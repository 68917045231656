import React from 'react';
import { Link } from 'react-router-dom';
import LandingButton from './LandingButton/LandingButton';
import AppStore from './AppStore/AppStore';

import styles from './Home.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import appSuite from '../../img/app-suite.png';

const Home = () => (
    <div className={styles.Home}>
        <div className="container overflow-hidden">
            <div className="row gx-5">
                <div className="col">
                    <div className="p-3">
                        <h1>YuleLog for Web</h1>
                        {/* <div className={styles.EarlyAccess} onClick={() => window.open('https://help.yulelog.com/hallmark/v/yulelog-for-web/development/early-access', '_blank')} >
                            <p>Early Access</p>
                        </div> */}
                        <h3>Ornament Price Guide &#38;<br /> Collection Database</h3>
                        <p className={styles.copy}>Catalog your Hallmark ornaments and Department 56 items. Sync your collections across your devices.</p>
                    </div>
                    <div className={styles.ButtonDiv}>
                        <Link to="/signin">
                            <LandingButton className={styles.Button} btnType="landing-btn" buttonText="Sign Up" />
                        </Link>

                    </div>

                    <div className={styles.AppStore}>
                        <AppStore />
                    </div>
                </div>
                <div className="col">
                    <div className="p-3">
                        <img className={styles.AppSuite} src={appSuite} alt="yulelog-app-preview" />
                    </div>
                </div>
            </div>
        </div>
    </div >
)


export default Home;