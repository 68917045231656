import React, { Fragment } from 'react';
import { Card, CardContent, Typography, Divider } from '@mui/material'
import styles from './ReportFavorites.module.css';
import { tooltipsText } from '../../../../constants/properties';

const ReportFavorites = (props) => {

    const renderItems = () => {
        if (props.items.length === 0) {
            return <div className={styles.EmptyDashboard}>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h6">
                    No data available.
                </Typography>
                <Typography
                    color="textPrimary"
                    style={{ lineHeight: "4px" }}
                    gutterBottom
                    variant="caption">
                    Add more items to your collection and see your top five {props.type.toLowerCase()} here.
                </Typography>
            </div>
        }
        return props.items
            ? props.items.map((i, index) =>
                <Fragment>
                    <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="body1">
                        <b>{index + 1}</b>. {i}
                    </Typography>
                    {index < 4 ? <Divider /> : null}
                </Fragment>
            )
            : null
    }

    return (
        <Card style={{ height: '250px' }} {...props}>
            <CardContent>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h5">
                    <b>Favorite {props.type}</b>
                </Typography>
                {renderItems()}
                {props.type === "Series" && props.items.join().includes('*') && <Typography
                    className={styles.UnofficialSeries}
                    color="textPrimary"
                    gutterBottom
                    variant="subtitle1">
                    <b>{tooltipsText.general.unofficial}</b>
                </Typography>}


            </CardContent>
        </Card>
    )
}

export default ReportFavorites;