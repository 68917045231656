import React, { useEffect } from 'react'
import { withAuthorization } from '../Session';
import { withRouter } from "react-router-dom";
import Firebase from '../Firebase/firebase'


import logo from '../../img/yulelogicon.png';
import './Success.css';
import Snowfall from 'react-snowfall'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import styles from './Success.module.css';
import Button from '@mui/material/Button'

import { properties } from '../../constants/properties';

const Success = (props) => {

    const firebase = new Firebase();

    document.title = 'YuleLog Account Updated';

    useEffect(() => {
        // If user is signed in, sign them out so that re-authentication can take place
        firebase.doSignOut();
    });

    return (
        <div className="backgroundImg">
            <Snowfall snowflakeCount={6} />
            <div className="logo">
                <img className="logo-img" src={logo} alt="yulelog-ornament-logo" />
            </div>
            <div className="topMenu">
                <a href="https://help.yulelog.com/hallmark/v/yulelog-for-web/" target="_blank">
                    <HelpOutlineIcon style={{ color: 'white', fontSize: '36px' }} />
                </a>
            </div>
            <div className="loginBox">
                <h2 className={styles.Welcome}>{properties.successText.headline}</h2>
                <div className={styles.Icon}>
                    <SuccessIcon style={{ height: '36px', width: '36px' }} />
                </div>
                <div className={styles.WelcomeText}>
                    <p>{properties.successText.signin}</p>
                    <Button
                        onClick={() => props.history.push('/signin')}
                        size="large"
                        className={styles.Button}
                        variant="contained">Log In</Button>
                </div>

            </div>
        </div>
    )
}

export default withRouter(Success);