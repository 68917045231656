import React from 'react';
import styles from './CollectionInput.module.css';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { isEmptyOrSpaces } from '../../../../../lib/util';


const CollectionInput = (props) => {

    const filter = createFilterOptions();

    const [value, setValue] = React.useState('');

    let storedArr = localStorage.getItem(props.dataType);
    storedArr = JSON.parse(localStorage[props.dataType]);
    let mappedArr = storedArr.map(item => {
        return {
            title: item
        }
    });
    if (mappedArr.length === 1 && mappedArr[0].title === "") {
        mappedArr = [""]
    }


    const handleOnChange = (e, newValue) => {
        console.log("ASDF", newValue)
        if (typeof newValue === 'string') {
            props.changed(props.id, newValue.title, storedArr);
        } else if (newValue && newValue.inputValue) {
            let newStr = newValue.inputValue.trim();
            // Create a new value from the user input
            storedArr.push(newStr)
            props.changed(props.id, newStr, storedArr);
        } else {
            if (newValue) {
                props.changed(props.id, newValue.title, storedArr);
            }
            if (newValue === null) {
                props.changed(props.id, "", storedArr);
            }
        }
    }

    const defaultValue = props.text;


    return (
        <Autocomplete
            className={styles.Autocomplete}
            onChange={handleOnChange}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isEmptyOrSpaces(inputValue) && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            freeSolo
            defaultValue={defaultValue}
            options={mappedArr}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.title;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            renderInput={(params) => <TextField {...params} label={props.label} />}
        />
    )
};

export default CollectionInput;