import React from 'react';

import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { tooltipsText } from '../../constants/properties';

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { connectSearchBox } from 'react-instantsearch-dom';
import styles from './CustomSearchBox.module.css';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        width: '100%'
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        color: 'white'
    },
    inputRoot: {
        marginLeft: '50px',
        padding: '8px 0px 5px 0px',
        fontSize: '40px'
    },
    inputBase: {
        paddingTop: '10px'
    },
    inputInput: {
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        width: '100%',
        color: 'white'
    },
}));


const SearchBox = (({ currentRefinement, refine }) => {
    // return the DOM output
    const classes = useStyles();


    const handleSearchBoxClick = () => {
        document.getElementById('search-input').focus();
    }

    return (
        <div className={classes.search}>
            <form onClick={handleSearchBoxClick} className={styles.Form} id="searchform" onSubmit={e => {
                e.preventDefault();
                // there are multiple ways to get the value from the input,
                // querySelector is another valid option
                const value = new FormData(e.target).get('search-input');
                refine(value);
            }}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder={tooltipsText.general.searchbar}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    type="text"
                    autoComplete="off"
                    id="search-input"
                    name="search-input"
                    style={{ width: "400px", color: "white", fontSize: "20px" }}
                />
            </form>
        </div>
    )
});

const CustomSearchBox = connectSearchBox(SearchBox);

export default CustomSearchBox;