export const getCollectionInfo = (collector, collection) => {
    if (collector && collection) {
        return `${collector} - ${collection}`;
    } else if (collector && !collection) {
        return collector;
    } else if (collection && !collector) {
        return collection;
    } else {
        return null;
    }
}

export const getCollectionInfoLabel = (collector, collection) => {
    if (collector && collection) {
        return 'Collector / Collection info'
    } else if (collector && !collection) {
        return 'Collector info';
    } else if (collection && !collector) {
        return 'Collection info';;
    } else {
        return null;
    }
}