import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch';
import NumberFormat from 'react-number-format';
import styles from './Hit.module.css';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Link from '@mui/material/Link';
import defaultImage from '../../img/missing.png';
import DetailsModal from '../UI/Modal/PriceGuideModal/PriceGuideModal';
import CollectionModal from '../UI/Modal/CollectionModal/CollectionModal';
import Comments from '../../components/Hit/Comments/Comments';
import Firebase, { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';

import { generateRandomId } from '../../lib/generateRandomId';
import { tooltipsText } from '../../constants/properties';

import './Hit.css';

import * as ROUTES from '../../constants/routes';


const Hits = ({ hits, firebase }, props) => {

    const ALGOLIA_ID = 'G89C4NZVH8';
    const ALGOLIA_ADMIN_KEY = localStorage.getItem("search");
    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);

    var collectionItems = JSON.parse(localStorage.getItem("collectionItems")) || [];
    let wantedCollectionItems = JSON.parse(localStorage.getItem("wantedCollectionItems")) || [];

    let activeDb = sessionStorage.getItem('activeDb');

    const getOrDefault = (storedValue, defaultValue = "") => {
        if (storedValue != null) {
            return storedValue
        }
        if (storedValue == "undefined") {
            return ""
        }
        return ""
    }


    const [modalShow, setModalShow] = React.useState(false);
    const [collectionModalShow, setCollectionModalShow] = React.useState(false);
    const [itemData, setItemData] = React.useState(0);
    const [collectionData, setCollectionData] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const ylidUser = localStorage.getItem("showYLID") === "true" ? true : false;

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    var user = firebase.auth.currentUser.uid;


    const handleItemDetails = (yulelogID) => (event) => {
        setModalShow(true)
        yulelogID = yulelogID.toString();
        // or get the single doc from the collection
        firebase.db.collection(activeDb).doc(yulelogID)
            .get()
            .then(doc => {
                const data = doc.data();
                setItemData(data);
            });
    }


    const renderMibPrice = (hit) => {
        return hit.prices.price2024
    }

    const handleItemDetailsClose = () => {
        setItemData(0);
        setCollectionData(0);
    }

    const renderItemCollected = (yulelogID) => {
        let itemCheck = collectionItems.includes(yulelogID);
        let wantedCheck = wantedCollectionItems
            ? wantedCollectionItems.includes(yulelogID)
            : null;
        /* Account for multiples (one wanted, on in collection) */
        if (itemCheck && wantedCheck) {
            return <div className={styles.HitItemAdded}>
                <div id={yulelogID} className={styles.ItemAdded}><CheckCircleIcon style={{ fontSize: 12 }} /> Item in Collection</div>
            </div>
        }
        if (itemCheck) {
            return <div className={styles.HitItemAdded}> <div id={yulelogID} className={styles.ItemAdded}><CheckCircleIcon style={{ fontSize: 12 }} /> Item in Collection</div></div>
        }
        if (wantedCheck) {
            return <div className={styles.HitItemWanted}><div id={yulelogID} className={styles.ItemWanted}><FavoriteIcon style={{ fontSize: 12 }} /> Item on Wishlist</div></div>
        }
        return <div className={styles.HitItemAdded}><div id={yulelogID} className={styles.ItemAdded} style={{ display: 'none' }}><CheckCircleIcon style={{ fontSize: 12 }} /> Item in Collection</div></div>
    }

    const isItemCollected = (yulelogID) => {
        return collectionItems.includes(yulelogID);
    }

    const isItemWanted = (yulelogID) => {
        return wantedCollectionItems.includes(yulelogID);
    }


    const handleAddItemAndEdit = (yulelogID) => (event) => {
        let yulelogIDString = yulelogID.toString();

        firebase.db.collection(activeDb).doc(yulelogIDString)
            .get()
            .then(doc => {
                const ornament = doc.data();
                var randomID = generateRandomId(28);
                ornament.timeStamp = Math.round(+new Date() / 1000)
                /* Set MIB Price based on brand */
                if (ornament.brand === "Hallmark") { ornament.mibPrice = ornament.prices.price2024 };
                if (ornament.brand === "Department 56") { ornament.mibPrice = ornament.prices.price2024 };

                /* Remove price history for collection ornament. Only mibPrice is necessary */
                let { price2015, price2016, price2017, price2018, price2019, price2020, price2021, price2022, prices, ...collectionOrnament } = ornament;

                /* Set default values */
                collectionOrnament.collector = getOrDefault(localStorage.getItem("collectorDefault"), "");
                collectionOrnament.collection = getOrDefault(localStorage.getItem("collectionDefault"), "");
                collectionOrnament.displayLocation = getOrDefault(localStorage.getItem("displayLocationDefault"), "");
                collectionOrnament.purchaseLocation = getOrDefault(localStorage.getItem("purchaseLocationDefault"), "");
                collectionOrnament.storageLocation = getOrDefault(localStorage.getItem("storageLocationDefault"), "");


                firebase.db.collection("users").doc(user).collection("maincollection").doc(randomID).set(collectionOrnament).then(doc => {
                    const itemID = randomID;
                    const index = client.initIndex("userItems");
                    // TODO: Call snackbar here
                    collectionOrnament.objectID = itemID;
                    collectionOrnament.user = user;
                    index.saveObject(collectionOrnament);
                    collectionItems.push(yulelogID);
                    localStorage.setItem("collectionItems", JSON.stringify(collectionItems));
                    sessionStorage.setItem("shouldRefreshCollection", "true");
                    var yulelogElement = document.getElementById(yulelogIDString);
                    if (yulelogElement) {
                        yulelogElement.style.display = 'block';
                    }
                    handleClick();
                    setCollectionData(collectionOrnament);
                    setModalShow(false);
                    setCollectionModalShow(true)
                })
            });
    }

    const handleAddItem = (yulelogID) => (event) => {
        let yulelogIDString = yulelogID.toString();
        firebase.db.collection(activeDb).doc(yulelogIDString)
            .get()
            .then(doc => {
                const ornament = doc.data();
                var randomID = generateRandomId(28);
                ornament.timeStamp = Math.round(+new Date() / 1000)
                /* Set MIB Price based on brand */
                if (ornament.brand === "Hallmark") { ornament.mibPrice = ornament.prices.price2024 };
                if (ornament.brand === "Department 56") { ornament.mibPrice = ornament.prices.price2024 };

                /* Remove price history for collection ornament. Only mibPrice is necessary */
                let { price2015, price2016, price2017, price2018, price2019, price2020, price2021, price2022, prices, ...collectionOrnament } = ornament;

                /* Set default values */
                collectionOrnament.collector = getOrDefault(localStorage.getItem("collectorDefault"), "");
                collectionOrnament.collection = getOrDefault(localStorage.getItem("collectionDefault"), "");
                collectionOrnament.displayLocation = getOrDefault(localStorage.getItem("displayLocationDefault"), "");
                collectionOrnament.purchaseLocation = getOrDefault(localStorage.getItem("purchaseLocationDefault"), "");
                collectionOrnament.storageLocation = getOrDefault(localStorage.getItem("storageLocationDefault"), "");

                firebase.db.collection("users").doc(user).collection("maincollection").doc(randomID).set(collectionOrnament).then(doc => {
                    const itemID = randomID;
                    const index = client.initIndex("userItems");
                    // TODO: Call snackbar here
                    collectionOrnament.objectID = itemID;
                    collectionOrnament.user = user;
                    index.saveObject(collectionOrnament);
                    collectionItems.push(yulelogID);
                    localStorage.setItem("collectionItems", JSON.stringify(collectionItems));
                    sessionStorage.setItem("shouldRefreshCollection", "true");
                    var yulelogElement = document.getElementById(yulelogIDString);
                    if (yulelogElement) {
                        yulelogElement.style.display = 'block';
                    }
                    handleClick();
                })
            });
    }

    const yulelogIDClick = (id) => (event) => {
        sessionStorage.setItem('itemToEdit', id);
        window.open('/yl-admin', '_blank')
    };

    const handleDeleteItem = (objectID, yulelogID, user, status) => (event) => {
        firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID)
            .delete()
            .then(doc => {
                const index = client.initIndex("userItems");
                // TODO: Call snackbar here
                index.deleteObject(objectID).then(() => {

                    if (status === "Wanted") {
                        let oldWantedArr = JSON.parse(localStorage.getItem("wantedCollectionItems"));
                        var indexToRemove = oldWantedArr.indexOf(yulelogID);
                        // If the element is found, remove it
                        if (indexToRemove !== -1) {
                            oldWantedArr.splice(indexToRemove, 1);
                        }
                        console.log("oldWanted", oldWantedArr)
                        localStorage.setItem("wantedCollectionItems", JSON.stringify(oldWantedArr));
                    } else {
                        let oldArr = JSON.parse(localStorage.getItem("collectionItems"));
                        var indexToRemove = oldArr.indexOf(yulelogID);
                        // If the element is found, remove it
                        if (indexToRemove !== -1) {
                            oldArr.splice(indexToRemove, 1);
                        }
                        localStorage.setItem("collectionItems", JSON.stringify(oldArr));
                    }

                    sessionStorage.setItem("shouldRefreshCollection", "true");
                    handleItemDetailsClose();
                });
            });
    }

    const handleSaveItem = (objectID, user, item) => (event) => {

        alert("Saving...")

        let updateObj = {
            dateBought: item.dateBought,
            dateSold: item.dateSold,
            myComments: item.myComments,
            priceBought: item.priceBought,
            priceSold: item.priceSold,
            condition: item.condition,
            quantity: item.quantity,
            status: item.status,
            signed: item.signed,
            purchaseLocation: item.purchaseLocation,
            displayLocation: item.displayLocation,
            storageLocation: item.storageLocation,
            collector: item.collector,
            collection: item.collection
        }

        firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID)
            .update(updateObj).then(function () {

                const index = client.initIndex("userItems");

                index.partialUpdateObject({
                    ...updateObj,
                    objectID: objectID
                }).then(() => {
                    console.log("UPDATED ITEM")
                    handleItemDetailsClose();
                    sessionStorage.setItem("shouldRefreshCollection", "true");
                });
            });

        let arrObj = {};
        if (item.purchaseLocationArray) {
            arrObj['purchaseLocation'] = item.purchaseLocationArray;
            localStorage.setItem("purchaseLocations", JSON.stringify(item.purchaseLocationArray));
        }
        if (item.displayLocationArray) {
            arrObj['displayLocation'] = item.displayLocationArray;
            localStorage.setItem("displayLocations", JSON.stringify(item.displayLocationArray));
        }
        if (item.storageLocationArray) {
            arrObj['storageLocation'] = item.storageLocationArray;
            localStorage.setItem("storageLocations", JSON.stringify(item.storageLocationArray));
        }
        if (item.collectorArray) {
            arrObj['collector'] = item.collectorArray;
            localStorage.setItem("collectors", JSON.stringify(item.collectorArray));
        }
        if (item.collectionArray) {
            arrObj['collection'] = item.collectionArray;
            localStorage.setItem("collections", JSON.stringify(item.collectionArray));
        }

        /* Only update Firestore locations array if changes were detected and object is populated */
        if (item.updatedArray && Object.entries(arrObj).length > 0) {

            firebase.db.collection("users").doc(user).collection("userinfo").doc("locations").update(
                arrObj
            )
                .then(function () {
                }).catch(function (error) {
                    // The document probably doesn't exist.
                });
        }
    }

    const handleLinkClick = (yulelogID) => {

        const id = yulelogID.toString();
        console.log(id)

        console.log("handle from collectionhit")
        const url = `https://yulelog.com/priceguide?ornament=${id}`;
        console.log("Clicked handle link")
        console.log(id)
        console.log(url)

        // Call your Cloud Function using httpsCallable
        const itemSharedFunction = firebase.functions.httpsCallable('itemShared');
        const result = itemSharedFunction({ text: id });

        // Handle the result accordingly
        console.log('Item Shared result:', result.data);

        // Continue with the clipboard logic if needed
        navigator.clipboard.writeText(url)
            .then(() => {
                return
            })
            .catch((err) => {
                console.error('Unable to copy to clipboard', err);
            });

        return;
    };

    const handleShopVisited = (yulelogID, shop) => {
        const id = yulelogID.toString();
        firebase.analytics.logEvent('affiliate_link_click', {
            affiliate_name: shop,
            id: id
        });
    }


    return (
        <div>
            {hits.map(hit => (
                <div>
                    <div>
                    </div>
                    <div className={styles.Hit} key={hit.objectID}>
                        <div className={styles.HitContent}>
                            <div className={styles.HitAddButton}>
                                <Tooltip title={tooltipsText.priceGuide.addItem}>
                                    <IconButton onClick={(e) => handleAddItem(hit.yulelogID)(e)} aria-label="add">
                                        <AddCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className={styles.HitTextbox}>
                                {
                                    ylidUser && <Tooltip placement="right" arrow title={tooltipsText.general.yulelogID}>
                                        <div onClick={(e) => yulelogIDClick(hit.yulelogID)(e)} className={styles.YuleLogID}>
                                            {hit.yulelogID}
                                        </div>

                                    </Tooltip>
                                }
                                <div className={styles.HitYearBoxnum}>
                                    <Tooltip placement="top" arrow title={tooltipsText.general.year}>
                                        <div className={styles.HitYear}>
                                            {hit.year}
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement="top" arrow title={tooltipsText.general.boxnum}>
                                        <div className={styles.HitBoxnum}>
                                            {hit.boxnum}
                                        </div>
                                    </Tooltip>
                                </div>
                                {renderItemCollected(hit.yulelogID)}
                                <div className={styles.HitName}>
                                    <Tooltip placement="right" arrow title={tooltipsText.general.nameHit}>
                                        <Link underline="hover" className={styles.HitLink} onClick={(e) => handleItemDetails(hit.yulelogID)(e)}>{hit.name}</Link>
                                    </Tooltip>
                                </div>

                                <div className={styles.HitSeries}>
                                    <Tooltip placement="right" arrow title={tooltipsText.general.village}>
                                        <b><i>{hit.village}</i></b>
                                    </Tooltip>
                                </div>

                                <div className={styles.HitSeries}>
                                    <Tooltip placement="right" arrow title={tooltipsText.general.series}>
                                        <b>{hit.series}</b>
                                    </Tooltip>
                                    {hit.seriesnum > 0 ? " #" + hit.seriesnum : ""}
                                </div>

                                <div className={styles.HitArtist}>
                                    <Tooltip placement="right" arrow title={tooltipsText.general.artist}>
                                        <b>{hit.artist.join(', ')}</b>
                                    </Tooltip>
                                </div>
                                <div className={styles.HitPrices}>
                                    <Tooltip placement="top" arrow title={tooltipsText.general.boxPrice}>
                                        <b>Box Price: </b>
                                    </Tooltip>
                                    <NumberFormat value={hit.boxPrice} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    <br />
                                    <Tooltip placement="top" arrow title={tooltipsText.general.mibPrice}>
                                        <b>MIB Price: </b>
                                    </Tooltip>
                                    <NumberFormat value={renderMibPrice(hit)} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </div>
                                <Comments comments={hit.pgComments} />
                            </div>
                            <div className={styles.HitImageWrapper}>
                                <img className={styles.HitImage} alt="price guide item" src={hit.imageURL || defaultImage} />
                            </div>
                        </div>
                    </div>
                </div >
            ))
            }
            <DetailsModal
                yulelogID={itemData.yulelogID}
                name={itemData.name}
                brand={itemData.brand}
                artist={itemData.artist}
                boxnum={itemData.boxnum}
                year={itemData.year}
                category={itemData.category}
                village={itemData.village}
                series={itemData.series}
                seriesnum={itemData.seriesnum}
                imageurl={itemData.imageURL}
                videoURL={itemData.videoURL}
                mibPrice={itemData.prices}
                boxPrice={itemData.boxPrice}
                prices={itemData.prices}
                price2017={itemData.price2017}
                price2018={itemData.price2018}
                price2019={itemData.price2019}
                price2020={itemData.price2020}
                price2021={itemData.price2021}
                price2022={itemData.price2022}
                pgComments={itemData.pgComments}
                keywords={itemData.keywords}
                show={modalShow}
                animation={false}
                onHide={() => setModalShow(false)}
                onExited={handleItemDetailsClose}
                addItem={handleAddItemAndEdit}
                isItemCollected={isItemCollected(itemData.yulelogID)}
                isItemWanted={isItemWanted(itemData.yulelogID)}
                itemShared={handleLinkClick}
                shopVisited={handleShopVisited}
            />
            {(collectionData && itemData) ? <CollectionModal
                name={itemData.name}
                artist={itemData.artist}
                boxnum={itemData.boxnum}
                year={itemData.year}
                category={itemData.category}
                village={itemData.village}
                brand={itemData.brand}
                series={itemData.series}
                seriesnum={itemData.seriesnum}
                imageurl={collectionData.customPhoto ? collectionData.imageURL : itemData.imageURL}
                mibPrice={itemData.prices.price2024}
                boxPrice={itemData.boxPrice}
                price2017={itemData.price2017}
                price2018={itemData.price2018}
                price2019={itemData.price2019}
                price2020={itemData.price2020}
                price2021={itemData.price2021}
                pgComments={itemData.pgComments}
                pgImg={itemData.imageURL}
                objectID={collectionData.objectID}
                yulelogID={itemData.yulelogID}
                keywords={itemData.keywords}
                collector={collectionData.collector}
                collection={collectionData.collection}
                condition={collectionData.condition}
                customImg={collectionData.imageURL}
                showCustomImage={collectionData.customPhoto}
                dateBought={collectionData.dateBought}
                dateSold={collectionData.dateSold}
                displayLocation={collectionData.displayLocation}
                myComments={collectionData.myComments}
                quantity={collectionData.quantity}
                priceBought={collectionData.priceBought}
                priceSold={collectionData.priceSold}
                purchaseLocation={collectionData.purchaseLocation}
                signed={collectionData.signed}
                status={collectionData.status}
                storageLocation={collectionData.storageLocation}
                user={user}
                show={collectionModalShow}
                animation={false}
                hide={handleItemDetailsClose}
                onExited={handleItemDetailsClose}
                deleteItem={handleDeleteItem}
                saveItem={handleSaveItem}
                itemShared={handleLinkClick}
                shopVisited={handleShopVisited}
            /> : null}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message="Item added to collection"
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    )
};

const CustomHits = connectHits(Hits);

const condition = authUser => !!authUser;

export default withFirebase(withAuthorization(condition)(CustomHits));