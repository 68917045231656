import React from 'react';
import styles from './Comments.module.css';
import Collapse from 'react-bootstrap/Collapse';

const Comments = (props) => {

    const commentsFull = props.comments;
    let showShowMore = false;
    let commentsShort;

    if (commentsFull.length > 150) {
        showShowMore = true;
        commentsShort = commentsFull.substring(0, 150) + '...';
    } else {
        commentsShort = commentsFull; // No need to shorten if it's already short
    }

    const [showMore, setShowMore] = React.useState(false);
    const [linkText, setLinkText] = React.useState('Show more');

    const handleClick = () => {
        setShowMore(!showMore);
        setLinkText(showMore ? 'Show more' : 'Show less');
    }

    return (
        <div>
            {!showMore && <div dangerouslySetInnerHTML={{ __html: commentsShort }} className={styles.Comments}></div>}
            <Collapse in={showMore}>
                <span>
                    {<div dangerouslySetInnerHTML={{ __html: commentsFull }} className={styles.Comments}></div>}
                </span>
            </Collapse>
            {showShowMore && <a className={styles.CollapseLink} onClick={handleClick}>{linkText}</a>}
        </div>
    )
}

export default Comments;