import React from 'react';

import styles from './CollapsibleHeader.module.css';
import './CollapsibleHeader.css';

class CollapsibleHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = { expanded: false };
        this.handleMenuClick = this.handleMenuClick.bind(this)
    }

    handleMenuClick() {
        this.state.expanded ? this.setState({ expanded: false }) : this.setState({ expanded: true });
    }

    render() {
        let className = "icon"
        if (this.state.expanded) {
            className = ' rotated';
        } else {
            className = '';
        }

        return (
            <div onClick={this.handleMenuClick} >
                <div className={styles.TextDiv}>
                    {this.props.title}<span className={["icon" + className]}>▶</span>
                </div>
            </ div>)
    }
}

export default CollapsibleHeader;