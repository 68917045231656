import React from 'react';
import NumberFormat from 'react-number-format';
import styles from './PriceChange.module.css';


const PriceChange = (props) => {

    const [priceDisplay, setPriceDisplay] = React.useState(false);

    const handleClick = () => {
        if (props.boxPrice > 0) {
            setPriceDisplay(!priceDisplay);
        }
    };

    let priceChange = props.mibPrice - props.boxPrice;

    const renderPrice = (price) => {
        let color;
        let sign;
        if (price === 0) {
            return null
        }
        if (price > 0) {
            color = 'green'
            sign = '+'
        }
        if (price < 0) {
            color = 'red'
        }
        return price ? (
            <span style={{ color: color, cursor: 'pointer' }}>{sign}<NumberFormat fixedDecimalScale={true} decimalScale={2} value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} allowNegative={true} /></span>) : null;
    }

    const renderPercentage = (boxPrice, mibPrice) => {
        let percentChange = 0;
        let color;
        let isPositive = true;
        if (mibPrice > boxPrice) {
            color = 'green';
            percentChange = ((mibPrice - boxPrice) / (boxPrice)) * 100;
        } else {
            color = 'red;'
            percentChange = ((mibPrice - boxPrice) / (boxPrice)) * 100;
            percentChange = percentChange * -1;
            isPositive = false;
        }
        percentChange = Number(percentChange).toFixed(2);
        return (isPositive)
            ? <span style={{ color: color, cursor: 'pointer' }}><NumberFormat fixedDecimalScale={true} decimalScale={2} value={percentChange} displayType={'text'} thousandSeparator={true} prefix={'+'} suffix={'%'} allowNegative={true} /></span>
            : <span style={{ color: color, cursor: 'pointer' }}><NumberFormat fixedDecimalScale={true} decimalScale={2} value={percentChange} displayType={'text'} thousandSeparator={true} prefix={'-'} suffix={'%'} allowNegative={true} /></span>

    }

    return (
        <div className={styles.PriceChange} onClick={handleClick}>
            {priceDisplay ? renderPercentage(props.boxPrice, props.mibPrice) : renderPrice(priceChange)}
        </div>
    )
}

export default PriceChange;