import React from 'react';
import TextField from '@mui/material/TextField';

import styles from './SeriesNumInput.module.css';

const SeriesNumInput = (props) => {

    const [seriesnum, setSeriesNum] = React.useState(props.quantity);
    const [error, setError] = React.useState(false);

    const handleChange = (event) => {
        if (event.target.value < 1) {
            return setSeriesNum(1);
        }
        if (event.target.value > 99) {
            return setSeriesNum(99)
        }
        props.changed("seriesnum", (Math.floor(event.target.value)));
        return setSeriesNum(Math.floor(event.target.value));
    };

    return (
        <div className={styles.Quantity}>
            <TextField
                type="number"
                size="small"
                helperText="Between 1-99"
                error={error}
                onChange={handleChange}
                label="Series #"
                value={seriesnum}
                sx={{ textAlign: 'center' }}
                InputProps={{
                    inputProps: {
                        max: 99, min: 1
                    }
                }}
            />
        </div>
    )
}

export default SeriesNumInput;