import React from 'react'

import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-tables';
import { Link } from 'react-router-dom';
import logo from '../../img/yulelogicon.png';
import './Subscribe.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Firebase, { withFirebase } from '../../components/Firebase';
import { AuthUserContext, withAuthorization } from '../../components/Session';

const Subscribe = ({ firebase }) => {


    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    console.log(firebase.auth.currentUser.uid)
    const userID = firebase.auth.currentUser.uid;
    var hallmark = "1"
    var d56 = "2"
    var hallmark56 = "3"

    var clientRefHallmark = hallmark.concat(userID)
    var clientRef56 = d56.concat(userID)
    var clientRefHallmark56 = hallmark56.concat(userID)

    const checkoutButton = () => {

        let createStripeCheckout = firebase.functions.httpsCallable('createStripeCheckout');
        createStripeCheckout({ text: clientRefHallmark }).then(function (result) {
            // Read result of the Cloud Function.
            setOpen(true)
            var result = result.data.text;
            window.location.replace(result);
        }).catch(function (error) {
            // Handle errors here
            alert("There was an error opening the checkout page. Please contact sean@yulelog.com if this persists.")
            // You can also notify the user about the error or perform other actions as needed.
        });
    };

    const checkoutButton56 = () => {

        let createStripeCheckout = firebase.functions.httpsCallable('createStripeCheckout');
        createStripeCheckout({ text: clientRef56 }).then(function (result) {
            // Read result of the Cloud Function.
            setOpen(true)
            var result = result.data.text;
            window.location.replace(result);
        }).catch(function (error) {
            // Handle errors here
            alert("There was an error opening the checkout page. Please contact sean@yulelog.com if this persists.")
            // You can also notify the user about the error or perform other actions as needed.
        });
    };

    const checkoutButtonBoth = () => {

        let createStripeCheckout = firebase.functions.httpsCallable('createStripeCheckout');
        createStripeCheckout({ text: clientRefHallmark56 }).then(function (result) {
            // Read result of the Cloud Function.
            setOpen(true)
            var result = result.data.text;
            window.location.replace(result);
        }).catch(function (error) {
            // Handle errors here
            alert("There was an error opening the checkout page. Please contact sean@yulelog.com if this persists.")
            // You can also notify the user about the error or perform other actions as needed.
        });
    };

    const signOutReturn = () => {
        firebase.doSignOut();
        return window.location.href = '/'
    }


    return (
        <div className="subscribe">
            <div className="logo">
                <img className="logo-img" src={logo} alt="yulelog-ornament-logo" />
            </div>
            <div className="topMenu">
                <Link to="#" underline="hover" className="GoBackLink" onClick={signOutReturn}>Sign Out</Link>
                <a href="https://help.yulelog.com/hallmark/v/yulelog-for-web/yulelog-subscription-types" target="_blank">
                    <HelpOutlineIcon style={{ color: 'white', fontSize: '36px' }} />
                </a>
            </div>
            <div className="subscribe-hero"><h1>Subscribe to YuleLog</h1></div>


            <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
            <stripe-pricing-table pricing-table-id="prctbl_1ObtBHCgstdlWwSCW5Dwn2se"
                publishable-key="pk_live_XjXV7BSKEPRNw0rd98n9XViH"
                client-reference-id={userID}>
            </stripe-pricing-table>
            {/* <div className="pricing-table">
                <Backdrop sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    color: '#fff'
                }} open={false} onClick={handleClose}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <PricingTable highlightColor="#28a74594">
                    <PricingSlot buttonClass="landing-btn" onClick={checkoutButton56} buttonText='Select' title='Dept. 56' priceText='$34.99 / year'>
                        <PricingDetail><b>Price Guide</b> includes <b>7,500+</b> ornaments</PricingDetail>
                        <PricingDetail>Secondary market values and images for items from <b>25+ villages</b></PricingDetail>
                        <PricingDetail><b>Print</b> and <b>export</b> reports as <b>PDF</b> files or <b>spreadsheets</b></PricingDetail>
                        <PricingDetail>Includes <b>Snowbabies</b> and hanging ornaments</PricingDetail>
                    </PricingSlot>
                    <PricingSlot buttonClass="landing-btn" onClick={checkoutButton} buttonText='Select' title='Hallmark' priceText='$39.99 / year'>
                        <PricingDetail><b>Price Guide</b> includes <b>14,500+</b> ornaments</PricingDetail>
                        <PricingDetail>Secondary market values and images for items from <b>500+ series</b></PricingDetail>
                        <PricingDetail><b>Print</b> and <b>export</b> reports as <b>PDF</b> files or <b>spreadsheets</b></PricingDetail>
                        <PricingDetail>Includes <b>Merry Miniatures</b> and rare <b>colorway ornaments</b></PricingDetail>
                    </PricingSlot>

                    <PricingSlot title='Hallmark + Dept. 56' priceText='$49.99 / year'>
                        <PricingDetail><b>Price Guide</b> includes <b>20,000+</b> items</PricingDetail>
                        <PricingDetail>Combines features of Hallmark + Dept. 56</PricingDetail>
                        <PricingDetail><b>Print</b> and <b>export</b> reports as <b>PDF</b> files or <b>spreadsheets</b></PricingDetail>
                        <PricingDetail><b>Best value</b></PricingDetail>
                    </PricingSlot>
                </PricingTable>
            </div> */}

        </div >
    )
};

const condition = authUser => !!authUser;

export default withFirebase(withAuthorization(condition)(Subscribe));