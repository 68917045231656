import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, Button } from '@mui/material/';
import IconButton from '@mui/material/IconButton';
import { CropLandscape, CropPortrait } from '@material-ui/icons';
import styles from './PrintReport.module.css';
import localforage from 'localforage';
import './PrintReport.css';
import { type } from 'jquery';

const reportType = localStorage.getItem('reportType');



var cssPagedMedia = (function () {
    var style = document.createElement('style');
    document.head.appendChild(style);
    return function (rule) {
        style.innerHTML = rule;
    };
}());

cssPagedMedia.size = function (size) {
    cssPagedMedia('@page {size: ' + size + '}');
};

// Or you can open it in a new window:
// doc.output('dataurlnewwindow');
const handleClickLandscape = () => {
    cssPagedMedia.size('landscape');
    window.print()
}

const handleClickPortrait = () => {
    cssPagedMedia.size('portrait');
    window.print()
}

const PrintReport = (props) => {

    document.title = reportType ? reportType : 'YuleLog';

    const [loading, setIsLoading] = React.useState(true);
    const [imagesEnabled, setImagesEnabled] = React.useState(false);
    const [headers, setHeaders] = React.useState([]);
    const [totals, setTotals] = React.useState([]);
    const [printData, setPrintData] = React.useState(0);




    useEffect(() => {
        getPrintData()
        async function getPrintData() {
            var result = await localforage.getItem('printData'); //wait for the localforage item
            if (result.headers.includes("Image")) {
                setImagesEnabled(true)
            }
            setPrintData(result || { "headers": [], "rows": [], "totals": [] })
            setHeaders(result.headers.map((item) => <th>{item}</th>));
            setTotals(result.totals.map((item) => <th>{item}</th>));
            return Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
                setIsLoading(false);
            });
        }
    }, []);

    return (
        <div>
            {loading &&
                <div>
                    <center>
                        <h6 className={styles.LoaderText} id="changeText">
                            <Typography
                                color="primary"
                                gutterBottom
                                variant="body1">
                                <b>Generating printable YuleLog report...</b>
                            </Typography>
                            <Typography
                                color="gray"
                                gutterBottom
                                variant="caption">
                                Larger reports with photos may take longer to load.
                            </Typography>
                        </h6>
                    </center>
                    <div className={styles.LoaderWrapper}>
                        <CircularProgress color="primary" />
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <h4 className={styles.Header}>{reportType}</h4>
                   
                    <div className={"print-btn-wrapper"}>
                    <center>
                    <Typography
                        color="black"
                        gutterBottom
                        variant="paragraph">
                        Select page orientation for your report:
                    </Typography>
                    <br></br><br></br>
                    </center>

                    <Button
                        size='large'
                        sx={{marginRight: '10px'}}
                        onClick={handleClickLandscape}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CropLandscape />}
                        >
                        Print Landscape
                        </Button>
                        <Button
                         size='large'
                        onClick={handleClickPortrait}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CropPortrait />}
                        >
                        Print Portrait
                        </Button>
                    
                        
                        {/* <button className="print-btn-alternate" onClick={handleClick}>Storage Cards</button> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                {headers}
                            </tr>
                        </thead>

                        <tbody>

                            {printData.rows.map(item =>
                                <tr>
                                    {
                                        item.map(
                                            item => {
                                                if (typeof item === "string") {
                                                    item = item.replaceAll('<br>', " ")
                                                    console.log(item)
                                                }
                                                // item['pgComments'].replace('<br>', '');
                                                return (imagesEnabled && String(item).includes('firebasestorage'))
                                                    ? <td><img height="75" width="75" src={item} /></td>
                                                    : <td>{item}</td>
                                            }
                                        )
                                    }
                                </tr>
                                
                            )}
                            <tr class="totals">
                                {totals}
                            </tr>
                        </tbody>

                    </table>
                </div>
            }
        </div>
    )
}

export default PrintReport;

{/* <tr>
                            {
                                item.map(
                                    item => {
                                        (imagesEnabled && item[0].includes('https'))
                                            ? <td><img src={item} /></td>
                                            : <td>{item}</td>
                                    }
                                )
                            }
                        </tr> */}