import { connectCurrentRefinements } from 'react-instantsearch-dom';

import styles from './CustomClearRefinements.module.css';

const ClearRefinements = ({ items, refine }) => (
    <div className={styles.ClearDiv}>
        <button className={styles.Button} onClick={() => refine(items)} disabled={!items.length}>
            Clear Filters
        </button>
    </div>
);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

export default CustomClearRefinements;