import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'



const ImageUploader = (props) => {
    // specify upload params and url for your files
    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => {
            f.remove()
            props.submit(f.file);
        })

    }

    return (
        <Dropzone
            styles={{
                inputLabel: { color: '#7d54b4' },
                dropzone: { border: '2px dashed lightgray' },
                submitButton: { backgroundColor: '#7d54b4' }
            }}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            accept=".jpg, .jpeg"
            maxFiles={1}
            multiple={false}
            canCancel={false}
            minSizeBytes={0}
            maxSizeBytes={512000}
            inputContent="To upload a custom image, drag file here or click to browse."
        />
    )
}

export default ImageUploader;