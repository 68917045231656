import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';

// Configure Firebase.
const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
    constructor() {

        if (!app.apps.length) {
            app.initializeApp(config);
        }
        this.auth = app.auth();
        this.db = app.firestore();
        this.analytics = app.analytics();
        this.database = app.database();
        this.storage = app.storage();
        this.functions = app.functions();
        this.fieldValue = app.firestore.FieldValue;
    }

    doSignOut = () => {
        window.localStorage.clear();
        this.auth.signOut();
    }

}



export default Firebase;