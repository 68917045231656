import React from 'react';
import styles from './CalculatedValue.module.css';
import InfoIcon from '@mui/icons-material/Info';
import BrokenIcon from '@mui/icons-material/BrokenImage';
import SignedIcon from '@mui/icons-material/VerifiedRounded';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import PriceCheck from '@mui/icons-material/PriceCheck';
import MoneyOff from '@mui/icons-material/MoneyOff';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Typography from '@mui/material/Typography';
import ItemAttribute from './ItemAttribute/ItemAttribute';
import Stack from '@mui/material/Stack';
import { calculateValue } from '../../../../../lib/lookupValues';

const CalculatedProfit = (props) => {

    return (
        <div
            className={styles.Wrapper}
        >
            <Stack direction="row" alignItems="center" gap={1}>
                <span className={styles.Label}>Estimated Item Value</span>
                <a class={styles.Link} target="_blank" href={"https://help.yulelog.com/hallmark/v/yulelog-for-web/item-estimated-worth"}>
                    <InfoIcon fontSize="small" />
                </a>
            </Stack>
            <Typography variant="h5" noWrap >
                ${calculateValue(props.condition, props.mibPrice, props.signed)}
            </Typography>
            <hr className={styles.DottedLine} />
            <div className={styles.StatusInfo}>
                {(props.signed) &&

                    <ItemAttribute
                        icon={<AssignmentTurnedInIcon style={{ color: "#7d54b4" }} fontSize="small" />}
                        text="Signed" />
                }
                {
                    (props.condition === "Mint In Box") &&
                    <ItemAttribute
                        icon={<PriceCheck style={{ color: "green" }} fontSize="small" />}
                        text="Mint In Box" />
                }
                {
                    (props.condition === "Damaged Box") &&
                    <ItemAttribute
                        icon={<BrokenIcon style={{ color: "red" }} fontSize="small" />}
                        text="Damaged Box" />
                }
                {
                    (props.condition === "Slightly Damaged Box") &&
                    <ItemAttribute
                        icon={<BrokenIcon style={{ color: "red" }} fontSize="small" />}
                        text="Slightly Damaged Box" />
                }
                {
                    (props.condition === "No Price Tag") &&
                    <ItemAttribute
                        icon={<MoneyOff style={{ color: "red" }} fontSize="small" />}
                        text="No Price Tag" />
                }
                {
                    (props.condition === "No Box") &&
                    <ItemAttribute
                        icon={<DisabledByDefaultIcon style={{ color: "red" }} fontSize="small" />}
                        text="No Box" />
                }
                {
                    (props.condition === "Never Removed From Box") &&
                    <ItemAttribute
                        icon={<PriceCheck style={{ color: "green" }} fontSize="small" />}
                        text="Never Removed From Box" />
                }
            </div>
        </div >
    )
}

export default CalculatedProfit;