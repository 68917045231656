import React from 'react';

import ListItem from '@mui/material/ListItem';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { withFirebase } from '../Firebase';

const menuFontSize = '18px';

const SignOut = ({ firebase }) => (
    <ListItem button onClick={firebase.doSignOut}>
        <ListItemIcon>
            <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Sign Out" primaryTypographyProps={{ fontSize: menuFontSize }} />
    </ListItem>
);

export default withFirebase(SignOut);