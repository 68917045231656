import React from 'react'
import { withAuthorization } from '../Session';
import { withRouter } from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';

import logo from '../../img/yulelogicon.png';
import './Login.css';
import Snowfall from 'react-snowfall'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import styles from './Login.module.css';

import { properties } from '../../constants/properties';

const Login = (props) => {

    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        signInSuccessUrl: '/loading',
        tosUrl: 'https://help.yulelog.com/hallmark/v/yulelog-for-web/legal/software-license-agreement',
        privacyPolicyUrl: 'https://help.yulelog.com/hallmark/v/yulelog-for-web/legal/privacy-policy'
    }

    document.title = 'YuleLog Sign In';
    return (
        <div className="backgroundImg">
            <Snowfall snowflakeCount={6} />
            <div className="logo">
                <img className="logo-img" src={logo} alt="yulelog-ornament-logo" />
            </div>
            <div className="topMenu">
                <a href="https://help.yulelog.com/hallmark/v/yulelog-for-web/" target="_blank">
                    <HelpOutlineIcon style={{ color: 'white', fontSize: '36px' }} />
                </a>
            </div>
            <div className="loginBox">
                <h1 className={styles.Welcome}>{properties.loginText.headline}</h1>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                <div className={styles.WelcomeText}>
                    <p>{properties.loginText.signup}</p>
                </div>

            </div>
        </div>
    )
}

export default withRouter(Login);