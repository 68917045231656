
import artists from '../constants/artists';

export const getArtist = (artist) => {
    const res = artists.list.find(record => record.name === artist)

    let obj = {}
    if (res) {
        obj = {
            name: res.name,
            bio: res.bio,
            src: res.src
        }
    }
    else {
        obj = {
            name: artist,
            bio: "Item artist",
            src: ""
        }
    }

    return obj;
}