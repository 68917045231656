import React, { Fragment, Collapse } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import Firebase from '../../Firebase/firebase';
import algoliasearch from 'algoliasearch';
import NumberFormat from 'react-number-format';
import styles from '../Hit.module.css';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Avatar from '@mui/material/Avatar';
import Comments from '../Comments/Comments';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import defaultImage from '../../../img/missing.png';
import CollectionModal from '../../UI/Modal/CollectionModal/CollectionModal';

import { getCollectionInfo, getCollectionInfoLabel } from '../../../lib/getCollectionInfo';

import '../Hit.css'

const CollectionHits = ({ hits, parentCallback, d56Enabled }, props) => {

    const [refresh, setRefresh] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [snackbarText, setSnackbarText] = React.useState("Item saved successfully");
    const showD56 = localStorage.getItem('showD56');

    const openSnackbar = (text) => {
        setSnackbarText(text);
        setOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const ALGOLIA_ID = 'G89C4NZVH8';
    const ALGOLIA_ADMIN_KEY = localStorage.getItem("search");
    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);

    const [modalShow, setModalShow] = React.useState(false);
    const [itemData, setItemData] = React.useState(0);
    const [collectionData, setCollectionData] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);
    const firebase = new Firebase();

    var user = firebase.auth.currentUser.uid;

    const handleItemDetails = (yulelogID, objectID, brand) => (event) => {
        setItemData(0);
        setCollectionData(0);
        let collectionData;
        yulelogID = yulelogID.toString();
        let itemBrand = (brand === "Hallmark") ? "hallmark" : "dept56";
        // or get the single doc from the collection
        firebase.db.collection(itemBrand).doc(yulelogID)
            .get()
            .then(doc => {
                let data = { ...doc.data(), objectID: objectID };
                setItemData(data);
            });
        firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID)
            .get()
            .then(doc => {
                collectionData = { ...doc.data(), objectID: objectID };
            }).then(() => {
                setCollectionData(collectionData);
                setModalShow(true)
            })
    }

    const handleItemDetailsClose = () => {
        setModalShow(false);
        setItemData(0);
    }

    const renderMibPrice = (itemData) => {
        if (itemData.brand === "Hallmark") {
            return itemData.prices.price2024
        }
        if (itemData.brand === "Department 56") {
            return itemData.prices.price2024
        }
        if (itemData.brand === "Custom") {
            return 0
        }
        return 0
    }

    const handleLinkClick = (yulelogID) => {

        console.log("handle from collectionhit")
        const url = `https://yulelog.com/priceguide?ornament=${yulelogID}`;
        console.log("Clicked handle link")
        console.log(yulelogID)

        // Call your Cloud Function using httpsCallable
        const itemSharedFunction = firebase.functions.httpsCallable('itemShared');
        const result = itemSharedFunction({ text: yulelogID });

        // Handle the result accordingly
        console.log('Item Shared result:', result.data);

        // Continue with the clipboard logic if needed
        navigator.clipboard.writeText(url)
            .then(() => {
                return
            })
            .catch((err) => {
                console.error('Unable to copy to clipboard', err);
            });

        return;
    };

    const handleShopVisited = (yulelogID, shop) => {
        const id = yulelogID.toString();
        firebase.analytics.logEvent('affiliate_link_click_collection', {
            affiliate_name: shop,
            id: id
        });
    }

    const handleSaveItem = (objectID, user, item) => (event) => {

        let updateObj = {
            dateBought: item.dateBought,
            dateSold: item.dateSold,
            myComments: item.myComments,
            priceBought: item.priceBought,
            priceSold: item.priceSold,
            condition: item.condition,
            quantity: item.quantity,
            status: item.status,
            signed: item.signed,
            purchaseLocation: item.purchaseLocation,
            displayLocation: item.displayLocation,
            storageLocation: item.storageLocation,
            collector: item.collector,
            collection: item.collection
        }

        firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID)
            .update(updateObj).then(function () {
                console.log('ASDF1234')
                let wantedCollectionItems = JSON.parse(localStorage.getItem("wantedCollectionItems")) || [];
                let collectionArr = JSON.parse(localStorage.getItem("collectionItems")) || [];

                /* If item is in wanted index and status is changing FROM wanted */
                let wantedIndex = wantedCollectionItems.indexOf(item.yulelogID);
                if (wantedIndex > -1 && item.status != "Wanted") {
                    wantedCollectionItems.splice(wantedIndex, 1);
                    collectionArr.push(item.yulelogID);
                    localStorage.setItem("collectionItems", JSON.stringify(collectionArr));
                    localStorage.setItem("wantedCollectionItems", JSON.stringify(wantedCollectionItems))
                }

                /* If item is wanted, add it to wanted index */
                if (item.status === "Wanted") {
                    let collectionArr = JSON.parse(localStorage.getItem("collectionItems"));
                    let index = collectionArr.indexOf(item.yulelogID);
                    if (index > -1) {
                        collectionArr.splice(index, 1);
                    }
                    localStorage.setItem("collectionItems", JSON.stringify(collectionArr));
                    wantedCollectionItems.push(item.yulelogID);
                    localStorage.setItem("wantedCollectionItems", JSON.stringify(wantedCollectionItems));
                }
                const index = client.initIndex("userItems");
                index.partialUpdateObject({
                    ...updateObj,
                    objectID: objectID
                }).then(() => {
                    handleItemDetailsClose();
                    openSnackbar("Item succesfully updated.");
                    setRefresh(true);
                    parentCallback(true);
                });
            });

        let arrObj = {};
        if (item.purchaseLocationArray) {
            arrObj['purchaseLocation'] = item.purchaseLocationArray;
            localStorage.setItem("purchaseLocations", JSON.stringify(item.purchaseLocationArray));
        }
        if (item.displayLocationArray) {
            arrObj['displayLocation'] = item.displayLocationArray;
            localStorage.setItem("displayLocations", JSON.stringify(item.displayLocationArray));
        }
        if (item.storageLocationArray) {
            arrObj['storageLocation'] = item.storageLocationArray;
            localStorage.setItem("storageLocations", JSON.stringify(item.storageLocationArray));
        }
        if (item.collectorArray) {
            arrObj['collector'] = item.collectorArray;
            localStorage.setItem("collectors", JSON.stringify(item.collectorArray));
        }
        if (item.collectionArray) {
            arrObj['collection'] = item.collectionArray;
            localStorage.setItem("collections", JSON.stringify(item.collectionArray));
        }

        /* Only update Firestore locations array if changes were detected and object is populated */
        if (item.updatedArray && Object.entries(arrObj).length > 0) {
            firebase.db.collection("users").doc(user).collection("userinfo").doc("locations").update(
                arrObj
            )
                .then(function () {

                }).catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
        }
    }


    const handleDeleteItem = (objectID, yulelogID, user) => (event) => {
        firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID)
            .delete()
            .then(doc => {
                const index = client.initIndex("userItems");
                // TODO: Call snackbar here
                index.deleteObject(objectID).then(() => {
                    let collectionArr = JSON.parse(localStorage.getItem("collectionItems")) || [];
                    let collectionWantedArr = JSON.parse(localStorage.getItem("wantedCollectionItems")) || [];

                    let index = collectionArr.indexOf(yulelogID);
                    if (index > -1) {
                        collectionArr.splice(index, 1);
                    }
                    localStorage.setItem("collectionItems", JSON.stringify(collectionArr));

                    let wantedIndex = collectionWantedArr.indexOf(yulelogID);
                    if (wantedIndex > -1) {
                        collectionWantedArr.splice(index, 1);
                    }
                    localStorage.setItem("wantedCollectionItems", JSON.stringify(collectionWantedArr));
                    setRefresh(true);
                    openSnackbar("Item deleted from collection.");
                    parentCallback(true);
                    handleItemDetailsClose();
                });
            });
    }

    return (
        <div>
            {hits.map(hit => (
                <div>
                    <div>
                    </div>
                    <div className={styles.Hit} key={hit.objectID}>
                        <div className={styles.HitContent}>
                            <div className={styles.HitAddButton}>
                                <Tooltip title="Delete item from your collection">
                                    <IconButton onClick={(e) => handleDeleteItem(hit.objectID, hit.yulelogID, user, hit.status)(e)} aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className={styles.HitTextbox}>
                                {(hit.brand != "Custom") &&
                                    <div className={styles.HitYearBoxnum}>
                                        <Tooltip placement="top" arrow title="Year item was released">
                                            <div className={styles.HitYear}>
                                                {(hit.year === 0) ? null : hit.year}
                                            </div>
                                        </Tooltip>
                                        <Tooltip placement="top" arrow title="Box number">
                                            <div className={styles.HitBoxnum}>
                                                {hit.boxnum}
                                            </div>
                                        </Tooltip>
                                    </div>
                                }
                                {(hit.collector || hit.collection) && <div className={styles.CollectionInfo}>
                                    <Tooltip placement="right" arrow title={getCollectionInfoLabel(hit.collector, hit.collection)}>
                                        <Chip sx={{ marginRight: '10px' }} avatar={<Avatar sx={{ width: 5, height: 5 }} />} className={styles.Artist} label={getCollectionInfo(hit.collector, hit.collection)} size="small" />
                                    </Tooltip>
                                </div>}
                                <div className={styles.LabelItems}>
                                    {(hit.brand === "Custom") && <Chip className={styles.ConditionChip} color="warning" size="small" label="Custom Item" />}
                                    {(hit.status != "Wanted") && <Tooltip placement="right" arrow title={"Item condition"}><Chip className={styles.ConditionChip} size="small" label={hit.condition} /></Tooltip>}
                                    {(hit.status === "Wanted") && <Tooltip placement="right" arrow title="This item is on your wishlist."><FavoriteIcon sx={{ color: 'salmon', minWidth: '50px', marginLeft: '-15px' }} /></Tooltip>}
                                    {hit.signed && <Chip className={styles.ConditionChip} icon={<AssignmentTurnedInIcon style={{ color: "#7d54b4" }} fontSize="small" />} size="small" label={"Signed"} />}
                                </div>
                                <div className={styles.HitName}>
                                    <Tooltip placement="right" arrow title="Item name. Click for more details.">
                                        <Link style={{ textDecoration: 'none' }} className={styles.HitLink} onClick={(e) => handleItemDetails(hit.yulelogID, hit.objectID, hit.brand)(e)}>{hit.name}</Link>
                                    </Tooltip>
                                    {hit.quantity > 1 && <Tooltip placement="right" arrow title="Item quantity.">
                                        <span className={styles.QtyBox}><b>x {hit.quantity}</b></span></Tooltip>}
                                </div>
                                {showD56 && <div className={styles.HitSeries}>
                                    <Tooltip placement="left" arrow title="Dept. 56 Village to which this item belongs.">
                                        <b>{hit.village}</b>
                                    </Tooltip>
                                </div>}
                                <div className={styles.HitSeries}>
                                    <Tooltip placement="right" arrow title="Item series. * indicates an unofficial series.">
                                        <b>{hit.series}</b>
                                    </Tooltip>
                                    {hit.seriesnum > 0 ? " #" + hit.seriesnum : ""}
                                </div>

                                <div className={styles.HitArtist}>
                                    <Tooltip placement="right" arrow title="Item artist.">
                                        <b>{hit.artist.join(', ')}</b>
                                    </Tooltip>
                                </div>
                                <div className={styles.HitPrices}>
                                    <b>Box Price:</b> <NumberFormat decimalScale={2} fixedDecimalScale value={hit.boxPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    <br />
                                    <b>MIB Price:</b> <NumberFormat decimalScale={2} fixedDecimalScale value={hit.mibPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </div>
                                {hit.brand !== "Custom" && <Comments comments={hit.pgComments} />}
                                {hit.brand === "Custom" && <Comments comments={hit.myComments} />}
                            </div>
                            <div className={styles.HitImageWrapper}>
                                <img className={styles.HitImage} alt="collection item" src={hit.imageURL || defaultImage} />
                            </div>
                        </div>
                    </div>
                </div >
            ))
            }
            {(collectionData && itemData) ?
                < CollectionModal
                    name={itemData.name}
                    customName={collectionData.name}
                    customYear={collectionData.year}
                    artist={itemData.artist}
                    boxnum={itemData.boxnum}
                    customBoxnum={collectionData.boxnum}
                    year={itemData.year}
                    category={itemData.category}
                    village={itemData.village}
                    customVillage={collectionData.village}
                    brand={collectionData.brand}
                    series={itemData.series}
                    seriesnum={itemData.seriesnum}
                    customSeries={collectionData.series}
                    imageurl={collectionData.customPhoto ? collectionData.imageURL : itemData.imageURL}
                    mibPrice={renderMibPrice(itemData)}
                    boxPrice={itemData.boxPrice}
                    price2017={itemData.price2017}
                    price2018={itemData.price2018}
                    price2019={itemData.price2019}
                    price2020={itemData.price2020}
                    pgComments={itemData.pgComments}
                    pgImg={itemData.imageURL}
                    videoURL={itemData.videoURL}
                    objectID={itemData.objectID}
                    yulelogID={itemData.yulelogID}
                    keywords={itemData.keywords}
                    collector={collectionData.collector}
                    collection={collectionData.collection}
                    condition={collectionData.condition}
                    customImg={collectionData.imageURL}
                    showCustomImage={collectionData.customPhoto}
                    dateBought={collectionData.dateBought}
                    dateSold={collectionData.dateSold}
                    displayLocation={collectionData.displayLocation}
                    myComments={collectionData.myComments}
                    quantity={collectionData.quantity}
                    priceBought={collectionData.priceBought}
                    priceSold={collectionData.priceSold}
                    purchaseLocation={collectionData.purchaseLocation}
                    signed={collectionData.signed}
                    status={collectionData.status}
                    storageLocation={collectionData.storageLocation}
                    user={user}
                    show={modalShow}
                    animation={false}
                    hide={handleItemDetailsClose}
                    onExited={handleItemDetailsClose}
                    deleteItem={handleDeleteItem}
                    saveItem={handleSaveItem}
                    shareLink={handleLinkClick}
                    shopVisited={handleShopVisited}
                    shouldRefresh={parentCallback}
                />
                : null
            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={snackbarText}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    )
};

const CustomCollectionHits = connectHits(CollectionHits);

export default CustomCollectionHits;