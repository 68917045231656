import React from 'react';
import * as ROUTES from '../../../constants/routes';

import { Link } from 'react-router-dom';
import './NavigationItem.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const NavigationItem = (props) => {

    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLegacyClicked = () => {
        setOpen(true)
        // window.open('https://yulelog-software.myshopify.com/')
    }

    const navigateToStore = () => {
        window.open('https://yulelog-software.myshopify.com/')
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Looking for YuleLog Legacy?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        In 2022, a new version of YuleLog - <b>YuleLog for Web</b> - was released. It is a totally separate program than the version of YuleLog has produced for many years.
                    </DialogContentText>
                    <br></br>
                    <DialogContentText id="alert-dialog-description">
                        <b>New and existing users are encouraged to use YuleLog for Web</b>
                    </DialogContentText>
                    <br></br>
                    <DialogContentText id="alert-dialog-description">
                        <b>YuleLog Legacy</b> refers to the offline, older FileMaker version of YuleLog that has been produced and distributed since 1996.
                    </DialogContentText>
                    <br></br>
                    <DialogContentText id="alert-dialog-description">
                        <b>YuleLog for Web</b> is the latest and greatest version of YuleLog that syncs your collection across all of your desktop and mobile devices.
                    </DialogContentText>
                    <br></br>
                    <DialogContentText id="alert-dialog-description">
                        Not sure which is right for you? Contact us at <a href="mailto:sean@yulelog.com">sean@yulelog.com</a>
                    </DialogContentText>
                    <br></br>
                    <DialogContentText id="alert-dialog-description">
                        <a href="https://help.yulelog.com/hallmark/v/yulelog-for-web/faq">Learn More</a>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant='contained' onClick={navigateToStore} autoFocus>
                        Take Me to YuleLog Legacy
                    </Button>
                </DialogActions>
            </Dialog>
            {props.legacy
                ? <li className="unauth-menu" >
                    <a onClick={handleLegacyClicked} href='#'>YuleLog Legacy</a>
                </li >
                : <li className="unauth-menu" >
                    <Link to={props.link}>{props.text}</Link>
                </li >
            }
        </>
    )
}

export default NavigationItem;