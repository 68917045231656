import React from 'react';
import { Card, CardContent, Typography, Divider } from '@mui/material'
import Chart from "react-google-charts";

const ReportPieChart = (props) => {

    const hallmarkCount = props.brands.hallmark;
    const dept56Count = props.brands.d56;
    const customCount = props.brands.custom;

    let chartData = [
        ['Brand', 'Items'],
        ['Hallmark', hallmarkCount],
        ['Department 56', dept56Count],
        ['Custom', customCount]
    ];

    const renderChart = () => {

        if (hallmarkCount + dept56Count + customCount === 0) {
            chartData = [
                ['Brand', 'Items'],
                ['No Data Available', 1]
            ]

            return <Chart
                width={'100%'}
                height={'400px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={chartData}
                options={{
                    sliceVisibilityThreshold: 0,
                    legend: { position: 'bottom', textStyle: { fontSize: 12 } },
                    fontSize: 20,
                    pieHole: 0.4,
                    chartArea: { left: 10, top: 50, right: 10, width: '60%', height: '60%' },
                    // Just add this option
                    pieSliceText: 'percentage',
                    slices: {
                        0: { color: 'lightgray' },
                    },
                }}
                rootProps={{ 'data-testid': '3' }}
            />

        }

        return <Chart
            width={'100%'}
            height={'400px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={{
                sliceVisibilityThreshold: 0,
                legend: { position: 'bottom', textStyle: { fontSize: 12 } },
                fontSize: 20,
                pieHole: 0.4,
                chartArea: { left: 10, top: 50, right: 10, width: '70%', height: '70%' },
                // Just add this option
                pieSliceText: 'percentage',
                slices: {
                    0: { color: '#7851a9' },
                    1: { color: '#A52A2A' }
                },
            }}
            rootProps={{ 'data-testid': '3' }}
        />

    }

    return (
        <Card style={{ height: '450px' }} {...props}>
            <CardContent>
                <Typography
                    color="textPrimary"
                    variant="h5">
                    <b>Items by Brand</b>
                </Typography>
                {renderChart()}
            </CardContent>
        </Card >
    )
}

export default ReportPieChart;