import React from 'react'

import { Link } from 'react-router-dom';

import FeatureList from '../../FeatureList/FeatureList.js';
import LandingButton from '../../LandingButton/LandingButton';

import './Plan.css';

const Plan = (props) => (
    <div className="price-item">
        <div className="price-head">
            <h5 className="head-text">{props.head}</h5>
        </div>
        <div className="price-features">
            <FeatureList features={props.featureList} />
        </div>
        <div className="price-box">
            <div className="price-text">
                <div className="price-value">
                    <h2 className="price-header">
                        <span className="dollarsign">$</span>
                        {props.price}
                        <span className="subtext">per year</span></h2>
                </div>
                <div className="price-subtext">
                </div>
            </div>
            <div className="price-button">
                <Link to="/signin">
                    <LandingButton buttonText="Subscribe" btnType="landing-btn" />
                </Link>
            </div>
        </div>
    </div>

)

export default Plan;