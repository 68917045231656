import React from 'react';

import { Card, CardContent, Grid, Typography, Avatar } from '@mui/material';
import NumberFormat from 'react-number-format';
import styles from './ReportSummaryBox.module.css';

const ReportSummaryBox = (props) => {

    let value = props.number;
    let sign = Math.sign(value);
    let color = "textPrimary";
    if (props.colorScheme !== "neutral") {
        if (sign === -1) {
            color = "red"
        }
        if (sign === 1) {
            color = "green"
        }
    }

    return (
        <Card {...props}>
            <CardContent>
                <Grid
                    container justifyContent='space-between' spacing={3}>
                    <Grid item >
                        <Typography
                            gutterBottom
                            variant="h6">
                            {props.label}
                        </Typography>
                        {props.type === "currency"
                            ?
                            <Typography
                                style={{ color: color }}
                                variant="h4">
                                <NumberFormat
                                    value={props.number}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'} />
                            </Typography>
                            : <Typography
                                color="textPrimary"
                                variant="h4">
                                {props.number}
                            </Typography>
                        }

                    </Grid>
                    <Grid item justify="flex-end" >
                        {props.children}
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )

}

export default ReportSummaryBox;