import React, { Fragment } from 'react';
import { Card, CardContent, Grid, Typography, Divider } from '@mui/material'
import NumberFormat from 'react-number-format';
import styles from '../../Dashboard/Dashboard.module.css';
import santagift from '../../../../img/santagift.png';

import ValuablesRow from './ValuablesRow/ValuablesRow';
import { StylesContext } from '@mui/styles';

const ReportValuables = (props) => {

    const renderValuables = () => {
        if (props.items.length === 0) {
            return (
                <Fragment>
                    <div className={styles.EmptyDashboard}>
                        <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6">
                            Nothing here yet!
                        </Typography>
                        <img class={styles.EmptyImage} width='100px' src={santagift} />
                        <br></br><br></br>
                        <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="caption">
                            Add items to your collection and find out which of them are the most valuable on the secondary market.
                        </Typography>
                    </div>
                </Fragment>)
        }

        return (props.items.map((i, index) => <Fragment>
            <ValuablesRow index={index} name={i.name ? i.name : ""} boxnum={i.boxnum ? i.boxnum : "No Boxnum"} value={<NumberFormat value={i.mibPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />} />
            <Divider />
            <br></br>
        </Fragment>))
    }

    return (
        <Card style={{ height: '450px' }} {...props}>
            <CardContent>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h5">
                    <b>Most Valuable Items</b>
                </Typography>
                {renderValuables()}
            </CardContent>
        </Card>
    )
}

export default ReportValuables;