import React from 'react';
import styles from './ConditionSelect.module.css';
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const ConditionSelect = (props) => {

    const [itemCondition, setCondition] = React.useState(props.conditionValue);

    const handleChange = (event) => {
        setCondition(event.target.value);
        props.changed("condition", event.target.value)
    };

    return (
        <div className={styles.ConditionSelect}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={itemCondition}
                    label={"Condition"}
                    onChange={handleChange}
                >
                    <MenuItem value={"Never Removed From Box"}>Never Removed From Box</MenuItem>
                    <MenuItem value={"Mint In Box"}>Mint In Box</MenuItem>
                    <MenuItem value={"No Price Tag"}>No Price Tag</MenuItem>
                    <MenuItem value={"Slightly Damaged Box"}>Slightly Damaged Box</MenuItem>
                    <MenuItem value={"Damaged Box"}>Damaged Box</MenuItem>
                    <MenuItem value={"No Box"}>No Box</MenuItem>

                </Select>
            </FormControl>
        </div>
    )
};

export default ConditionSelect;