import { connectStateResults, Pagination } from 'react-instantsearch-dom';
import SantaChimney from '../../../img/santachimney.png';
import SantaGift from '../../../img/santagift.png';
import BookIcon from '@mui/icons-material/Book';

import styles from './CustomStateCollectionResults.module.css';
import './CustomStateCollectionResults.css';
import { Grid, Button } from '@mui/material';

const StateCollectionResults = ({ props, searchResults }) => {
    const hasResults = searchResults && searchResults.nbHits !== 0;
    const nbHits = searchResults && searchResults.nbHits;
    const time = searchResults && searchResults.processingTimeMS;

    const goToPriceGuide = () => {
        return window.location.href = '/priceguide'
    };

    return (
        <div style={{ marginLeft: '50px' }}>
            <div hidden={!hasResults}>
                <div className={styles.Pagination}>
                    <Pagination />
                </div>
                <div className={styles.Results}>
                    {nbHits} results found in {time} ms.
                </div>
            </div>
            {props.isEmptyCollection ? <div className={styles.NoItemsWrapper} >
                <center>
                    <h4><b>Welcome to your Collection!</b></h4>
                    <img className={styles.NoResultsImage} src={SantaGift} />
                    <br></br><br></br>
                    When you add items from the <b>Price Guide</b>, they will appear here.
                    <br></br>
                    <br></br>
                    <Button
                        variant="outlined"
                        startIcon={<BookIcon />}
                        onClick={goToPriceGuide}>Go to Price Guide</Button><br></br><br></br>
                    <a href="https://help.yulelog.com/hallmark/v/yulelog-for-web/collection/collection" target="_blank">Learn more</a>
                </center>
                <div className={styles.NoResultsText}>
                    <Grid justifyContent='space-between' container>
                        <Grid item>

                        </Grid>
                    </Grid>
                </div>
            </div>
                :
                <div className={styles.NoResultsWrapper} hidden={hasResults}>
                    <center>
                        <img className={styles.NoResultsImage} src={SantaChimney} />

                        <h6><b>No results found</b></h6>
                        Try searching by:
                        <br></br>
                        <br></br>
                    </center>
                    <div className={styles.NoResultsText}>
                        <Grid justifyContent='space-between' container>
                            <Grid item>
                                <ul>
                                    <li>Name</li>
                                    <li>Box #</li>
                                    <li>Keyword</li>
                                </ul>
                            </Grid>
                            <Grid item>
                                <ul>
                                    <li>Year</li>
                                    <li>Series</li>
                                    <li>Artist</li>
                                </ul>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </div >
    );
};

const CustomStateCollectionResults = connectStateResults(StateCollectionResults);

export default CustomStateCollectionResults;