const keywords = [
    "Airplane",
    "Angel",
    "Baby's First",
    "Ball",
    "Bear",
    "Beaver",
    "Bells",
    "Bird",
    "Boat",
    "Book",
    "Brass",
    "Bunny",
    "Car",
    "Cat",
    "Children",
    "Chipmunk",
    "Church",
    "Clip-On",
    "Club",
    "Coca-Cola",
    "Deer",
    "Disney",
    "Dog",
    "Doll",
    "Dove",
    "Duck",
    "Egg",
    "Elephant",
    "Elf",
    "Eskimo",
    "Flower",
    "Food",
    "Fox",
    "Halloween",
    "Heart",
    "House",
    "Jack-O-Lantern",
    "Kitten",
    "Lamb",
    "Love",
    "Monkey",
    "Mouse",
    "Mrs. Claus",
    "Music",
    "Nativity",
    "Owl",
    "Partridge",
    "Patriotic",
    "Penguin",
    "Pewter",
    "Photo Holder",
    "Pig",
    "Polar Bear",
    "Porcelain",
    "Puppy",
    "Raccoon",
    "Reindeer",
    "Religion",
    "Rocking Horse",
    "Santa",
    "Seal",
    "Skater",
    "Skunk",
    "Sleigh",
    "Snowflake",
    "Snowman",
    "Soldier",
    "Squirrel",
    "Star",
    "Stocking Hanger",
    "Tin",
    "Train",
    "Tree",
    "Tree-Topper",
    "Turtle",
    "Wreath",
    "Yarn",
]

export default keywords;