import React, { useEffect, useRef } from 'react';
import algoliasearch from 'algoliasearch';
import { orderBy } from 'lodash';
import { makeStyles } from '@mui/styles';
import Collapsible from 'react-collapsible';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import DrawerList from '../../components/Drawers/DrawerList';
import List from '@mui/material/List';
import Hits from '../../components/Hit/Hit';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert/Alert';
import { InstantSearch, RefinementList, MenuSelect, ClearRefinements, Configure, Panel } from 'react-instantsearch-dom';

import logo from '../../img/yulelogicon.png';
import IconButton from '@mui/material/IconButton';
import Dept56Icon from '../../components/Icons/Dept56Icon/Dept56Icon';
import HallmarkIcon from '../../components/Icons/HallmarkIcon/HallmarkIcon';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/HelpCenterRounded';
import BarcodeIcon from '@mui/icons-material/DocumentScanner';
import CustomToolbar from '../../components/UI/CustomToolbar/CustomToolbar';
import CustomStateResults from '../../components/UI/CustomStateResults/CustomStateResults';
import CollapsibleHeader from '../../components/UI/CollapsibleHeader/CollapsibleHeader';
import CustomClearRefinements from '../../components/UI/CustomClearRefinements/CustomClearRefinements';
import ActivateProductModal from '../../components/UI/Modal/ActivateProductModal/ActiveProductModal';
import BarcodeScanModal from '../../components/UI/Modal/BarcodeModal/BarcodeModal';
import DetailsModal from '../../components/UI/Modal/PriceGuideModal/PriceGuideModal';
import CollectionModal from '../../components/UI/Modal/CollectionModal/CollectionModal';

import styles from './PriceGuide.module.css';
import 'instantsearch.css/themes/satellite.css';
import './PriceGuide.css';

import { AuthUserContext, withAuthorization } from '../../components/Session';
import { withFirebase } from '../../components/Firebase';

import { generateRandomId } from '../../lib/generateRandomId';
import { tooltipsText } from '../../constants/properties';


const drawerWidth = 240;

const searchClient = algoliasearch(process.env.REACT_APP_SEARCH_ID, process.env.REACT_APP_ALGOLIA_SEARCHONLY_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: 400
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const PriceGuide = ({ firebase }) => {

    document.title = 'YuleLog Price Guide';

    var collectionItems = JSON.parse(localStorage.getItem("collectionItems")) || [];
    let wantedCollectionItems = JSON.parse(localStorage.getItem("wantedCollectionItems")) || [];

    var user = firebase.auth.currentUser.uid;
    const ALGOLIA_ID = 'G89C4NZVH8';
    const ALGOLIA_ADMIN_KEY = localStorage.getItem("search");
    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);

    const [activePriceGuide, setActivePriceGuide] = React.useState(true);
    const [showActivateModal, setActivateModal] = React.useState(false);
    const [showBarcodeModal, setBarcodeModal] = React.useState(false);
    const [showSnackbar, setSnackbarOpen] = React.useState(false);
    const [collectionModalShow, setCollectionModalShow] = React.useState(false);
    const [barcode, setBarcode] = React.useState("")
    const [itemData, setItemData] = React.useState(0);
    const [modalShow, setModalShow] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [collectionData, setCollectionData] = React.useState(0);

    let activeIndex = activePriceGuide ? "hallmarkOrnaments" : "dept56Items";
    let toolbarTitle = activePriceGuide ? "Hallmark Price Guide" : "Dept. 56 Price Guide";
    sessionStorage.setItem('activeDb', activePriceGuide ? "hallmark" : "dept56");

    const getOrDefault = (storedValue, defaultValue = "") => {
        if (storedValue != null) {
            return storedValue
        }
        else return ""
    }

    /* Check if user is a Hallmark subscriber. Redirect to Dept 56 if not. */
    useEffect(() => {
        firebase.auth.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
                // Confirm the user is an Admin.
                if (!!idTokenResult.claims.hallmarkEnabled) {
                    // User is admin
                } else {
                    // Show Dept. 56 Price Guide.
                    setActivePriceGuide(false);
                }
            })
            .catch((error) => {
                alert(error);
            });

        const getParameterByName = (name, url) => {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
            const results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        };


        // Function to open the ornament based on the parameter
        const openOrnament = () => {
            const ornamentId = getParameterByName('ornament');
            if (ornamentId) {
                // Your logic to open the ornament based on the ornamentType
                handleItemDetails(ornamentId)
            }
        };

        // Run the openOrnament function on component mount
        openOrnament();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);




    /* Check if user has proper access enabled */
    const handlePriceGuideChange = (activeIndex) => {
        firebase.auth.currentUser.getIdToken(true)
        if (activePriceGuide) {
            firebase.auth.currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    if (!!idTokenResult.claims.d56Enabled) {
                        // Show d56 UI.
                        setActivePriceGuide(false)
                    } else {
                        // Show regular hallmark UI.
                        setActivateModal(true);
                    }
                })
                .catch((error) => {
                    alert(error);
                });
        }
        if (!activePriceGuide) {
            firebase.auth.currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    if (!!idTokenResult.claims.hallmarkEnabled) {
                        // Show admin UI.
                        setActivePriceGuide(true)
                    } else {
                        // Show regular user UI.
                        setActivateModal(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    alert(error);
                });
        }
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleDeleteItem = (objectID, yulelogID, user, status) => (event) => {
        firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID)
            .delete()
            .then(doc => {
                const index = client.initIndex("userItems");
                // TODO: Call snackbar here
                index.deleteObject(objectID).then(() => {

                    if (status === "Wanted") {
                        let oldWantedArr = JSON.parse(localStorage.getItem("wantedCollectionItems"));
                        var indexToRemove = oldWantedArr.indexOf(yulelogID);
                        // If the element is found, remove it
                        if (indexToRemove !== -1) {
                            oldWantedArr.splice(indexToRemove, 1);
                        }
                        localStorage.setItem("wantedCollectionItems", JSON.stringify(oldWantedArr));
                    } else {
                        let oldArr = JSON.parse(localStorage.getItem("collectionItems"));
                        var indexToRemove = oldArr.indexOf(yulelogID);
                        // If the element is found, remove it
                        if (indexToRemove !== -1) {
                            oldArr.splice(indexToRemove, 1);
                        }
                        localStorage.setItem("collectionItems", JSON.stringify(oldArr));
                    }

                    sessionStorage.setItem("shouldRefreshCollection", "true");
                    handleItemDetailsClose();
                });
            });
    }

    const handleSaveItem = (objectID, user, item) => (event) => {

        alert("Saving...")

        let updateObj = {
            dateBought: item.dateBought,
            dateSold: item.dateSold,
            myComments: item.myComments,
            priceBought: item.priceBought,
            priceSold: item.priceSold,
            condition: item.condition,
            quantity: item.quantity,
            status: item.status,
            signed: item.signed,
            purchaseLocation: item.purchaseLocation,
            displayLocation: item.displayLocation,
            storageLocation: item.storageLocation,
            collector: item.collector,
            collection: item.collection
        }

        firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID)
            .update(updateObj).then(function () {

                const index = client.initIndex("userItems");

                index.partialUpdateObject({
                    ...updateObj,
                    objectID: objectID
                }).then(() => {
                    console.log("UPDATED ITEM")
                    handleItemDetailsClose();
                    sessionStorage.setItem("shouldRefreshCollection", "true");
                });
            });

        let arrObj = {};
        if (item.purchaseLocationArray) {
            arrObj['purchaseLocation'] = item.purchaseLocationArray;
            localStorage.setItem("purchaseLocations", JSON.stringify(item.purchaseLocationArray));
        }
        if (item.displayLocationArray) {
            arrObj['displayLocation'] = item.displayLocationArray;
            localStorage.setItem("displayLocations", JSON.stringify(item.displayLocationArray));
        }
        if (item.storageLocationArray) {
            arrObj['storageLocation'] = item.storageLocationArray;
            localStorage.setItem("storageLocations", JSON.stringify(item.storageLocationArray));
        }
        if (item.collectorArray) {
            arrObj['collector'] = item.collectorArray;
            localStorage.setItem("collectors", JSON.stringify(item.collectorArray));
        }
        if (item.collectionArray) {
            arrObj['collection'] = item.collectionArray;
            localStorage.setItem("collections", JSON.stringify(item.collectionArray));
        }

        /* Only update Firestore locations array if changes were detected and object is populated */
        if (item.updatedArray && Object.entries(arrObj).length > 0) {

            firebase.db.collection("users").doc(user).collection("userinfo").doc("locations").update(
                arrObj
            )
                .then(function () {
                }).catch(function (error) {
                    // The document probably doesn't exist.
                });
        }
    }

    const handleAddItemAndEdit = (yulelogID) => (event) => {
        let yulelogIDString = yulelogID.toString();
        firebase.db.collection("hallmark").doc(yulelogIDString)
            .get()
            .then(doc => {
                const ornament = doc.data();
                var randomID = generateRandomId(28);
                ornament.timeStamp = Math.round(+new Date() / 1000)
                /* Set MIB Price based on brand */
                if (ornament.brand === "Hallmark") { ornament.mibPrice = ornament.prices.price2024 };
                if (ornament.brand === "Department 56") { ornament.mibPrice = ornament.prices.price2024 };

                /* Remove price history for collection ornament. Only mibPrice is necessary */
                let { price2015, price2016, price2017, price2018, price2019, price2020, price2021, price2022, prices, ...collectionOrnament } = ornament;

                /* Set default values */
                collectionOrnament.collector = getOrDefault(localStorage.getItem("collectorDefault"), "");
                collectionOrnament.collection = getOrDefault(localStorage.getItem("collectionDefault"), "");
                collectionOrnament.displayLocation = getOrDefault(localStorage.getItem("displayLocationDefault"), "");
                collectionOrnament.purchaseLocation = getOrDefault(localStorage.getItem("purchaseLocationDefault"), "");
                collectionOrnament.storageLocation = getOrDefault(localStorage.getItem("storageLocationDefault"), "");

                firebase.db.collection("users").doc(user).collection("maincollection").doc(randomID).set(collectionOrnament).then(doc => {
                    const itemID = randomID;
                    const index = client.initIndex("userItems");
                    // TODO: Call snackbar here
                    collectionOrnament.objectID = itemID;
                    collectionOrnament.user = user;
                    index.saveObject(collectionOrnament);
                    collectionItems.push(yulelogID);
                    localStorage.setItem("collectionItems", JSON.stringify(collectionItems));
                    sessionStorage.setItem("shouldRefreshCollection", "true");
                    var yulelogElement = document.getElementById(yulelogIDString);
                    if (yulelogElement) {
                        yulelogElement.style.display = 'block';
                    }

                    handleClick();
                    setCollectionData(collectionOrnament);
                    setModalShow(false);
                    setCollectionModalShow(true)
                })
            });
    }

    const handleUpgrade = () => {
        var docRef = firebase.db.collection("users").doc(user).collection("userinfo").doc("customer");
        docRef.get().then(function (doc) {
            if (doc.exists) {
                if (doc.data().platform == "iOS") {
                    alert("You are subscribed to YuleLog on your mobile device. You can upgrade your subscription from the mobile app's main menu.")
                } else {
                    if (activePriceGuide) {
                        docRef.set({
                            d56Enabled: true,
                            plan: 3
                        }, { merge: true });
                    }
                    if (!activePriceGuide) {
                        docRef.set({
                            hallmarkEnabled: true,
                            plan: 3
                        }, { merge: true });
                    }
                    handleClose();
                    setSnackbarOpen(true);
                    setActivePriceGuide(!activePriceGuide);
                }
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    const handleOpenBarcode = () => {
        setBarcodeModal(true);
    };

    const handleItemDetails = (yulelogID) => {
        setModalShow(true);
        yulelogID = yulelogID.toString();

        // Get the single doc from the collection
        firebase.db
            .collection("hallmark")
            .doc(yulelogID)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    setItemData(data);
                } else {
                    // Display an alert if no item details are found
                    alert("Item details not found!");
                    setModalShow(false); // Close the modal or handle as needed
                }
            })
            .catch((error) => {
                console.error("Error getting item details:", error);
            });
    };

    const handleItemDetailsClose = () => {
        setItemData(0);
    }

    const handleBarcodeMatch = async (barcode) => {
        setBarcode(barcode)
        const barcodeNum = barcode.substring(1);
        const hallmarkCollection = firebase.db.collection('hallmark');
        const query = hallmarkCollection.where('barcode', '==', barcodeNum);
        try {
            const querySnapshot = await query.get();

            if (!querySnapshot.empty) {
                // If the document is found, set the data in the state
                const docSnapshot = querySnapshot.docs[0];
                const data = docSnapshot.data();
                const documentId = docSnapshot.id;
                handleItemDetails(documentId)
            } else {
                // Document with the specified barcode not found
                console.log('Item not found');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleCloseBarcode = () => {
        setBarcodeModal(false);
    };

    const handleClose = () => {
        setActivateModal(false);
    }

    const handleAddedSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const classes = useStyles();

    return (
        <div>
            <AuthUserContext.Consumer>
                {authUser => (
                    <InstantSearch searchClient={searchClient} indexName={activeIndex}>
                        <div className={classes.root}>
                            <CssBaseline />
                            <AppBar position="fixed" className={classes.appBar}>
                                <CustomToolbar title={toolbarTitle}>
                                    <Tooltip title={tooltipsText.priceGuide.scanBarcode}>
                                        <IconButton onClick={() => handlePriceGuideChange()} className={styles.IconButton}>
                                            {activePriceGuide ? <Dept56Icon /> : <HallmarkIcon />}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={tooltipsText.priceGuide.help}>
                                        <IconButton className={styles.IconButton}>
                                            <HelpIcon fontSize='large' onClick={() => window.open('https://help.yulelog.com/hallmark/v/yulelog-for-web/')} className={styles.IconButton} />
                                        </IconButton>
                                    </Tooltip>
                                    {activePriceGuide && <Tooltip title={tooltipsText.priceGuide.scanBarcode}>
                                        <IconButton disabled={!activePriceGuide} className={styles.RotateIcon}>
                                            <BarcodeIcon fontSize='large' onClick={() => handleOpenBarcode()} className={styles.IconButton} />
                                        </IconButton>
                                    </Tooltip>
                                    }
                                </CustomToolbar>
                            </AppBar>
                            <Drawer
                                className={classes.drawer}
                                variant="permanent"
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                <Toolbar />
                                <DrawerList pageSelected={1} />
                                <div className={classes.drawerContainer}>
                                    <List />
                                    <CustomClearRefinements />
                                    <Collapsible trigger={<CollapsibleHeader title="Year" />}>
                                        <Panel className={styles.Panel}>
                                            <RefinementList
                                                attribute="year"
                                                limit={100}
                                                transformItems={items => orderBy(items, "label", "desc")} />
                                        </Panel>
                                    </Collapsible>
                                    <Divider />
                                    {!activePriceGuide && <>
                                        <Divider />
                                        <Panel className={styles.Panel} header="Village">
                                            <MenuSelect attribute="village" limit={1000} transformItems={items => orderBy(items, "label", "asc")} />
                                        </Panel>
                                        <Divider />
                                    </>
                                    }
                                    <Panel className={styles.Panel} header="Series">
                                        <MenuSelect attribute="series" limit={1000} transformItems={items => orderBy(items, "label", "asc")} />
                                    </Panel>
                                    {activePriceGuide && <>
                                        <Divider />
                                        <Collapsible trigger={<CollapsibleHeader title="Artist" />}>
                                            <Panel className={styles.Panel}>
                                                <RefinementList attribute="artist" limit={1000} transformItems={items => orderBy(items, "label", "asc")} />
                                            </Panel>
                                        </Collapsible>
                                    </>
                                    }
                                    <Divider />
                                    <Collapsible trigger={<CollapsibleHeader title="Category" />} >
                                        <Panel className={styles.Panel}>
                                            <RefinementList attribute="category" />
                                        </Panel>
                                    </Collapsible>

                                </div>
                            </Drawer>
                            <main className={classes.content}>
                                <Toolbar />
                                <Configure
                                    hitsPerPage={50}
                                />
                                <div className="Hits">
                                    <Hits user={user} className={styles.Hits} />
                                    <CustomStateResults />
                                </div>
                            </main>
                        </div>
                    </InstantSearch>
                )
                }
            </AuthUserContext.Consumer >
            <ActivateProductModal
                cancelClicked={handleClose}
                show={showActivateModal}
                product={activePriceGuide ? "Department 56" : "Hallmark"}
                clicked={() => handleUpgrade(activePriceGuide)}
            />
            <BarcodeScanModal
                show={showBarcodeModal}
                matchFound={handleBarcodeMatch}
                cancelClicked={handleCloseBarcode}
            />
            <DetailsModal
                yulelogID={itemData.yulelogID}
                name={itemData.name}
                brand={itemData.brand}
                artist={itemData.artist}
                boxnum={itemData.boxnum}
                year={itemData.year}
                category={itemData.category}
                village={itemData.village}
                series={itemData.series}
                seriesnum={itemData.seriesnum}
                imageurl={itemData.imageURL}
                videoURL={itemData.videoURL}
                mibPrice={itemData.prices}
                boxPrice={itemData.boxPrice}
                prices={itemData.prices}
                price2017={itemData.price2017}
                price2018={itemData.price2018}
                price2019={itemData.price2019}
                price2020={itemData.price2020}
                price2021={itemData.price2021}
                price2022={itemData.price2022}
                pgComments={itemData.pgComments}
                keywords={itemData.keywords}
                show={modalShow}
                animation={false}
                onHide={() => setModalShow(false)}
                onExited={handleItemDetailsClose}
                addItem={handleAddItemAndEdit}
            />
            {(collectionData && itemData) ? <CollectionModal
                name={itemData.name}
                artist={itemData.artist}
                boxnum={itemData.boxnum}
                year={itemData.year}
                category={itemData.category}
                village={itemData.village}
                brand={itemData.brand}
                series={itemData.series}
                seriesnum={itemData.seriesnum}
                imageurl={collectionData.customPhoto ? collectionData.imageURL : itemData.imageURL}
                mibPrice={itemData.prices.price2024}
                boxPrice={itemData.boxPrice}
                price2017={itemData.price2017}
                price2018={itemData.price2018}
                price2019={itemData.price2019}
                price2020={itemData.price2020}
                price2021={itemData.price2021}
                pgComments={itemData.pgComments}
                pgImg={itemData.imageURL}
                objectID={collectionData.objectID}
                yulelogID={itemData.yulelogID}
                keywords={itemData.keywords}
                collector={collectionData.collector}
                collection={collectionData.collection}
                condition={collectionData.condition}
                customImg={collectionData.imageURL}
                showCustomImage={collectionData.customPhoto}
                dateBought={collectionData.dateBought}
                dateSold={collectionData.dateSold}
                displayLocation={collectionData.displayLocation}
                myComments={collectionData.myComments}
                quantity={collectionData.quantity}
                priceBought={collectionData.priceBought}
                priceSold={collectionData.priceSold}
                purchaseLocation={collectionData.purchaseLocation}
                signed={collectionData.signed}
                status={collectionData.status}
                storageLocation={collectionData.storageLocation}
                user={user}
                show={collectionModalShow}
                animation={false}
                hide={handleItemDetailsClose}
                onExited={handleItemDetailsClose}
                deleteItem={handleDeleteItem}
                saveItem={handleSaveItem}
            /> : null}
            <Snackbar
                style={{ marginTop: '60px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showSnackbar}
                onClose={handleSnackbarClose}
                autoHideDuration={10000}>
                <Alert
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}>
                    Subscription change successful! You now have access to the {activePriceGuide ? "Hallmark" : "Department 56"} Price Guide.
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={handleAddedSnackbarClose}
                message="Item added to collection"
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div >
    )
};

const condition = authUser => !!authUser;

export default withFirebase(withAuthorization(condition)(PriceGuide));