import React, { useState, useEffect } from 'react';
import Firebase from '../../components/Firebase/firebase';
import { withRouter } from "react-router-dom";
import logo from '../../img/yulelogicon.png';
import './Loading.css';
import styles from './Loading.module.css';
import algoliasearch from 'algoliasearch';
import Snowfall from 'react-snowfall'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { properties } from '../../constants/properties';
import Login from '../../components/Login/Login';
import Subscribe from '../../components/Subscribe/Subscribe';
import { getRandomLoaderText } from '../../lib/getRandomLoaderText';


const Loading = (props) => {

    const firebase = new Firebase();
    let hits = [];


    const signOutReturn = () => {
        alert("This account has been deleted. If this is incorrect, please contact sean@yulelog.com")
        firebase.doSignOut();
        return window.location.href = '/'
    }


    const getOrDefault = (storedValue) => {
        if (storedValue != null) {
            return storedValue
        }
        if (storedValue == "undefined") {
            return ""
        }
        return ""
    }

    useEffect(() => {

        firebase.auth.onAuthStateChanged(function (user) {
            if (user) {
                const userId = user.uid
                localStorage.setItem('user', userId);
                firebase.db.collection("users").doc(userId).collection("userinfo").doc("customer")
                    .get()
                    .then(doc => {
                        if (!doc.data()) {
                            return props.history.push('/subscribe')
                        }
                        const status = doc.data().status;
                        if (status === undefined) {
                            alert("Something went wrong. Redirecting you to login page. If the issue persists, please contact sean@yulelog.com")
                            props.history.push('/login')
                        } else if (status == "deleted") {
                            return signOutReturn()

                        } else if (status == "active" || status == "cancelled") {
                            firebase.db.collection("users").doc(userId).collection("userinfo").doc("search")
                                .get()
                                .then(doc => {
                                    var client = algoliasearch('G89C4NZVH8', doc.data().clientNum);
                                    var index = client.initIndex("userItems");

                                    firebase.auth.currentUser.getIdTokenResult()
                                        .then((idTokenResult) => {
                                            if (!!idTokenResult.claims.adminAccess) {
                                                localStorage.setItem("showYLID", "true");
                                            } else {
                                                localStorage.setItem("showYLID", "false");
                                            }
                                            if (!!idTokenResult.claims.d56Enabled) {
                                                localStorage.setItem("showD56", "true");
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            alert(error);
                                        });

                                    index.browseObjects({
                                        query: '', // Empty query will match all records
                                        filters: 'user:' + userId,
                                        batch: batch => {
                                            hits = hits.concat(batch);
                                        }
                                    }).then(() => {

                                        var hallmarkItems = hits.filter(function (item) {
                                            return item.brand === "Hallmark" && item.status !== "Wanted";
                                        });

                                        var wantedItems = hits.filter(function (item) {
                                            return item.brand === "Hallmark" && item.status == "Wanted";
                                        });

                                        let collectionItems = hallmarkItems.map(a => a.yulelogID);
                                        let wantedCollectionItems = wantedItems.map(a => a.yulelogID);

                                        localStorage.setItem("collectionItems", JSON.stringify(collectionItems));
                                        localStorage.setItem("wantedCollectionItems", JSON.stringify(wantedCollectionItems));


                                    });

                                    localStorage.setItem('search', doc.data().clientNum)

                                    firebase.db.collection("users").doc(userId).collection("userinfo").doc("locations")
                                        .get()
                                        .then(doc => {
                                            // Grab Collection/Collector/Locations Data
                                            localStorage.setItem("collectorDefault", getOrDefault(doc.data().collectorDefault));
                                            localStorage.setItem("collectors", JSON.stringify(doc.data().collector));
                                            localStorage.setItem("collectionDefault", getOrDefault(doc.data().collectionDefault));
                                            localStorage.setItem("collections", JSON.stringify(doc.data().collection));
                                            localStorage.setItem("purchaseLocations", JSON.stringify(doc.data().purchaseLocation));
                                            localStorage.setItem("purchaseLocationDefault", getOrDefault(doc.data().purchaseLocationDefault));
                                            localStorage.setItem("displayLocations", JSON.stringify(doc.data().displayLocation));
                                            localStorage.setItem("displayLocationDefault", getOrDefault(doc.data().displayLocationDefault));
                                            localStorage.setItem("storageLocations", JSON.stringify(doc.data().storageLocation));
                                            localStorage.setItem("storageLocationDefault", getOrDefault(doc.data().storageLocationDefault));
                                            // Navigate to collection screen
                                            props.history.push('/collection')
                                        })
                                });
                        } else {
                            localStorage.setItem('user', userId);
                            props.history.push('/subscribe')
                        }
                    });
            } else {
                console.log("No user signed in")
            }
        });
    });

    return (
        <div className="backgroundImg">
            <Snowfall snowflakeCount={5} />
            <div className="logo">
                <img className="logo-img" src={logo} alt="yulelog-ornament-logo" />
            </div>
            <div className="topMenu">
                <a href="https://help.yulelog.com/hallmark/v/yulelog-for-web/" target="_blank">
                    <HelpOutlineIcon style={{ color: 'white', fontSize: '36px' }} />
                </a>
            </div>
            <div className={styles.LoaderWrapper}>
                <div className={styles.Loader}>
                    <CircularProgress color="inherit" />
                    <br></br>
                    <span className={styles.LoaderText}>{getRandomLoaderText()}...</span>
                </div>
            </div>

        </div>
    )
}
export default withRouter(Loading);