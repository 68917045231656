import React, { Fragment } from 'react';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Summarize from '@mui/icons-material/Summarize';
import Favorite from '@mui/icons-material/Favorite';
import WatchLater from '@mui/icons-material/WatchLater';

import styles from './ReportDrawerList.module.css';

import * as ROUTES from '../../../constants/routes';

class ReportDrawerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selected: null };
    }

    updateSelected(prevSelected, selectedIndex) {
        this.setState({ selected: selectedIndex });
        if (selectedIndex == "2") {
            localStorage.setItem('reportType', 'custom');
        }
        if (selectedIndex == "3") {
            if (prevSelected === 2) {
                window.location.reload()
            }
            localStorage.setItem('reportType', 'wishlist');
        }
    }

    render() {
        const { selected } = this.state;

        return (
            <Fragment>
                {/* <div className={styles.Hint}>
                    Available Reports:
                </div> */}
                <List>
                    <Link className={styles.Link} to={ROUTES.REPORTS}>
                        <MenuItem button onClick={() => this.updateSelected(selected, 1)} selected={selected === 1}>
                            <ListItemIcon>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Collection Summary" />
                        </MenuItem>
                    </Link>
                    <Link className={styles.Link} to={ROUTES.CUSTOM_REPORT}>
                        <MenuItem button onClick={() => this.updateSelected(selected, 2)} selected={selected === 2}>
                            <ListItemIcon>
                                <Summarize />
                            </ListItemIcon>
                            <ListItemText primary="Custom Report" />
                        </MenuItem>
                    </Link>
                    <Link className={styles.Link} to={ROUTES.CUSTOM_REPORT}>
                        <MenuItem button onClick={() => this.updateSelected(selected, 3)} selected={selected === 3}>
                            <ListItemIcon>
                                <Favorite />
                            </ListItemIcon>
                            <ListItemText primary="Wishlist" /><Chip color='warning' variant='outlined' size='small' label="Beta" variant="outlined" />
                        </MenuItem>
                    </Link>
                    <Link className={styles.Link}>
                        <MenuItem disabled button onClick={() => this.updateSelected(2)} selected={selected === 2}>
                            <ListItemIcon>
                                <WatchLater />
                            </ListItemIcon>
                            <ListItemText primary="More Coming Soon" />
                        </MenuItem>
                    </Link>
                    <p className={styles.MoreReportsPara}>More reports are in the works!</p>
                    <p className={styles.MoreReportsPara2}>If you have an idea for a report, please reach out to us.</p>
                </List>
            </Fragment>
        );
    }
}

export default ReportDrawerList;