export const calculateProfit = (priceBought, priceSold) => {


    let profit = 0;

    /* Check for undefined */
    priceBought = priceBought ? priceBought : 0;
    priceSold = priceSold ? priceSold : 0;

    let priceBoughtFloat = parseFloat(priceBought);
    let priceSoldFloat = parseFloat(priceSold);

    console.log('ASDF')
    console.log(priceBoughtFloat)
    console.log(priceSoldFloat)

    /* If priceSold is 0, do not make profit negative */
    if (priceSoldFloat === 0) {
        return 0;
    }

    profit = priceSoldFloat - priceBoughtFloat;

    console.log('profit', profit)

    return profit;
}