import React from 'react'

import Plan from './Plan/Plan';

import { properties } from '../../../constants/properties';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Prices.module.css';


const Prices = (props) => (
    <div className={styles.Prices}>
        <div className="container px-4">
            <div className="row gx-3">
                <div className="col-sm">
                    <div className="border" style={{ borderRadius: '5px' }}>
                        <Plan head="Hallmark" price="39.99" featureList={properties.lists.hallmark} />
                    </div>
                </div>
                <div className="col-sm">
                    <div className="border" style={{ borderRadius: '5px' }}>
                        <Plan head="Hallmark + Dept. 56" price="49.99" featureList={properties.lists.hallmarkDept56} />
                    </div>
                </div>
                <div className="col-sm">
                    <div className="border" style={{ borderRadius: '5px' }}>
                        <Plan head="Dept. 56" price="34.99" featureList={properties.lists.dept56} />
                    </div>
                </div>
            </div>
        </div>
    </div >
)

export default Prices;