import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import style from './SettingsGroup.module.css';


const SettingsGroup = (props) => {


    return (

        <div style={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item sm={3}>
                    <h5 className={style.Header}>{props.header}</h5>
                    <p className={style.Description}>
                        {props.description}
                    </p>
                    <p className={style.Description2}>{props.description2}</p>
                </Grid>
                <Grid item sm={9}>
                    <Paper sx={{
                        padding: (theme) => theme.spacing(2),
                        textAlign: 'left',
                        color: '#7d54b4',
                    }}>
                        {props.children}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default SettingsGroup;