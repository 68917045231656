import React from 'react';
import TextField from '@mui/material/TextField';
import styles from './SeriesInput.module.css';

const SeriesInput = (props) => {

    return (
        <TextField
            variant="outlined"
            size="small"
            label="Series Name"
            defaultValue={props.value}
            InputProps={{
                style: {
                    width: 260,
                } // font size of input label
            }}
        />
    )
};

export default SeriesInput;