import React from 'react';
import { Link } from 'react-router-dom';
import SignOutButton from '../SignOut/SignOut'
import NavigationItem from './NavigationItem/NavigationItem';
import NavigationItemExternalUrl from './NavigationItem/NavigationItemExternalUrl';
import { AuthUserContext } from '../Session';

import './Navigation.css';

import * as ROUTES from '../../constants/routes';
import { RouterSharp } from '@mui/icons-material';




const Navigation = () => {



    return (
        <>
            <AuthUserContext.Consumer>
                {authUser =>
                    authUser ? <NavigationAuth /> : <NavigationNonAuth />
                }

            </AuthUserContext.Consumer>

        </>
    )
};

const NavigationAuth = () => (
    <div>
        <ul className="nav-group">
            <NavigationItem link={ROUTES.LOADING} text="Collection" />
            <NavigationItemExternalUrl url={ROUTES.FORUMS} text="Forums" />
            <NavigationItemExternalUrl url="https://help.yulelog.com/hallmark/v/yulelog-for-web/" text="Help" />
        </ul>
    </div>
);

const NavigationNonAuth = (props) => (


    <div>
        <ul className="nav-group">
            <NavigationItem legacy text="YuleLog Legacy" />
            <NavigationItemExternalUrl url={ROUTES.FORUMS} text="Forums" />
            <NavigationItemExternalUrl url="https://help.yulelog.com/hallmark/v/yulelog-for-web/" text="Help" />
            <NavigationItem link={ROUTES.SIGN_IN} text="Sign In" />
        </ul>
    </div>

);

export default Navigation;