import React from 'react'
import LandingButton from '../LandingButton/LandingButton'
import { Link } from 'react-router-dom';

import styles from './Feature.module.css';

const Feature = (props) => (
    <div>
        <div className={styles.Feature}>
            <div className="container overflow-hidden">
                <div className="row gx-5">
                    <div className="col">
                        <div className="p-3">
                            <h2 className={styles.FeatureHeadline}>{props.headline}</h2>
                            <p className={styles.FeatureSubheadline}>{props.subheadline}</p>
                            <p className={styles.FeatureDescription}>{props.description}</p></div>
                        <Link to="/signin">
                            <LandingButton btnType="main-btn" buttonText="Sign Up" />
                        </Link>
                    </div>
                    <div className="col">
                        <div className="p-3">
                            <img className={styles.FeatureImage} src={props.img} alt="yulelog-screenshot-preview" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >
)

export default Feature;