import React from 'react';
import styles from './YearSelect.module.css';
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const YearSelect = (props) => {

    const [year, setYear] = React.useState(props.customYear);

    const handleChange = (event) => {
        setYear(event.target.value);
        props.changed("customYear", event.target.value)
    };

    const years = Array.from(Array(new Date().getFullYear() - 1972), (_, i) => (i + 1973).toString()).reverse();

    return (
        <div className={styles.StatusSelect}>
            <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label-status">Year</InputLabel>
                <Select
                    labelId="demo-simple-select-label-status"
                    id="demo-simple-select-status"
                    size="small"
                    value={year}
                    defaultValue={props.value}
                    label="Year"
                    onChange={handleChange}
                >
                    {
                        years.map((yearOption, index) => {
                            return <MenuItem key={`year${index}`} value={yearOption}>{yearOption}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </div>
    )
};

export default YearSelect;