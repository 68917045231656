import React from 'react';
import TextField from '@mui/material/TextField';

import styles from './Quantity.module.css';

const Quantity = (props) => {

    const [quantity, setQuantity] = React.useState(props.quantity);
    const [error, setError] = React.useState(false);

    const handleChange = (event) => {
        if (event.target.value < 1) {
            return setQuantity(1);
        }
        if (event.target.value > 999) {
            return setQuantity(999)
        }
        props.changed("quantity", (Math.floor(event.target.value)));
        return setQuantity(Math.floor(event.target.value));
    };

    return (
        <div className={styles.Quantity}>
            <TextField
                type="number"
                helperText="Between 1-999"
                error={error}
                onChange={handleChange}
                label="Quantity"
                value={quantity}
                sx={{ m: 1, width: '14ch', textAlign: 'center' }}
                InputProps={{
                    inputProps: {
                        max: 999, min: 1
                    }
                }}
            />
        </div>
    )
}

export default Quantity;