// Features

// Headlines

export const properties = {
    barcode: {
        scan: "Scan Box Barcode",
        scanInstructions: "Hold the ornament box with the barcode facing the camera.",
        mobileScan: "<strong>Note</strong>: You can also scan barcodes with the <a href=\"https://apps.apple.com/us/app/yulelog/id1493430055\" target=\"_blank\">YuleLog iOS app</a>."
    },
    headlines: {
        featureHeadline1: "Keep track of your collection value",
        featureHeadline2: "Easy to Generate Reports",
        featureHeadline3: "Export and print data",
        tagline: "Which ornaments do I have, where are they, and what are they worth?",
        tagline2: "Instantly sync your collection across your devices",
        pricePlan: "Choose your price plan"
    },
    subHeadlines: {
        featureSubheadline1: "Up to date price information for over 20,000 items",
        featureSubheadline2: "Customize the data you want to see",
        featureSubheadline3: "YuleLog is u"
    },
    descriptions: {
        taglineCopy: "The answer is just a few mouse clicks away with YuleLog. To create your ornament inventory, all you have to do is select the items that you own from a list of all the items produced by Hallmark since 1973 and Department 56 from 1976, and they will be added to your personal list of ornaments.",
        taglineCopy2: "YuleLog is a cloud-based application, so any changes you make to your collection on your computer, tablet, or smartphone will be automatically synced across your devices.",
        featureDescription1: "The YuleLog Price Guide includes a wealth of information for items in your collection, and even tracks how the secondary market price has changed over time.",
        featureDescription2: "YuleLog allows you to create reports that show you helpful information about your collection: financials, wishlists, series, and more, with the option to choose which fields you want to include in your report.",
        pricePlan: "There are three different subscription types."
    },
    lists: {
        hallmark: ["Price Guide includes 15,000+ ornaments from 100+ series", "Secondary market values and images", "Print and export reports as PDF files or spreadsheets", "Includes Merry Miniatures, Heritage ornaments, and rare colorway ornaments"],
        dept56: ["Price Guide includes 8,000 ornaments from 25+ villages", "Secondary market values and images", "Print and export reports as PDF files or spreadsheets", "Includes Snowbabies, hanging ornaments, and a wide range of accessories"],
        hallmarkDept56: ["Access to Hallmark and Dept.56 Price Guides", "Secondary market values and images", "Print and export reports as PDF files or spreadsheets", "Includes 20,000+ items with images", "Best value"]
    },
    loaderText:
        ["Making a List and Checking it Twice",
            "Decking the Halls",
            "Confirming Data With Santa's Workshop",
            "Collating Reindeer TPS Reports",
            "Triangulating with Santa's Sleigh",
            "Elves Tabulating Data",
            "Jingling Bells and Loading Table Cells",
            "Festive Lights Blinking, Data Syncing",
            "Simulating Snowfall",
            "Loading Christmas Wonder Module",
            "Opening the Igloo Bay Door"
        ],
    loginText: {
        headline: "Welcome to YuleLog for Web",
        signup: "New to YuleLog? Enter your email address above to get started."
    },
    successText: {
        headline: "Your subscription update was successful!",
        signin: "Please log in to access your new features."
    }
}

export const tooltipsText = {
    collection: {
        deleteItem: "Delete this item from your collection",
        saveItem: "Save changes made to this item"
    },
    general: {
        artist: "Ornament artist.",
        boxnum: "Item box number.",
        boxPrice: "Original suggested retail price of the item.",
        category: "Item category.",
        custom: "This is a custom item not added from the Price Guide.",
        keyword: "Item keyword.",
        nameHit: "Item name. Click for more details.",
        nameModal: "Item name.",
        priceChange: "The price/percentage increase or decrease.",
        mibPrice: "The estimated Mint in Box (MIB) price for this item.",
        searchbar: "Search by Box #, Name, Keyword...",
        village: "The Dept. 56 village to which this item belongs.",
        series: "The series the item is associated with. * indicates an unofficial series.",
        unofficial: "* Indicates an unofficial series.",
        yulelogID: "The unique YuleLog ID for each item.",
        year: "Year item was released."
    },
    image: {
        unavailable: "No image available for this item"
    },
    priceGuide: {
        addItem: "Add item to your collection.",
        shop: "Shop for this item online.",
        copyLink: "Copy a link to this item in the price guide. Use this link to share item with others",
        itemAdded: "This item is already in your collection.",
        itemWanted: "This item is on your wishlist.",
        scanBarcode: "Scan a box barcode using your webcam.",
        switchPriceGuide: "Switch price guide.",
        help: "Get help using YuleLog."
    },
    reportButtons: {
        pdf: "Export the data displayed on this table as a PDF, which you can then print.",
        csv: "Export this data as a .csv file, which you can open in Excel.",
        showHide: "Control which columns are displayed for this report."
    }
}

export const settingsText = {
    general: {
        header: "General",
        description: "We use this information to contact you and identify your account. Each user is assigned a unique ID for record-keeping purposes.",
        deleteHeader: "Delete Account",
        deleteBody1: "Are you sure you want to delete your account? Your collection information will be totally and permanently deleted.",
        deleteBody2: "This action cannot be undone.",
        deleteDescription: "Delete your account AND collection information.",
        deleteWarning1: "Delete your account AND collection information.",
        deleteWarning2: "If you choose to delete your account, YuleLog will totally remove any comments, images or any other data associated with your collection."
    },
    billing: {
        header: "Billing",
        description: "Information regarding your subscription status and when it renews.",
        description2: "If cancelled, your subscription will expire on the renewal date."
    },
    collectors: {
        header: "Collectors",
        description: "Edit the names that appear in the auto-entry option for the collector field.",
        highlighted: "Highlighted rows indicate that a default value has been set.",
        setDefault: "You can set default values by clicking the a row's star icon."
    },
    collections: {
        header: "Collections",
        description: "Edit the names that appear in the auto-entry option for collection."
    },
    purchaseLocations: {
        header: "Purchase Locations",
        description: "Edit the names that appear in the auto-entry option for purchase location."
    },
    storageLocations: {
        header: "Storage Locations",
        description: "Edit the names that appear in the auto-entry option for storage location."
    },
    displayLocations: {
        header: "Display Locations",
        description: "Edit the names that appear in the auto-entry option for display location."
    }
}

export const COLUMN_HEADERS = {
    brand: "Brand",
    year: "Year",
    name: "Name ",
    boxnum: "Box #",
    boxPrice: "Box Price",
    artist: "Artist",
    quantity: "Qty",
    series: "Series",
    seriesnum: "Series #",
    myComments: "My Comments",
    pgComments: "Price Guide Comments",
    condition: "Condition",
    priceBought: "Price Bought",
    priceSold: "Price Sold",
    dateBought: "Date Bought",
    dateSold: "Date Sold",
    profit: "Profit",
    mibPrice: "MIB Price",
    value: "Value",
    priceChange: "Price Change",
    signed: "Signed",
    status: "Status",
    imageURL: "Image",
    collector: "Collector",
    collection: "Collection",
    displayLocation: "Display Location",
    purchaseLocation: "Purchase Location",
    storageLocation: "Storage Location"
}

export const REPORT_TEXT = {

    groupBy: "Group By:",
    none: "None",
    search: "Search:",
    showAllColumns: " Show All Columns",
    showLocations: "Show Location Columns",
    reports: "Reports"
}

export const SHORT_FEATURES = {
    d56: {
        feature1: "Contains 7,000+ items",
        feature2: "Items from 23+ villages",
        feature3: "Snowbabies, Accessories, and more"
    },
    hallmark: {
        feature1: "Contains 14,500+ items from 1973-present",
        feature2: "Search for Hallmark items by barcode using your iOS device",
        feature3: "Includes Merry Miniatures, Kiddie Kar Classics, and more"
    }
}

