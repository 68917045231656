import React from 'react';
import styles from './Profit.module.css';
import FormControl from '@mui/material/FormControl';
import { calculateProfit } from '../../../../../lib/collectionOperations';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const Profit = (props) => {
    const [profit, setProfit] = React.useState(props.value);

    // Format value to always display two decimal points
    const formattedValue = parseFloat(props.value).toFixed(2);

    return (
        <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
                className={styles.CurrencyTextField}
                id={props.id}
                label={props.label}
                value={formattedValue}
                helperText="Price Sold - Price Bought"
                variant="outlined"
                textAlign="left"
                minimumValue={props.minVal}
                outputFormat="number"
                placeholder="0.00"
                readOnly={true}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: { min: 0, max: 10000, step: 0.01 }, // Set min, max, and step values
                    inputMode: 'numeric', // Set input mode to numeric for better mobile support
                    placeholder: '0.00',
                }}
            />
        </FormControl>
    );
};

export default Profit;