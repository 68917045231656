import React from 'react';
import TextField from '@mui/material/TextField';
import styles from './MyComments.module.css';

const MyCommentsInput = (props) => {

    const handleChange = (event) => {
        props.changed(event.target.value.replace(/\n/g, "<br>"));
    };

    const parseLines = (value) => {
        if (value) {
            return value.replace(/<br\s*[\/]?>/gi, "\n");
        }
        return "";
    }

    return (
        <TextField
            multiline
            rows={4}
            label={props.label}
            onChange={handleChange}
            className={styles.MyComments}
            defaultValue={parseLines(props.value)}
            InputLabelProps={{ shrink: true }} />
    )
};

export default React.memo(MyCommentsInput);