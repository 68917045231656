import { connectStateResults, Pagination } from 'react-instantsearch-dom';
import SantaChimney from '../../../img/santachimney.png';
import styles from './CustomStateResults.module.css';
import Grid from '@mui/material/Grid';

const StateResults = ({ searchResults }) => {
    const hasResults = searchResults && searchResults.nbHits !== 0;
    const nbHits = searchResults && searchResults.nbHits;
    const time = searchResults && searchResults.processingTimeMS;

    return (
        <div style={{ marginLeft: '50px' }}>
            <div hidden={!hasResults}>
                <div>
                    {nbHits} results found in {time} ms.
                </div>
                <div className={styles.Pagination}>
                    <Pagination />
                </div>
            </div>
            <div className={styles.NoResultsWrapper} hidden={hasResults}>
                <center>
                    <img className={styles.NoResultsImage} src={SantaChimney} />

                    <h6><b>No results found</b></h6>
                    Try searching by:
                    <br></br>
                    <br></br>
                </center>
                <div className={styles.NoResultsText}>
                    <Grid justifyContent='space-between' container>
                        <Grid item>
                            <ul>
                                <li>Name</li>
                                <li>Box #</li>
                                <li>Keyword</li>
                            </ul>
                        </Grid>
                        <Grid item>
                            <ul>
                                <li>Year</li>
                                <li>Series</li>
                                <li>Artist</li>
                            </ul>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div >
    );
};

const CustomStateResults = connectStateResults(StateResults);

export default CustomStateResults;