/* Check to see if string (ex: user input) has undesirable white spaces */
export const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}

export const getOrDefault = (storedValue) => {
    if (storedValue != null) {
        return storedValue
    }
    if (storedValue == "undefined") {
        return ""
    }
    return ""
}