import React from 'react';
import { Grid, Typography } from '@mui/material'

const ValuablesRow = (props) => {

    return (
        <Grid container justifyContent='space-between' spacing={4}>
            <Grid item align-items="flex-start" >
                <Typography
                    color="textPrimary"
                    variant="body1">
                    <b><span title={props.name}>{props.index + 1}. {props.name.substring(0, 30)}{props.name.length >= 30 && '...'}<br /></span></b>
                    &nbsp;&nbsp;&nbsp;&nbsp;{props.boxnum}
                </Typography>
            </Grid>
            <Grid item align-items="flex-end" >
                <Typography
                    color="textSecondary"
                    variant="body1">
                    {props.value}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default ValuablesRow;