import React, { forwardRef, useState } from 'react';
import Firebase from '../../components/Firebase/firebase';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import MaterialTable from "@material-table/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import StarIcon from "@material-ui/icons/Star";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import Modal from 'react-bootstrap/Modal';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';

import SaveIcon from '@mui/icons-material/Save';
import TransferIcon from '@mui/icons-material/AssignmentInd';
import Snackbar from '@mui/material/Snackbar';


import Publish from '@mui/icons-material/Publish';

import { withFirebase } from '../../components/Firebase';


import style from './SettingsGroupTable.module.css';
import { fabClasses } from '@mui/material';
import { Collections } from '@mui/icons-material';




const tableIcons = {
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
    Star: forwardRef((props, ref) => <StarIcon style={{ fontSize: 14 }} {...props} ref={ref} />),
    TransferArrow: forwardRef((props, ref) => <TransferIcon style={{ fontSize: 14 }} {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <EditIcon style={{ fontSize: 14 }} {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteIcon style={{ fontSize: 14 }} fontSize="small" {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
};


const SettingsGroupTable = (props, { firebase }) => {

    var firebase = new Firebase()

    let changeType = ""
    let capitalChangeType = ""

    if (props.location === 'collection') {
        changeType = 'collection';
        capitalChangeType = 'Collection';
    } else if (props.location === 'collector') {
        changeType = 'collector';
        capitalChangeType = 'Collector';
    } else if (props.location === 'purchaseLocation') {
        changeType = 'purchase location';
        capitalChangeType = 'Purchase location';
    } else if (props.location === 'storageLocation') {
        changeType = 'storage location';
        capitalChangeType = 'Storage location';
    } else if (props.location === 'displayLocation') {
        changeType = 'display location';
        capitalChangeType = 'Display location';
    } else {
        // Default value if 'location' doesn't match any of the expected values
        changeType = 'item';
        capitalChangeType = 'Item'
    }

    const [showUpdateModal, setShowUpdateModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showAssignModal, setShowAssignModal] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarText, setSnackbarText] = React.useState(`${capitalChangeType} updated.`);


    var user = firebase.auth.currentUser.uid;
    let docRef = firebase.db.collection("users").doc(user).collection("userinfo").doc("locations");
    let listType = props.location
    const [defaultSelection, setDefaultSelection] = useState(localStorage.getItem(props.location + "Default"))

    // Grab arrays and transform them into Material Table-friendly arrays of objects
    let storedArr = localStorage.getItem(props.dataType);
    storedArr = JSON.parse(localStorage[props.dataType]);
    storedArr = storedArr.filter(entry => entry.trim() != '');
    let mappedArr = storedArr.map(item => {
        return {
            name: item
        }
    })

    if (mappedArr.length === 1 && mappedArr[0].name === "") {
        mappedArr = []
    }



    const [data, setData] = useState(mappedArr);
    const [newData, setNewData] = useState([]);
    const [oldData, setOldData] = useState([]);
    const [toAssign, setToAssign] = useState("")

    const storedCollectors = JSON.parse(localStorage.getItem("collectors"));
    const [collectors, setCollectors] = useState(storedCollectors ? storedCollectors : false)

    const firstValue = Array.isArray(storedCollectors) && storedCollectors.length > 0 ? storedCollectors[0] : "";
    const [selected, setSelect] = useState(firstValue);

    const index = data.findIndex(item => item.name == defaultSelection);
    const [selectedRow, setSelectedRow] = useState(index);

    const handleConfirmUpdate = async () => {

        console.log("Clicked confirm");

        // Callable function to push update across collection
        const itemSharedFunction = firebase.functions.httpsCallable('updateLocations');
        const newValue = newData.name;
        const oldValue = oldData.name;

        console.log("old", oldValue)
        console.log("new", newValue)

        // Clone the data array
        const dataUpdate = [...data];

        // Find the index of the updated row
        const index = oldData.tableData.id;

        // Check if the new data already exists in the table
        const existingIndex = data.findIndex(item => item.name === newValue);

        // If a match was found purge that row
        if (existingIndex) {
            // If it exists, remove the new entry
            dataUpdate.splice(existingIndex, 1);
        }

        // Update local state with the new data
        dataUpdate[index] = newData;
        setData([...dataUpdate]);

        // Update the array in local storage
        storedArr = storedArr.filter(item => item !== oldValue); // Remove old item

        storedArr[oldData.tableData.id] = newValue

        localStorage.setItem(props.dataType, JSON.stringify(storedArr));

        // Update the array in Firestore
        await docRef.update({
            [props.location]: storedArr
        });


        const result = itemSharedFunction({ userID: user, field: listType, newValue: newValue, oldValue: oldValue });
        console.log(result)

        setShowUpdateModal(false)
        setSnackbarText(`${capitalChangeType} updated across collection.`)
        sessionStorage.setItem("shouldRefreshCollection", "true");
        setSnackbarOpen(true)

    }

    const handleCancelUpdate = () => {
        // Clone the data array
        const dataUpdate = [...data];

        // Find the index of the updated row
        const index = oldData.tableData.id;

        // Update local state with the new data
        dataUpdate[index] = oldData;
        setData([...dataUpdate]);
        setShowUpdateModal(false)
    }

    const handleUpdateClose = () => {
        setShowUpdateModal(false)
    }

    const handleConfirmDelete = () => {
        const itemSharedFunction = firebase.functions.httpsCallable('deleteAcrossCollection');
        const valueToDelete = oldData.name
        const field = props.location
        const result = itemSharedFunction({ user, valueToDelete, field });
        console.log("delete", result)
        setShowDeleteModal(false)
        setSnackbarText(`${capitalChangeType} deleted across collection.`)
        setSnackbarOpen(true)
    }

    const handleCancelDelete = () => {
        // Clone the data array
        setSnackbarText(`${capitalChangeType} row deleted.`)
        setSnackbarOpen(true)
        setShowDeleteModal(false)
    }

    const handleConfirmAssignment = () => {
        // Clone the data array
        const itemSharedFunction = firebase.functions.httpsCallable('assignCollector');
        const collection = toAssign
        const collector = selected
        let text = `Collection ${collection} assigned to ${collector}.`

        console.log("params", user, collection, collector)

        if (collector === "No collector" || collector === "None" || collector === undefined || collector === null || collector === "") {
            console.log("path", collector)
            console.log("Empty collector path")
            text = `Collection ${collection} no longer assigned to a collector.`;
            const result = itemSharedFunction({ user, collection, collector: '' });
            setShowAssignModal(false)
            setSnackbarText(text)
            setSnackbarOpen(true)
        } else {
            console.log("Assigned new ollector path")
            const result = itemSharedFunction({ user, collection, collector });
            setShowAssignModal(false)
            setSnackbarText(text)
            setSnackbarOpen(true)
        }
    }

    const handleAssignModalClose = () => {
        // Clone the data array
        setShowAssignModal(false)
    }

    const handleTransfer = (event, rowData) => {
        setToAssign(rowData.name)
        setShowAssignModal(true)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };



    const handleDefaultSelection = (event, rowData) => {
        if (rowData.name == defaultSelection) {
            setSelectedRow(null)
            setDefaultSelection("")
            docRef.update({
                [props.location + 'Default']: ""
            });
            localStorage.setItem([props.location] + "Default", "");
            setSnackbarText(`Removed default ${changeType}.`)
            return setSnackbarOpen(true)
        }
        setSelectedRow(rowData.tableData.id)
        setSnackbarText(`Set default ${changeType}.`)
        setDefaultSelection(rowData.name)
        docRef.update({
            [props.location + 'Default']: rowData.name
        });
        localStorage.setItem([props.location] + "Default", rowData.name);
        return setSnackbarOpen(true)
    }

    const handleCollectorChange = (event) => {
        console.log(event.target.value)
        setSelect(event.target.value);
    };

    const tableActions = [
        {
            icon: tableIcons.Star,
            tooltip: 'Set Default',
            onClick: (event, rowData) => handleDefaultSelection(event, rowData)

        }]

    const collectorTableActions = [
        {
            icon: tableIcons.Star,
            tooltip: 'Set Default',
            onClick: (event, rowData) => handleDefaultSelection(event, rowData)

        },
        {
            icon: tableIcons.TransferArrow,
            tooltip: 'Assign to Collector',
            onClick: (event, rowData) => handleTransfer(event, rowData)
        }]

    return (

        <div style={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item sm={3}>
                    <h5 className={style.Header}>{props.header}</h5>
                    <p className={style.Description}>
                        {props.description}
                    </p>
                    <p className={style.Description2}>{props.description2}</p>
                    <p className={style.Description2}>{props.description3}</p>
                </Grid>
                <Grid item sm={9}>
                    <div style={{ maxWidth: "100%" }}>
                        <MaterialTable
                            actions={listType === "collection" ? collectorTableActions : tableActions}
                            columns={
                                [
                                    { title: props.columnTitle, field: "name" },

                                ]}
                            data={data}
                            title={props.tableTitle}
                            options={{
                                search: false,
                                paging: false,
                                rowStyle: rowData => ({
                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#DAA520' : '#FFF'
                                })
                            }}
                            icons={tableIcons}
                            editable={{
                                onRowAdd: newData => new Promise(async (resolve, reject) => {

                                    if (newData.name == "" || newData.name == undefined) {
                                        // Item already exists, show alert or handle as needed
                                        resolve(); // Reject the promise since the item already exists
                                    } else {
                                        try {


                                            // Check if the item already exists in the table
                                            const itemExists = data.some(item => item.name === newData.name);

                                            if (itemExists) {
                                                // Item already exists, show alert or handle as needed
                                                alert(`This ${changeType} already exists!`);
                                                reject(); // Reject the promise since the item already exists
                                            } else {
                                                // Item doesn't exist, proceed with Firestore update
                                                await docRef.update({
                                                    [props.location]: firebase.fieldValue.arrayUnion(newData.name)
                                                });

                                                // Update local state
                                                setData([...data, newData]);
                                                storedArr.push(newData.name);
                                                localStorage.setItem(props.dataType, JSON.stringify(storedArr));
                                                setSnackbarText(`New ${changeType} added.`)
                                                setSnackbarOpen(true)
                                                resolve(); // Resolve the promise since the update is successful
                                            }
                                        } catch (error) {
                                            console.error("Error updating Firestore:", error);
                                            reject();
                                        }
                                    }
                                }),
                                onRowUpdate: (newData, oldData) => new Promise(async (resolve, reject) => {

                                    /* If there is no change, don't do anything */
                                    if (oldData.name == newData.name) {
                                        resolve();
                                    } else {

                                        try {
                                            // Clone the data array
                                            const dataUpdate = [...data];

                                            // Find the index of the updated row
                                            const index = oldData.tableData.id;

                                            // Update local state with the new data
                                            dataUpdate[index] = newData;
                                            setData([...dataUpdate]);
                                            setShowUpdateModal(true);
                                            setNewData(newData)
                                            setOldData(oldData)
                                            resolve();
                                        } catch (error) {
                                            console.error("Error updating Firestore:", error);
                                            reject();
                                        }
                                    }
                                }),
                                onRowDelete: oldData => new Promise(async (resolve, reject) => {
                                    try {

                                        setOldData(oldData)

                                        // Clone the data array
                                        const dataDelete = [...data];

                                        // Find the index of the deleted row
                                        const index = oldData.tableData.id;

                                        // Remove the row from local state
                                        dataDelete.splice(index, 1);
                                        setData([...dataDelete]);

                                        // Handle the case for default selection
                                        if (defaultSelection === oldData.name) {
                                            setSelectedRow(null)
                                            setDefaultSelection("");
                                            await docRef.update({
                                                [props.location + 'Default']: ""
                                            });
                                        }

                                        // Update local storage
                                        localStorage.setItem([props.location] + "Default", "");

                                        // Remove the old item from the array
                                        storedArr = storedArr.filter(item => item !== oldData.name);

                                        // Handle the case when the array is empty
                                        if (storedArr.length === 0) {
                                            storedArr.push("");
                                        }

                                        // Log the updated array
                                        console.log(storedArr);

                                        // Update local storage
                                        localStorage.setItem(props.dataType, JSON.stringify(storedArr));

                                        // Update the array in Firestore
                                        await docRef.update({
                                            [props.location]: storedArr
                                        });

                                        setShowDeleteModal(true);


                                        resolve();
                                    } catch (error) {
                                        console.error("Error updating Firestore:", error);
                                        reject();
                                    }
                                }),
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
                open={showUpdateModal}
            >
                <Modal
                    style={{ zIndex: '9999' }}
                    show={showUpdateModal}
                    onHide={handleUpdateClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Update {changeType}?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Are you sure you want to update this <b>{changeType}</b>?
                            <br /><br />
                            <span><Chip sx={{ marginRight: '10px' }} label={oldData.name} size="large" /></span>
                            <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}> → </span>
                            <span><Chip sx={{ marginRight: '10px' }} label={newData.name} size="large" /></span>
                            <br /><br />
                            <b>This update will apply across your ENTIRE collection.</b>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>

                        <div className={style.ButtonDiv}>
                            <Button variant="outlined" className={style.FooterButtonAlt} onClick={handleCancelUpdate}>Cancel</Button>
                        </div>
                        <div className={style.ButtonDiv}>
                            <Button onClick={handleConfirmUpdate} className={style.FooterButton} variant="contained" color="primary" >Yes, Update</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
                open={showDeleteModal}
            >
                <Modal
                    style={{ zIndex: '9999' }}
                    show={showDeleteModal}
                    onHide={handleUpdateClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Delete this {changeType} for all items?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Do you want to delete this <b>{changeType}</b> across your entire collection?
                        </p>
                        <p>
                            <span><Chip sx={{ marginRight: '10px' }} label={oldData.name} size="large" /></span>
                        </p>
                        <p>
                            Otherwise, it will be removed from your list of {changeType}s, but the items currently assigned to it will still have this value set for the {changeType} field.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={style.ButtonDiv}>
                            <Button variant="outlined" className={style.FooterButtonAlt} onClick={handleCancelDelete}>No</Button>
                        </div>
                        <div className={style.ButtonDiv}>
                            <Button onClick={handleConfirmDelete} className={style.FooterButton} variant="contained" color="error" >Yes, delete across collection</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
                open={showAssignModal}
            >
                <Modal
                    style={{ zIndex: '9999' }}
                    show={showAssignModal}
                    onHide={handleAssignModalClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Assign Collector
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Which collector do you want to assign your collection to?
                        </p>
                        <p>
                            <span><b>Collection:</b> <Chip sx={{ marginRight: '10px' }} label={toAssign} size="large" /></span>
                        </p>
                        <p>
                            <b>Assign To:</b>
                        </p>
                        <FormControl fullWidth>
                            <NativeSelect
                                value={selected}
                                onChange={handleCollectorChange}
                                inputProps={{
                                    name: 'collector',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value={""}>No collector</option>
                                {collectors.map((element) => {
                                    if (element === "") {
                                        return null
                                    }
                                    return <option value={element}>{element}</option>
                                })}

                            </NativeSelect>
                        </FormControl>
                        <p>
                            <br></br><b>Note:</b> ALL items associated with this collection will be assigned to the new collector.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={style.ButtonDiv}>
                            <Button variant="outlined" className={style.FooterButtonAlt} onClick={handleAssignModalClose}>Cancel</Button>
                        </div>
                        <div className={style.ButtonDiv}>
                            <Button onClick={handleConfirmAssignment} className={style.FooterButton} variant="contained" color="primary" >Confirm</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Backdrop>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                message={snackbarText}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />

        </div >
    )
}

export default SettingsGroupTable;