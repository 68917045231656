import React from 'react';
import styles from './Image.module.css';

const Image = (props) => {

    return (
        <img onClick={props.clicked} className={styles.Image} src={props.img} />
    )
};

export default Image;