import React from 'react';

import styles from './Toolbar.module.css';
import Logo from '../../Logos/Logo/Logo';
import Navigation from '../../Navigation/Navigation';
import DrawerToggle from '../../Navigation/SideDrawer/DrawerToggle/DrawerToggle';

const toolbar = (props) => (
    <header className={styles.Toolbar}>
        <DrawerToggle clicked={props.drawerToggleClicked} />
        <div className={styles.Logo}>
            <Logo />
        </div>
        <nav className={styles.DesktopOnly}>
            <Navigation />
        </nav>
    </header>
);

export default toolbar;