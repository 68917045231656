import { breadcrumbsClasses } from "@mui/material";

export const calculateValue = (condition, mibPrice, signed) => {

    let value = 0;
    switch (condition) {
        case "Mint In Box":
            value = mibPrice;
            break;
        case "No Price Tag":
            value = (mibPrice * 0.95)
            break;
        case "Slightly Damaged Box":
            value = (mibPrice * 0.90)
            break;
        case "Damaged Box":
            value = (mibPrice * 0.85)
            break;
        case "No Box":
            value = (mibPrice * 0.80)
            break;
        case "Never Removed From Box":
            value = (mibPrice * 1.05)
            break;
    }
    if (signed) {
        value = value + (0.25 * mibPrice);
    }

    if (value === 0 || undefined || !value) {
        return 0.00
    }

    return value.toFixed(2);
}

export const calculateConditionLabel = (condition) => {

    switch (condition) {
        case "Mint In Box":
            return 'MIB';
        case "No Price Tag":
            return 'NPT'
        case "Slightly Damaged Box":
            return 'SDB'
        case "Damaged Box":
            return 'DB'
        case "No Box":
            return 'NB'
        case "Never Removed From Box":
            return 'NRFB'
    }
}

export const renderStatus = (status) => {
    switch (status) {
        case "Own":
            return 10
        case "Wanted":
            return 20
        case "For Sale":
            return 30
        case "Sold":
            return 40
        case "Trade":
            return 50
        case "In Layaway":
            return 60
        case "On Order":
            return 70
        case "Missing":
            return 80
    }
}