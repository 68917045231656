import React from 'react';

import './NavigationItem.css';

export const navigationItemExternalLink = (props) => (
    <li className="unauth-menu" >
        <a href={props.url}>{props.text}</a>
    </li >
);

export default navigationItemExternalLink;
