import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import DrawerList from '../../components/Drawers/DrawerList';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Modal from 'react-bootstrap/Modal';
import Tooltip from '@mui/material/Tooltip';
import SettingsGroup from '../../components/SettingsGroup/SettingsGroup';
import SettingsGroupTable from '../../components/SettingsGroupTable/SettingsGroupTable';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Link } from 'react-router-dom';
import SmallLogo from '../../components/Logos/SmallLogo/SmallLogo';
import HelpIcon from '@mui/icons-material/Help';


import styles from './Settings.module.css';
import AppMarkdown from './CHANGELOG.md';
import ReactMarkdown from 'react-markdown';
import Firebase, { withFirebase } from '../../components/Firebase';
import { AuthUserContext, withAuthorization } from '../../components/Session';
import { SocialIcon } from 'react-social-icons';

import { settingsText } from '../../constants/properties';
import { getOrDefault } from '../../lib/util';

const Settings = ({ firebase }) => {

    document.title = 'YuleLog Settings';





    const [open, setOpen] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [deleteEnabled, setDeleteEnabled] = React.useState(false);
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const [isChangelogOpen, setChangelogOpen] = React.useState(false);
    const [showUpdateModal, setShowUpdateModal] = React.useState(false);
    const [markdown, setMarkdown] = React.useState("");
    const handleClose = () => {
        setOpen(false);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setOpen(false);
    };
    const handleChangelogClose = () => {
        setChangelogOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const handleToggleDelete = () => {
        setDeleteModal(!deleteModal);
        setDeleteEnabled(false)
    };

    const closeDeleteModal = () => {
        setDeleteModal(false);
        setDeleteEnabled(false)
    };


    const handleUpdateClose = () => {
        setShowUpdateModal(false)
    }

    const handleDeleteChange = (event) => {
        if (event.target.value === "DELETE") {
            return setDeleteEnabled(true)
        }
    }

    const handleConfirmDelete = async () => {
        try {
            // Update user status in Firestore
            await firebase.db.collection("users").doc(firebase.auth.currentUser.uid).collection("userinfo").doc("customer").update({
                status: "deleted"
            });

            // Display success message and perform logout
            alert("Your account has been deleted. You will now be logged out.");
            setDeleteModal(false); // Toggle delete modal state
            await firebase.doSignOut(); // Logout user
            window.location.href = '/'; // Redirect to homepage
        } catch (error) {
            console.error("Error deleting account:", error);
            // Display error message
            alert("Something went wrong. Please contact sean@yulelog.com to delete your account.");
            setDeleteModal(false); // Toggle delete modal state
        }
    };

    useEffect(() => {


        firebase.db.collection("users").doc(firebase.auth.currentUser.uid).collection("userinfo").doc("locations")
            .get()
            .then(doc => {
                // Grab Collection/Collector/Locations Data
                localStorage.setItem("collectorDefault", getOrDefault(doc.data().collectorDefault));
                localStorage.setItem("collectors", JSON.stringify(doc.data().collector));
                localStorage.setItem("collectionDefault", getOrDefault(doc.data().collectionDefault));
                localStorage.setItem("collections", JSON.stringify(doc.data().collection));
                localStorage.setItem("purchaseLocations", JSON.stringify(doc.data().purchaseLocation));
                localStorage.setItem("purchaseLocationDefault", getOrDefault(doc.data().purchaseLocationDefault));
                localStorage.setItem("displayLocations", JSON.stringify(doc.data().displayLocation));
                localStorage.setItem("displayLocationDefault", getOrDefault(doc.data().displayLocationDefault));
                localStorage.setItem("storageLocations", JSON.stringify(doc.data().storageLocation));
                localStorage.setItem("storageLocationDefault", getOrDefault(doc.data().storageLocationDefault));
            })


        fetch(AppMarkdown).then(res => res.text()).then(text => setMarkdown(text));


        // Function to open the modal if the URL matches the desired deep link
        const openDeleteModal = () => {
            const url = window.location.href;
            const deepLink = 'yulelog.com/settings/delete-account';
            if (url.includes(deepLink)) {
                setDeleteModal(true)
            }
        };

        openDeleteModal();

    }, [])
    let userID = firebase.auth.currentUser.uid;
    let email = firebase.auth.currentUser.email;

    let customerRef = firebase.db.collection("users").doc(userID).collection("userinfo").doc("customer");
    customerRef.get().then(function (doc) {
        if (doc.exists) {
            localStorage.setItem("stripeCustomerID", doc.data().customer)
            localStorage.setItem("platform", doc.data().platform)
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }).catch(function (error) {
        console.log("Error getting document:", error);
    });

    const stripeSub = () => {
        let stripeCustomerID = localStorage.getItem("stripeCustomerID");
        let platform = localStorage.getItem("platform");
        if (platform === "iOS") {
            handleToggle();
            return setDialogOpen(true);
        }
        if (stripeCustomerID) {
            let callStripe = firebase.functions.httpsCallable('callStripe');
            callStripe({ text: stripeCustomerID }).then(function (result) {
                // Read result of the Cloud Function.
                var result = result.data.text;
                window.location.replace(result);
            });
        } else {
            handleToggle();
            return alert("There was a problem updating your subscription. Please contact us at sean@yulelog.com.")
        }
    }
    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <div style={{ display: 'flex', marginTop: '20px', padding: '20px' }}>
                    <CssBaseline />
                    <AppBar position="fixed" sx={{ bgcolor: '#7d54b4', zIndex: (theme) => theme.zIndex.drawer + 2 }}>
                        <Toolbar sx={{ bgcolor: '#7d54b4' }}>
                            <SmallLogo className={styles.Logo} />
                            <div className={styles.Title}>
                                <Typography sx={{ color: 'white' }} variant="h6" noWrap >
                                    Settings
                                </Typography>
                            </div>
                            <section style={{
                                marginLeft: 'auto',
                                marginRight: -12
                            }}>
                                <div className={styles.BarButtonWrapper}>
                                    <Tooltip arrow title="Go to help page">
                                        <IconButton>
                                            <a href="https://help.yulelog.com/hallmark/v/yulelog-for-web/" target="_blank">
                                                <HelpIcon className={styles.IconButton} />
                                            </a>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </section>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        sx={{
                            width: 240,
                            '& .MuiDrawer-paper': {
                                width: 240
                            },
                        }}
                        variant="permanent"
                    >
                        <Toolbar />
                        <DrawerList pageSelected={3} />
                        <Divider />
                    </Drawer>
                    <main style={{
                        flexGrow: 1,
                        padding: (theme) => theme.spacing(3),
                    }}>
                        <Toolbar />
                        <SettingsGroup
                            isTable
                            header={settingsText.general.header}
                            description={settingsText.general.description}
                        >
                            <TextField
                                fullWidth
                                id="outlined-margin-dense"
                                label="Email"
                                InputProps={{
                                    readOnly: true,
                                }}
                                defaultValue={email}
                                margin="dense"
                                variant="outlined"
                                helperText="The email address you used to register your account"
                            />
                            <br /><br />
                            <TextField
                                fullWidth
                                disabled
                                id="outlined-disabled"
                                label="User ID"
                                defaultValue="User ID"
                                value={userID}
                                margin="dense"
                                variant="outlined"
                                helperText="This user ID is for record-keeping purposes only."
                            />
                        </SettingsGroup>
                        <Divider style={{ margin: '25px 0' }} />
                        <SettingsGroup
                            header={settingsText.billing.header}
                            description={settingsText.billing.description}                            >
                            <div className={styles.ManageSub}>
                                <Button variant={'contained'} onClick={() => { handleToggle(); stripeSub() }} >
                                    Manage Subscription
                                </Button>
                                <Backdrop sx={{
                                    zIndex: (theme) => theme.zIndex.drawer + 1,
                                    color: '#fff'
                                }} open={open} onClick={handleClose}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <br /><br />
                                <FormHelperText>
                                    Clicking this button will take you to a page where you can manage your subscription, see when it is scheduled to renew and update your billing preferences.
                                </FormHelperText>
                            </div>
                            <Dialog
                                open={isDialogOpen}
                                onClose={handleDialogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"You are subscribed to YuleLog through Apple."}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        In order to update your subscription, you must do so through the App Store on your iOS device (your iPhone or iPad).
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleDialogClose} autoFocus>
                                        OK
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </SettingsGroup>
                        <Divider style={{ margin: '25px 0' }} />
                        <SettingsGroupTable
                            dataType="collectors"
                            location="collector"
                            header={settingsText.collectors.header}
                            description={settingsText.collectors.description}
                            description2={settingsText.collectors.highlighted}
                            description3={settingsText.collectors.setDefault}
                            columnTitle="Collector Name"
                            tableTitle="Collectors" />
                        <Divider style={{ margin: '25px 0' }} />
                        <SettingsGroupTable
                            dataType="collections"
                            location="collection"
                            header={settingsText.collections.header}
                            description={settingsText.collections.description}
                            description2={settingsText.collectors.highlighted}
                            description3={settingsText.collectors.setDefault}
                            columnTitle="Collection Name"
                            tableTitle="Collections" />
                        <Divider style={{ margin: '25px 0' }} />
                        <SettingsGroupTable
                            dataType="displayLocations"
                            location="displayLocation"
                            header={settingsText.displayLocations.header}
                            description={settingsText.displayLocations.description}
                            description2={settingsText.collectors.highlighted}
                            description3={settingsText.collectors.setDefault}
                            columnTitle="Display Location"
                            tableTitle="Display Locations" />
                        <Divider style={{ margin: '25px 0' }} />
                        <SettingsGroupTable
                            dataType="purchaseLocations"
                            location="purchaseLocation"
                            header={settingsText.purchaseLocations.header}
                            description={settingsText.purchaseLocations.description}
                            description2={settingsText.collectors.highlighted}
                            description3={settingsText.collectors.setDefault}
                            columnTitle="Purchase Location"
                            tableTitle="Purchase Locations" />
                        <Divider style={{ margin: '25px 0' }} />
                        <SettingsGroupTable
                            dataType="storageLocations"
                            location="storageLocation"
                            header={settingsText.storageLocations.header}
                            description={settingsText.storageLocations.description}
                            description2={settingsText.collectors.highlighted}
                            description3={settingsText.collectors.setDefault}
                            columnTitle="Storage Location"
                            tableTitle="Storage Locations" />
                        <Divider style={{ margin: '25px 0' }} />
                        <SettingsGroup
                            header={settingsText.general.deleteHeader}
                            description={settingsText.general.deleteDescription}                            >
                            <div className={styles.ManageSub}>
                                <Button color="error" variant={'contained'} onClick={() => { handleToggleDelete() }} >
                                    Delete Account
                                </Button>
                                <br></br><br></br>
                                <FormHelperText>
                                    {settingsText.general.deleteWarning1}
                                </FormHelperText>
                                <FormHelperText>
                                    {settingsText.general.deleteWarning2}
                                </FormHelperText>
                            </div>
                            <Dialog
                                open={deleteModal}
                                onClose={handleToggleDelete}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {settingsText.general.deleteHeader}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {settingsText.general.deleteBody1}
                                    </DialogContentText>
                                    <br></br>
                                    <DialogContentText id="alert-dialog-description">
                                        <b>{settingsText.general.deleteBody2}</b>
                                    </DialogContentText>
                                    <br></br>
                                    <TextField
                                        fullWidth
                                        onChange={handleDeleteChange}
                                        id="outlined-margin-dense"
                                        margin="dense"
                                        variant="filled"
                                        helperText="Type DELETE to confirm that you want to delete your account."
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={closeDeleteModal}>
                                        Cancel
                                    </Button>
                                    <Button disabled={!deleteEnabled} color='error' variant={'contained'} onClick={handleConfirmDelete}>
                                        Delete My Account
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </SettingsGroup>
                        <div className={styles.Version}>
                            <p><b>YuleLog Version: </b><Link to='#' onClick={() => setChangelogOpen(true)}>{process.env.REACT_APP_VERSION}</Link></p>
                            <Dialog
                                open={isChangelogOpen}
                                onClose={handleChangelogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth={true}
                                maxWidth="lg"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Changelog"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <div className={styles.Changelog}>
                                            <ReactMarkdown children={markdown} />
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>

                            <SocialIcon fgColor="#fff" url="https://www.facebook.com/YuleLogSoftware" />&nbsp;&nbsp;&nbsp;
                            <SocialIcon fgColor="#fff" url="https://www.youtube.com/channel/UCAzKeCW6y6uIx-f0Io5RqsA" />&nbsp;&nbsp;&nbsp;
                            <SocialIcon fgColor="#fff" url="https://www.tiktok.com/@yulelogsoftware" />&nbsp;&nbsp;&nbsp;
                            <SocialIcon fgColor="#fff" url="https://www.instagram.com/yulelogsoftware/" />
                        </div>
                    </main>
                </div>
            )
            }
        </AuthUserContext.Consumer >
    )
}


const condition = authUser => !!authUser;

export default withFirebase(withAuthorization(condition)(Settings));