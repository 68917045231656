import React from 'react';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import ForumIcon from '@mui/icons-material/Forum';
import BookIcon from '@mui/icons-material/Book';
import styles from './DrawerList.module.css';

import SignOut from '../SignOut/SignOut';

import * as ROUTES from '../../constants/routes';

class DrawerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selected: null };
    }

    updateSelected(selectedIndex) {
        this.setState({ selected: selectedIndex });
    }

    render() {
        const { selected } = this.state;
        const menuFontSize = '18px';

        return (
            <List sx={{
                // selected and (selected + hover) states
                '&& .Mui-selected, && .Mui-selected:hover': {
                    bgcolor: '#F5F5F5',
                }
            }}>
                <Link className={styles.Link} to={ROUTES.COLLECTION}>
                    <ListItem button onClick={() => this.updateSelected(0)} selected={this.props.pageSelected === 0}>
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Collection" primaryTypographyProps={{ fontSize: menuFontSize }} />
                    </ListItem>
                </Link>
                <Link className={styles.Link} to={ROUTES.PRICEGUIDE}>
                    <ListItem button onClick={() => this.updateSelected(1)} selected={this.props.pageSelected === 1}>
                        <ListItemIcon>
                            <BookIcon />
                        </ListItemIcon>
                        <ListItemText primary="Price Guide" primaryTypographyProps={{ fontSize: menuFontSize }} />
                    </ListItem>
                </Link>
                <Link className={styles.Link} to={ROUTES.REPORTS}>
                    <ListItem button onClick={() => this.updateSelected(2)} selected={this.props.pageSelected === 2}>
                        <ListItemIcon>
                            <AssessmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Reports" primaryTypographyProps={{ fontSize: menuFontSize }} />
                    </ListItem>
                </Link>
                <Link className={styles.Link}>
                    <ListItem button onClick={() => window.open(ROUTES.FORUMS)}>
                        <ListItemIcon>
                            <ForumIcon />
                        </ListItemIcon>
                        <ListItemText primary="Forums" primaryTypographyProps={{ fontSize: menuFontSize }} />
                    </ListItem>
                </Link>
                <Link className={styles.Link} to={ROUTES.SETTINGS}>
                    <ListItem button onClick={() => this.updateSelected(3)} selected={this.props.pageSelected === 3}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" primaryTypographyProps={{ fontSize: menuFontSize }} />
                    </ListItem>
                </Link>
                <SignOut />
            </List >
        );
    }
}

export default DrawerList;