import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './CustomImageModal.module.css';
import 'react-dropzone-uploader/dist/styles.css';
import Button from '@mui/material/Button';
import Firebase from '../../../../Firebase/firebase';
import Stack from '@mui/material/Stack';
import ImageUploader from '../ImageUploader/ImageUploader';
import algoliasearch from 'algoliasearch';
import { properties, SHORT_FEATURES } from '../../../../../constants/properties';

import d56 from '../../../../../img/56ylicon.png';
import hallmark from '../../../../../img/hallmarkylicon.png';
import missing from '../../../../../img/no-custom-image.png';
import defaultImage from '../../../../../img/missing-image-scaled.png';
import missingYl from '../../../../../img/no-yulelog-image.png';


const CustomImageModal = (props) => {

    const firebase = new Firebase();
    var user = firebase.auth.currentUser.uid;

    const handleSubmit = (file) => {

        var itemRef = firebase.db.collection("users").doc(user).collection("maincollection").doc(props.objectId);
        var storageRef = firebase.storage.ref();
        const ALGOLIA_ID = 'G89C4NZVH8';
        const ALGOLIA_ADMIN_KEY = localStorage.getItem("search");
        const collectionClient = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);

        var testImageRef = storageRef.child("users" + "/" + user + "/" + props.objectId + '.jpg');
        var uploadTask = testImageRef.put(file);

        uploadTask.on('state_changed', function (snapshot) {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case 'paused':
                    break;
                case 'running':
                    break;
            }
        }, function (error) {
            // Handle unsuccessful uploads
        }, function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                itemRef.update({

                    customPhoto: true,
                    imageURL: downloadURL

                })
                    .then(function () {
                        setImgSrc(downloadURL);
                        setCustomImage(true);
                        const collectionIndex = collectionClient.initIndex("userItems");
                        collectionIndex.partialUpdateObject({

                            objectID: props.objectId,
                            customPhoto: true,
                            imageURL: downloadURL

                        }, function (err, content) {
                            if (err) throw err;
                        }).then(() => {
                            props.setImage(downloadURL, true);
                        }
                        );

                    });
            });

        });

    }

    const useYulelogPhoto = () => {

        var storageRef = firebase.storage.ref();

        // Create a reference to the file to delete
        var imageRef = storageRef.child("users" + "/" + user + "/" + props.objectId + '.jpg');

        // Delete the file
        imageRef.delete().then(() => {

            setImgSrc(missing);
            setCustomImage(false);
            grabYulelogPhotoInformation(props.yulelogID, props.objectId, props.brand);
            props.close();
            // TODO: Somehow refresh collection search from here


        }).catch(function (error) {
            alert("There was an error deleting the image");
            setImgSrc(missing);
            setCustomImage(false);
            grabYulelogPhotoInformation(props.yulelogID, props.objectId, props.brand);
            props.close();
        });
    }

    function grabYulelogPhotoInformation(yulelogID, objectID, brand) {
        const ALGOLIA_ID = 'G89C4NZVH8';
        const ALGOLIA_ADMIN_KEY = localStorage.getItem("search");
        const collectionClient = algoliasearch(ALGOLIA_ID, process.env.REACT_APP_USER_KEY);
        let itemBrand = 'hallmark';
        if (brand === 'Department 56') {
            itemBrand = 'dept56';
        }
        var docRef = firebase.db.collection(itemBrand).doc(yulelogID.toString());
        docRef.get().then(function (doc) {
            if (doc.exists) {

                var yulelogImageURL = doc.data().imageURL;

                // document.getElementById("item-image").src = doc.data().imageURL;
                var userDocRef = firebase.db.collection("users").doc(user).collection("maincollection").doc(objectID);
                return userDocRef.update({
                    imageURL: yulelogImageURL,
                    customPhoto: false
                })
                    .then(function () {
                        const collectionIndex = collectionClient.initIndex("userItems");
                        collectionIndex.partialUpdateObject({

                            objectID: objectID,
                            customPhoto: false,
                            imageURL: yulelogImageURL

                        }, function (err, content) {
                            if (err) throw err;

                        })
                            .catch(function (error) {
                                // The document probably doesn't exist.
                                console.error("Error updating document: ", error);
                            });
                        props.setImage(yulelogImageURL, false);
                    });

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });

    }

    const renderCustomImage = () => {
        if (props.showCustomImg) {
            return props.customImg;
        }
        return props.pgImg;
    }

    const [imgSrc, setImgSrc] = React.useState(renderCustomImage());
    const [showCustomImage, setCustomImage] = React.useState(props.showCustomImg);


    return (
        <Modal
            style={{ zIndex: '9999' }}
            dialogClassName={styles.Modal}
            size="lg"
            show={props.open}
            aria-labelledby="contained-modal-title-vcenter"
            onHide={props.close}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Custom Image?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Upload a custom image or use the default YuleLog Price Guide image.
                </p>
                <div className={styles.Images}>
                    <div className={styles.LeftImage}>
                        <h4 className={styles.ImageHeader}>YuleLog Image</h4>
                        <div className={styles.Image}>
                            {props.pgImg === ""
                                ? <img className={styles.Image} height='300px' src={defaultImage} />
                                : props.isCustom ? <img className={styles.Image} height='300px' src={missingYl} /> : <img className={styles.Image} height='300px' src={props.pgImg} />
                            }
                        </div>
                    </div>
                    <div className={styles.RightImage}>
                        <h4 className={styles.ImageHeader}>Your Image</h4>
                        <div className={styles.Image}>
                            <div>
                                {
                                    showCustomImage
                                        ? <img height='300px' src={imgSrc} />
                                        : <img height='300px' src={missing} />
                                }
                            </div>
                        </div>
                        <div className={styles.ButtonWrapper}>
                            {/* <input onchange={uploadCustomImageHallmark()} accept='image/*,' style="display:none" id="file-input" type="file" />
                            <Button className={styles.Button} variant="contained">Upload Image</Button> */}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {showCustomImage
                    ? !props.isCustom
                        ? <>
                            <ImageUploader submit={handleSubmit} />
                            <div onClick={useYulelogPhoto} className={styles.Restore}>
                                <h4>Use YuleLog Price Guide Image</h4>
                                <p>We do our best to provide high quality images of Hallmark ornaments.</p>
                            </div>
                        </>
                        : <ImageUploader submit={handleSubmit} />
                    : <ImageUploader submit={handleSubmit} />
                }
            </Modal.Footer>
        </Modal>
    )
}

export default CustomImageModal;