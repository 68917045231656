export const generateRandomId = (len) => {

    // Function to generate randomID for Algolia + Firebase item ids
    var text = "";
    var charset = "abcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < len; i++)
        text += charset.charAt(Math.floor(Math.random() * charset.length));

    return text;
}