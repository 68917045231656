import React from "react"
import Modal from 'react-bootstrap/Modal';
import styles from './Barcode.module.css';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import { properties } from '../../../../constants/properties';

import BarcodeScannerComponent from "react-qr-barcode-scanner";


const BarcodeModal = ({ matchFound, cancelClicked, ...props }) => {

    const [data, setData] = React.useState('Not Found');
    const [stopStream, setStopStream] = React.useState(false)

    const handleMatchFound = (code) => {
        matchFound(code);
    };


    return (
        <Modal
            {...props}
            dialogClassName={styles.Modal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animated={true}
        >
            <Modal.Body>
                <center>
                    <h2>{properties.barcode.scan} <Chip color='warning' variant='filled' size='large' label="Beta" /></h2>

                    <p>{properties.barcode.scanInstructions}</p>
                    <div dangerouslySetInnerHTML={{ __html: properties.barcode.mobileScan }} />
                    <>
                        <BarcodeScannerComponent
                            width={300}
                            height={300}
                            onUpdate={(err, result) => {
                                if (result) {
                                    setData(result.text);
                                    cancelClicked();
                                    handleMatchFound(result.text);
                                } else setData("No barcode detected");
                            }}
                        />
                        <p>{data}</p>
                    </>
                    <Button onClick={() => cancelClicked()}>Cancel</Button>
                </center>
            </Modal.Body>

        </Modal>
    )
}

export default BarcodeModal;

