import React from 'react';
import TextField from '@mui/material/TextField';
import styles from './NameInput.module.css';

const NameInput = (props) => {

    return (
        <TextField
            hiddenLabel
            variant="filled"
            placeholder="Item Name"
            defaultValue={props.value}
            InputProps={{
                disableUnderline: true,
                style: {
                    width: 400,
                    marginTop: 10,
                    fontSize: 20,
                } // font size of input label
            }}
        />
    )
};

export default NameInput;