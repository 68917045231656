import React from 'react'

import './Overview.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LINKS } from '../../../constants/links';


const Overview = (props) => (
    <div>
        <div className="overview">
            <div className="overview-header">
                <h1 className="overview-headline">{props.headline}</h1>
            </div>
            <div className="overview-textbox">
                <p className="overview-paragraph">{props.description}</p>
                <p>
                    {props.showLink && <a className="feature-link" href={LINKS.HELP.SUBSCRIPTION_TYPES} target="_blank">Learn more</a>}
                </p>
            </div>
        </div>
    </div >
)

export default Overview;