import React, { useEffect } from 'react';
import { orderBy } from 'lodash';
import { makeStyles } from '@mui/styles';
import Collapsible from 'react-collapsible';

import { IconButton, Menu, MenuItem, List, ListItemIcon, ListItemText, Chip, AppBar, Toolbar, Divider, Drawer, CssBaseline } from '@mui/material';
import DrawerList from '../../components/Drawers/DrawerList';
import CollectionHits from '../../components/Hit/CollectionHit/CollectionHit';
import algoliasearch from 'algoliasearch';
import { InstantSearch, RefinementList, MenuSelect, ToggleRefinement, ClearRefinements, Configure, Panel } from 'react-instantsearch-dom';
import { Add, Book, Create, Refresh, Help } from '@mui/icons-material';
import CustomToolbar from '../../components/UI/CustomToolbar/CustomToolbar';
import CustomStateCollectionResults from '../../components/UI/CustomStateCollectionResults/CustomStateCollectionResults';
import CollapsibleHeader from '../../components/UI/CollapsibleHeader/CollapsibleHeader';
import CustomClearRefinements from '../../components/UI/CustomClearRefinements/CustomClearRefinements';

import styles from './Collection.module.css';
import 'instantsearch.css/themes/satellite.css';
import './Collection.css';

import { AuthUserContext, withAuthorization } from '../../components/Session';
import Firebase, { withFirebase } from '../../components/Firebase';
import { getOrDefault } from '../../lib/util';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: 400
    },
    drawerPaper: {
        marginTop: 5,
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));


const Collection = ({ firebase }, props) => {

    document.title = 'YuleLog Collection';
    const [isEmptyCollection, setIsEmptyCollection] = React.useState(false);
    const showD56 = localStorage.getItem('showD56');

    /* This feature is broken for now
    useEffect(() => {
        const collectionArr = JSON.parse(localStorage.getItem("collectionItems"));
        if (collectionArr) {
            if (collectionArr.length === 0) {
                setIsEmptyCollection(true)
            } else {
                setIsEmptyCollection(false)
            }
        }
    });
    */


    const [refresh, setRefresh] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const callback = () => {
        setRefresh(true)

        firebase.db.collection("users").doc(firebase.auth.currentUser.uid).collection("userinfo").doc("locations")
            .get()
            .then(doc => {
                // Grab Collection/Collector/Locations Data
                localStorage.setItem("collectorDefault", getOrDefault(doc.data().collectorDefault));
                localStorage.setItem("collectors", JSON.stringify(doc.data().collector));
                localStorage.setItem("collectionDefault", getOrDefault(doc.data().collectionDefault));
                localStorage.setItem("collections", JSON.stringify(doc.data().collection));
                localStorage.setItem("purchaseLocations", JSON.stringify(doc.data().purchaseLocation));
                localStorage.setItem("purchaseLocationDefault", getOrDefault(doc.data().purchaseLocationDefault));
                localStorage.setItem("displayLocations", JSON.stringify(doc.data().displayLocation));
                localStorage.setItem("displayLocationDefault", getOrDefault(doc.data().displayLocationDefault));
                localStorage.setItem("storageLocations", JSON.stringify(doc.data().storageLocation));
                localStorage.setItem("storageLocationDefault", getOrDefault(doc.data().storageLocationDefault));
                setTimeout(() => { setRefresh(false) }, 2000);
            })


    }

    const handleClickAddMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseAddMenu = () => {
        setAnchorEl(null);
    };
    const goToPriceGuide = () => {
        return window.location.href = '/priceguide'
    };

    let shouldRefresh = sessionStorage.getItem('shouldRefreshCollection');
    if (shouldRefresh === "true") {
        setRefresh(true)
        setTimeout(() => { setRefresh(false) }, 2000);
        sessionStorage.setItem("shouldRefreshCollection", "false");
    }

    const classes = useStyles();

    const search = localStorage.getItem('search');
    const user = localStorage.getItem('user');

    const searchClient = algoliasearch(process.env.REACT_APP_SEARCH_ID, search);


    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <InstantSearch refresh={callback} searchClient={searchClient} indexName={"userItems"}>
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar position="fixed" className={classes.appBar}>
                            <CustomToolbar title="Collection">
                                <IconButton onClick={callback}>
                                    <Refresh className={styles.IconButton} />
                                </IconButton>
                                <IconButton
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClickAddMenu}>
                                    <Add className={styles.IconButton} />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleCloseAddMenu}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem
                                        onClick={goToPriceGuide}>
                                        <ListItemIcon>
                                            <Book fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Add Item from Price Guide</ListItemText>
                                    </MenuItem>
                                    <MenuItem disabled onClick={handleCloseAddMenu}>
                                        <ListItemIcon>
                                            <Create fontSize="small" />
                                        </ListItemIcon>
                                        Add Custom Item<Chip style={{ marginLeft: '10px' }} color="primary" label="Coming Soon" size="small" />
                                    </MenuItem>
                                </Menu>
                                <IconButton>
                                    <Help onClick={() => window.open('https://help.yulelog.com/hallmark/v/yulelog-for-web/')} className={styles.IconButton} />
                                </IconButton>
                            </CustomToolbar>
                        </AppBar>
                        <Drawer
                            sx={{
                                zIndex: 400,
                                width: 240,
                                '& .MuiDrawer-paper': {
                                    width: 240
                                },
                            }}
                            variant="permanent"
                        >
                            <Toolbar />
                            <DrawerList pageSelected={0} />
                            <div className={styles.ClearButton}>
                                <CustomClearRefinements />
                            </div>
                            <div className={classes.drawerContainer}>
                                <List />
                                <Collapsible trigger={<CollapsibleHeader title="Year" />}>
                                    <Panel className={styles.Panel}>
                                        <RefinementList
                                            attribute="year"
                                            limit={100}
                                            transformItems={items => orderBy(items, "label", "desc")} />
                                    </Panel>
                                </Collapsible>
                                <Collapsible trigger={<CollapsibleHeader title="Category" />} >
                                    <Panel className={styles.Panel}>
                                        <RefinementList attribute="category" />
                                    </Panel>
                                </Collapsible>
                                <Collapsible trigger={<CollapsibleHeader title="Artist" />}>
                                    <Panel className={styles.Panel}>
                                        <RefinementList attribute="artist" limit={1000} transformItems={items => orderBy(items, "label", "asc")} />
                                    </Panel>
                                </Collapsible>
                                <Divider />
                                <Panel className={styles.Panel} header="Series">
                                    <MenuSelect
                                        attribute="series"
                                        limit={1000}
                                        transformItems={items => orderBy(items, "label", "asc")}
                                    />
                                </Panel>
                                <Panel className={styles.Panel} header="Village">
                                    <MenuSelect attribute="village" limit={1000} />
                                </Panel>
                                <Divider />

                                <Divider />
                                <Divider />
                                {showD56 && <Panel className={styles.Panel} header="Brand">
                                    <MenuSelect attribute="brand" limit={1000} />
                                </Panel>}
                                <Panel className={styles.Panel} header="Signed">
                                    <ToggleRefinement attribute="signed" label="Signed by Artist" value={true} />
                                </Panel>
                                <Divider />
                                <Panel className={styles.Panel} header="Status">
                                    <MenuSelect attribute="status" limit={1000} />
                                </Panel>
                                <Panel className={styles.Panel} header="Condition">
                                    <MenuSelect attribute="condition" limit={1000} />
                                </Panel>
                                <Divider />
                                <Panel className={styles.Panel} header="Collector">
                                    <MenuSelect attribute="collector" limit={1000} />
                                </Panel>
                                <Panel className={styles.Panel} header="Collection">
                                    <MenuSelect attribute="collection" limit={1000} />
                                </Panel>
                                <Divider />
                                <Panel className={styles.Panel} header="Display Location">
                                    <MenuSelect attribute="displayLocation" limit={1000} />
                                </Panel>
                                <Panel className={styles.Panel} header="Purchase Location">
                                    <MenuSelect attribute="purchaseLocation" limit={1000} />
                                </Panel>
                                <Panel className={styles.Panel} header="Storage Location">
                                    <MenuSelect attribute="storageLocation" limit={1000} />
                                </Panel>

                            </div>
                        </Drawer>
                        <main className={classes.content}>
                            <Toolbar />
                            <Configure
                                hitsPerPage={50}
                            />
                            <div className="Hits">
                                <CollectionHits user={user} className={styles.Hits} parentCallback={callback} />
                                <CustomStateCollectionResults isCollection={true} isEmptyCollection={isEmptyCollection} />

                            </div>

                        </main>
                    </div>
                </InstantSearch>
            )
            }
        </AuthUserContext.Consumer >
    )
};

const condition = authUser => !!authUser;

export default withFirebase(withAuthorization(condition)(Collection));