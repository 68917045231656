import { properties } from '../constants/properties';

export const getRandomLoaderText = () => {

    // Function to generate random loader text

    let loaderTexts = properties.loaderText;
    let max = loaderTexts.length
    let random = Math.floor(Math.random() * max);

    return loaderTexts[random];
}