import React from 'react';

import './FeatureList.css';

function FeatureList(props) {
    const features = props.features;
    const listItems = features.map((feature) =>
        <li key={feature.toString()}>
            {feature}
        </li>
    );
    return (
        <ul className="feature-list">{listItems}</ul>
    );
}

export default FeatureList;