import React from 'react';
import styles from './PriceInput.module.css';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const PriceInput = (props) => {
    const [price, setPrice] = React.useState(props.value);

    const handleChange = (event) => {
        let { value } = event.target;

        // Remove any non-numeric characters
        value = value.replace(/[^0-9.]/g, '');

        // Remove leading zeros
        value = value.replace(/^0+(?=\d)/, '');

        // Limit decimal places to two
        const parts = value.split('.');
        if (parts.length > 1) {
            parts[1] = parts[1].slice(0, 2);
            value = parts.join('.');
        }

        // Limit value to 100000
        if (parseFloat(value) > 100000) {
            return
        }

        props.changed(props.id, value);
        setPrice(value);
    }

    const handleBlur = () => {
        // Format the value to always have two decimal places when the input field loses focus
        const formattedValue = parseFloat(price).toFixed(2);
        setPrice(formattedValue);
    }

    return (
        <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
                id={props.id}
                label={props.label}
                value={price}
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: { min: 0, max: 10000, step: 0.01 }, // Set min, max, and step values
                    inputMode: 'numeric', // Set input mode to numeric for better mobile support
                    placeholder: '0.00',
                }}
            />
        </FormControl>
    );
};

export default PriceInput;