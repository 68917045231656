import React from 'react'
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { spacing } from '@mui/system';
import styles from './TableNavigationRow.module.css';

import IconButton from '@mui/material/IconButton';
import TableViewIcon from '@mui/icons-material/TableView';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Tooltip from '@mui/material/Tooltip';


import { tooltipsText } from '../../../../constants/properties';

export default function ButtonRow(props) {


    return (
        <div className={styles.TableNavigationRow}>
            <select
                value={props.pageSize}
                onChange={props.onPageSizeChange}
            >
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                ))}
            </select>
            <div className={styles.Left}>
                Page&nbsp;
                <strong>
                    {props.pageIndex + 1} of {props.pageOptions}
                </strong>{' '}
                <IconButton
                    onClick={props.previousClicked}
                    aria-label="previous-page"
                    disabled={props.previousDisabled}
                    size="large">
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <IconButton
                    onClick={props.nextClicked}
                    aria-label="next-page"
                    disabled={props.nextDisabled}
                    size="large">
                    <KeyboardArrowRightIcon />
                </IconButton>
            </div>
            <div>
                <span>
                    Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={props.pageIndex + 1}
                        onChange={props.onChange}
                        style={{ width: '100px' }}
                    />
                </span>
            </div>
        </div>
    )
}
