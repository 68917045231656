import React from 'react';
import styles from './StatusSelect.module.css';
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { renderStatus } from '../../../../../lib/lookupValues';

const StatusSelect = (props) => {

    const [itemStatus, setStatus] = React.useState(props.statusValue);

    const handleChange = (event) => {
        setStatus(event.target.value);
        props.changed("status", event.target.value)
    };


    return (
        <div className={styles.StatusSelect}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-status">Status</InputLabel>
                <Select

                    labelId="demo-simple-select-label-status"
                    id="demo-simple-select-status"
                    value={itemStatus}
                    label="Status"
                    onChange={handleChange}
                >
                    <MenuItem value={"Own"}>Own</MenuItem>
                    <MenuItem value={"Wanted"}>Wanted</MenuItem>
                    <MenuItem value={"For Sale"}>For Sale</MenuItem>
                    <MenuItem value={"Sold"}>Sold</MenuItem>
                    <MenuItem value={"Trade"}>Trade</MenuItem>
                    <MenuItem value={"In Layaway"}>In Layaway</MenuItem>
                    <MenuItem value={"On Order"}>On Order</MenuItem>
                    <MenuItem value={"Missing"}>Missing</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
};

export default StatusSelect;