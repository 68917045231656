import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './ActivateProductModal.module.css';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BookIcon from '@mui/icons-material/Book';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import HouseIcon from '@mui/icons-material/House';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { SHORT_FEATURES } from '../../../../constants/properties';

import d56 from '../../../../img/56ylicon.png';
import hallmark from '../../../../img/hallmarkylicon.png';

const ActivateProductModal = (props) => {

    const featuresObj = props.product === "Hallmark" ? SHORT_FEATURES.hallmark : SHORT_FEATURES.d56;
    const currentPrice = props.product === "Hallmark" ? "50.00" : "60.00";
    const image = props.product === "Hallmark" ? hallmark : d56;

    return (
        <Modal
            {...props}
            dialogClassName={styles.Modal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            animated={true}
        >
            <Modal.Body
                className={styles.Body}>
                <div className={styles.Header}>
                    <h4><b>Activate the YuleLog {props.product} Price Guide?</b></h4>
                </div>
                <div className={styles.Image}>
                    <img height='100px' width='auto' src={image} />
                </div>
                <div className={styles.Caption}>
                    <p>Upgrade your subscription to access YuleLog's {props.product} Price Guide for a total of <b>$74.99</b> / <b>year.</b></p>
                </div>
                <div className={styles.Features}>
                    <List dense={true}>
                        <ListItem>
                            <ListItemIcon>
                                <BookIcon style={{ color: '#7d54b4' }} />
                            </ListItemIcon>
                            <ListItemText
                                style={{ fontWeight: 'bold' }}
                                primary={featuresObj.feature1}
                                primaryTypographyProps={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    color: 'black',
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {props.product === "Department 56"
                                    ? <HouseIcon style={{ color: '#7d54b4' }} />
                                    : <DocumentScannerIcon style={{ color: '#7d54b4', transform: 'rotate(90deg)' }} />
                                }
                            </ListItemIcon>
                            <ListItemText
                                style={{ fontWeight: 'bold' }}
                                primary={featuresObj.feature2}
                                primaryTypographyProps={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircle style={{ color: '#7d54b4' }} />
                            </ListItemIcon>
                            <ListItemText
                                style={{ fontWeight: 'bold' }}
                                primary={featuresObj.feature3}
                                primaryTypographyProps={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            />
                        </ListItem>
                    </List>
                </div>
                <div className={styles.ButtonRow}>
                    <Button onClick={props.clicked} style={{ color: 'white', marginLeft: '10px', marginBottom: '10px' }} variant="contained" color="success">Upgrade</Button><br></br>
                    <Button onClick={props.cancelClicked}>Cancel</Button>
                </div>
                <div className={styles.NoteDiv}>
                    <p className={styles.Note}>Note:  <span className={styles.UpgradeText}>By clicking "Upgrade" your yearly subscription will increase from <strong>${currentPrice}</strong> to <strong>$75.00.</strong><br></br><br></br>You can edit your subscription from the <strong>Settings</strong> menu.<a style={{ fontWeight: 'bold', textDecoration: 'none' }} target="_blank" href="https://help.yulelog.com/hallmark/v/yulelog-for-web/yulelog-subscription-types"> Learn more.</a></span> </p>

                </div>
            </Modal.Body>
        </Modal >
    )
}

export default ActivateProductModal;