import React, { Fragment } from 'react';
import Firebase from '../../../Firebase/firebase';
import { Helmet } from 'react-helmet';

import YoutubeEmbed from '../CollectionModal/YoutubeEmbed/YoutubeEmbed';

import NumberFormat from 'react-number-format';

import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import Chart from 'react-google-charts';
import Skeleton from '@mui/lab/Skeleton';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CartIcon from '@mui/icons-material/ShoppingCartRounded';
import LinkIcon from '@mui/icons-material/LinkRounded';

import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';


import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CollectionInput from './CollectionInput/CollectionInput';
import BoxnumInput from './BoxnumInput/BoxnumInput';
import NameInput from './NameInput/NameInput';
import SeriesInput from './SeriesInput/SeriesInput';
import VillageInput from './VillageInput/VillageInput';
import SeriesNumInput from './SeriesNumInput/SeriesNumInput';
import YearSelect from './YearSelect/YearSelect';
import MyCommentsInput from './MyCommentsInput/MyCommentsInput';
import PriceInput from './PriceInput/PriceInput';
import Profit from './Profit/Profit';
import PriceChange from '../PriceChange/PriceChange';
import ConditionSelect from './ConditionSelect/ConditionSelect';
import StatusSelect from './StatusSelect/StatusSelect';
import Signed from './Signed/Signed';
import Quantity from './Quantity/Quantity';
import DateSelect from './DateSelect/DateSelect';
import CalculatedValue from './CalculatedValue/CalculatedValue';
import Image from './Image/Image';
import CustomImageModal from './CustomImageModal/CustomImageModal';

import { getArtist } from '../../../../lib/getArtist';
import { calculateProfit } from '../../../../lib/collectionOperations';
import defaultImage from '../../../../img/missing.png';
import styles from './CollectionModal.module.css';
import { useStyles } from '../../../../constants/useStyles';
import { tooltipsText } from '../../../../constants/properties';
import './CollectionModal.css';
import { Hits } from 'react-instantsearch-dom';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


class CollectionModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openSnackbar: false,
            anchorEl: null,
            isMenuOpen: false,
            updated: false,
            updatedArray: false,
            showSaveAlert: false,
            showCustomImageModal: false,
            profit: 0,
            image: this.props.imageurl ? this.props.imageurl : defaultImage,
            customImageSet: false,
            status: this.props.status,
            signed: this.props.signed,
            myComments: this.props.myComments,
            dateBought: this.props.dateBought,
            dateSold: this.props.dateSold,
            quantity: this.props.quantity,
            mibPrice: this.props.mibPrice,
            condition: this.props.condition,
            priceBought: this.props.priceBought,
            priceSold: this.props.priceSold,
            purchaseLocation: this.props.purchaseLocation,
            displayLocation: this.props.displayLocation,
            storageLocation: this.props.storageLocation,
            collector: this.props.collector,
            collection: this.props.collection,
            boxnum: this.props.boxnum,
            yulelogID: this.props.yulelogID
        }
        this.handleMyCommentsChange = this.handleMyCommentsChange.bind(this);
        this.initialState = { ...this.state }
    }

    renderArtist = (artist) => {

        if (!artist) {
            return null
        } else if (artist[0].length === 0) {
            return null
        } else {
            let artistArr = artist.map((i) =>
                getArtist(i)
            )
            return artistArr.map((i) =>
                <BootstrapTooltip title={i.bio} arrow placement="top">
                    <Chip avatar={<Avatar src={i.src}></Avatar>} className={styles.Artist} label={i.name} />
                </BootstrapTooltip>
            );
        }
    }

    renderKeywords = (keywords) => {

        if (!keywords) {
            return null
        }
        else if (keywords[0].length === 0) {
            return null
        } else {
            return keywords.map((i) =>
                <BootstrapTooltip title={tooltipsText.general.keyword} arrow placement="top">
                    <Chip className={styles.Keywords} label={i} />
                </BootstrapTooltip>
            );
        }
    }


    renderVillage = (village) => {
        return village == "" ? null : village;
    };

    renderSeries = (series) => {
        return series == "" ? null : series;
    };

    renderSeriesNum = (seriesnum) => {
        return seriesnum == 0 ? null : '#' + seriesnum;
    };

    renderEmbedId = (url) => {
        if (url = "") {
            return null
        }
        console.log("video url:", this.props.videoURL)
        let embedId = this.props.videoURL.replace("https://youtu.be/", "")
        console.log("embed", embedId)
        return <YoutubeEmbed embedId={embedId} />
    }

    renderImageURL = (imageURL, setCustomImage) => {
        let image;
        if (!this.props.imageurl) {
            return <Tooltip title={tooltipsText.image.unavailable}><img className={styles.DefaultImage} src={defaultImage} /></Tooltip>
        }
        /* If custom image is being set from Custom Image Modal */
        if (setCustomImage) {
            this.setState({
                ...this.state,
                image: imageURL,
                customImageSet: true
            })
            this.props.shouldRefresh(true);
            return <Image clicked={this.handleCustomImageModalOpen} img={imageURL} />
        }
        /* Normal flow */
        if (!setCustomImage) {
            this.setState({
                ...this.state,
                image: imageURL,
                customImageSet: false
            })
            this.props.shouldRefresh(true);
        }
    };


    renderPrice = (price) => {
        if (price === 0) {
            return <NumberFormat fixedDecimalScale={true} decimalScale={2} value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        }
        return price ? (<NumberFormat fixedDecimalScale={true} decimalScale={2} value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} />) : (<Skeleton variant="text" width={150} />)
    }

    renderChart = (brand) => {
        if (brand === "Hallmark") {
            return this.props.mibPrice ?
                <Chart
                    width={'500px'}
                    height={'200px'}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        ['x', 'MIB Average Price'],
                        ["2019", this.props.price2019],
                        ["2020", this.props.price2020],
                        ["2021", this.props.price2021],
                        ["2022", this.props.price2022],
                        ["2023", this.props.mibPrice],
                    ]}
                    options={{
                        title: "MIB Price Over Time",
                        titleTextStyle: {
                            fontSize: 14
                        },
                        hAxis: {
                            title: 'Year',
                        },
                        vAxis: {
                            title: 'MIB Average Price',
                            gridlines: {
                                color: 'transparent'
                            }
                        },
                        legend: 'none',
                        series: {
                            0: { color: '#7D54B4' }
                        },
                        pointsVisible: true,
                    }
                    }
                />
                : <Skeleton variant="rect" width={300} height={200} />
        }
    }

    saveItem = (e) => {
        this.props.saveItem(this.props.objectID, this.props.user, this.state)(e);
        this.props.hide();
    }

    deleteItem = () => {

    }


    // const [value, setValue] = React.useState('');


    // const [dateBought, setDateBought] = React.useState(new Date('2014-08-18T21:11:54'));

    handleClose = () => console.log("")

    handleCollectorChange = (newValue) => {
        this.setState({ ...this.state, collector: newValue });
    };

    handleCollectionChange = (newValue) => {
        this.setState({ ...this.state, collection: newValue });
    };

    handleDisplayLocationChange = (newValue) => {
        this.setState({ ...this.state, displayLocation: newValue });
    }

    handlePurchaseLocationChange = (newValue) => {
        this.setState({ ...this.state, purchaseLocation: newValue });
    }

    handleStorageLocationChange = (newValue) => {
        this.setState({ ...this.state, storageLocation: newValue });
    }

    handleMyCommentsChange = (value) => {
        this.setState({
            ...this.state,
            updated: true,
            myComments: value
        });
    };

    handleChange = (key, value) => {
        let changedKey = key;
        this.setState({
            ...this.state,
            updated: true,
            [changedKey]: value
        })

    };

    handleCollectionInputChange = (key, value, arr) => {

        let changedKey = key
        let changedArray = changedKey + "Array";
        // Remove empty strings from array
        arr = arr.filter(item => item.trim() != '');
        /* Handle items already in the array */
        if (arr.includes(value)) {
        } else {
            arr.push(value);
        }
        this.setState({
            ...this.state,
            updated: true,
            updatedArray: true,
            [changedKey]: value,
            [changedArray]: arr
        })
    }

    handlePriceChange = (key, value) => {
        let changedKey = key
        let priceBought = parseFloat(this.state.priceBought);
        let priceSold = parseFloat(this.state.priceSold);
        this.setState({
            ...this.state,
            updated: true,
            [changedKey]: parseFloat(value),
            profit: calculateProfit(priceBought, priceSold)
        })
    };

    handleOpen = () => {
        this.setState({
            updated: false,
            signed: false,
            status: "",
            myComments: "",
            collector: "",
            collection: ""
        })
    }

    handleClose = () => {
        if (this.state.updated) {
            return this.setState({
                ...this.state,
                showSaveAlert: true
            })
        }
        this.setState(this.initialState);
        this.props.hide();
    }

    handleDoNotSave = () => {
        this.setState(this.initialState);
        this.props.hide();
    }


    handleCustomImageModalOpen = () => {
        this.setState({
            ...this.state,
            showCustomImageModal: true
        })
    };

    handleCustomImageModalClose = () => {
        this.setState({
            ...this.state,
            showCustomImageModal: false
        })
    }

    handleClickAddMenu = (event) => {
        this.setState({
            ...this.state,
            anchorEl: event.currentTarget,
            isMenuOpen: true
        })
    };

    handleCloseAddMenu = () => {
        this.setState({
            ...this.state,
            anchorEl: null,
            isMenuOpen: false
        })
    };

    goToEbay = () => {
        this.handleCloseAddMenu();
        this.props.shopVisited(this.props.yulelogID, 'ebay');
        return window.open('https://www.ebay.com/sch/i.html?_from=R40&_trksid=p2380057.m570.l1313&_nkw=' + this.props.boxnum, '_blank');
    };

    goToOrnamentShop = () => {
        this.handleCloseAddMenu();
        this.props.shopVisited(this.props.yulelogID, 'ornament-shop');
        return window.open(`https://www.ornament-shop.com/search.php?search_query=${this.props.boxnum}&utm_source=YuleLog`, '_blank');
    };

    goToOrnamentMall = () => {
        this.handleCloseAddMenu();
        this.props.shopVisited(this.props.yulelogID, 'ornament-mall');
        return window.open(`https://www.ornamentmall.com/search.asp?keyword=${this.props.boxnum}&utm_source=YuleLog`, '_blank');
    };

    goToHookedOnOrnaments = () => {
        this.handleCloseAddMenu();
        this.props.shopVisited(this.props.yulelogID, 'hooked-on-ornaments');
        return window.open(`https://www.hookedonhallmark.com/search.asp?keyword=${this.props.boxnum}&utm_source=YuleLog`, '_blank');
    };

    handleLinkClick = () => {

        console.log("Call props function link")
        this.props.shareLink(this.props.yulelogID);

        this.setState({
            ...this.state,
            openSnackbar: true
        })
    };


    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            ...this.state,
            openSnackbar: false
        })
    };



    render() {
        const isCustom = this.props.brand === "Custom" ? true : false;
        const isHallmark = this.props.brand === "Hallmark";
        return (
            <div>
                <Modal
                    {...this.props}
                    dialogClassName={styles.Modal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    animated={false}
                    onHide={this.handleClose}
                    onEnter={this.handleOpen}
                >
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title>
                            <BootstrapTooltip title={tooltipsText.collection.deleteItem} placement="top">
                                <IconButton onClick={this.props.deleteItem(this.props.objectID, this.props.yulelogID, this.props.user, this.props.status)} style={{ marginRight: '10px' }} aria-label="add">
                                    <DeleteIcon />
                                </IconButton>
                            </BootstrapTooltip>
                            {isCustom && <BootstrapTooltip title={tooltipsText.general.custom} placement="right"><Chip size="small" color="warning" label="Custom Item" /></BootstrapTooltip>}
                            {!isCustom && <Fragment><BootstrapTooltip title={tooltipsText.priceGuide.shop} placement="top">
                                <IconButton onClick={this.handleClickAddMenu} style={{ marginRight: '10px' }} aria-label="shop">
                                    <CartIcon />
                                </IconButton>
                            </BootstrapTooltip>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={this.state.anchorEl}
                                    open={this.state.isMenuOpen}
                                    onClose={this.handleCloseAddMenu}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={this.goToEbay}>
                                        <ListItemText>eBay</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={this.goToHookedOnOrnaments}>
                                        Hooked on Ornaments
                                    </MenuItem>
                                    <MenuItem onClick={this.goToOrnamentShop}>
                                        The Ornament Shop
                                    </MenuItem>
                                    <MenuItem onClick={this.goToOrnamentMall}>
                                        Ornament Mall
                                    </MenuItem>
                                </Menu>
                                {isHallmark && <BootstrapTooltip title={tooltipsText.priceGuide.copyLink} placement="top">
                                    <IconButton onClick={() => this.handleLinkClick()} aria-label="copy-link">
                                        <LinkIcon />
                                    </IconButton>
                                </BootstrapTooltip>
                                }
                                <Snackbar
                                    style={{ marginTop: 100 }}
                                    open={this.state.openSnackbar}
                                    autoHideDuration={3000}
                                    onClose={this.handleCloseSnackbar}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                >
                                    <Alert anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={this.handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                                        Link copied to clipboard.
                                    </Alert>
                                </Snackbar>
                                {this.props.category ? <><br></br>&nbsp;<BootstrapTooltip title={tooltipsText.general.category} placement="right"><Chip size="small" color="primary" label={this.props.category} /></BootstrapTooltip></> : ""}
                            </Fragment>
                            }
                            {
                                isCustom && <Fragment>
                                    <div className={styles.IsCustomHero}>
                                        <div className={styles.IsCustomInfo}>
                                            <div className={styles.CustomBoxNumYearWrapper}>
                                                <div className={styles.CustomYear}>
                                                    <YearSelect
                                                        className={styles.CustomYear}
                                                        value={this.props.customYear}
                                                        changed={this.handleChange} />
                                                </div>
                                                <div className={styles.CustomBoxNum}>
                                                    <BoxnumInput
                                                        value={this.props.customBoxnum}
                                                        changed={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className={styles.NameInput}><NameInput
                                                value={this.props.customName}
                                                changed={this.handleChange}
                                            />
                                            </div>
                                            <div className={styles.CustomSeriesWrapper}>
                                                <div className={styles.CustomSeriesInput}>
                                                    <SeriesInput
                                                        value={this.props.customSeries}
                                                        changed={this.handleChange}
                                                    />
                                                </div>
                                                <div className={styles.CustomSeriesNumInput}>
                                                    <SeriesNumInput
                                                        value={this.props.customSeries}
                                                        changed={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.CustomVillageWrapper}>
                                                <div className={styles.CustomVillageInput}>
                                                    <VillageInput
                                                        value={this.props.customVillage}
                                                        changed={this.handleChange}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className={styles.IsCustomImage}>
                                            <Image clicked={this.handleCustomImageModalOpen} img={this.state.image} />
                                        </div>
                                    </div>
                                </Fragment>
                            }

                            {!isCustom && <div>
                                <BootstrapTooltip title={tooltipsText.general.year} placement="top"><span className={styles.Year}>{this.props.year}</span></BootstrapTooltip>
                                <BootstrapTooltip title={tooltipsText.general.boxnum} arrow placement="right"><span className={styles.Year}>{this.props.boxnum}</span></BootstrapTooltip>
                                <br />
                                {this.props.isItemCollected &&
                                    <BootstrapTooltip title={tooltipsText.priceGuide.itemAdded} arrow placement="right">
                                        <div className={styles.ItemAdded}><CheckCircleIcon style={{ fontSize: 12 }} /> Item in collection</div>
                                    </BootstrapTooltip>
                                }
                                <BootstrapTooltip title={tooltipsText.general.nameModal} arrow placement="right"><span className={styles.Name}>{this.props.name}</span></BootstrapTooltip>
                                {this.renderVillage(this.props.village) && (<div><BootstrapTooltip title={tooltipsText.general.village} arrow placement="right"><span className={styles.Series}>{this.props.village}</span></BootstrapTooltip></div>)}
                                {this.renderSeries(this.props.series) && (<div><BootstrapTooltip title={tooltipsText.general.series} arrow placement="right"><span className={styles.Series}>{this.props.series} {this.renderSeriesNum(this.props.seriesnum)}</span></BootstrapTooltip></div>)}
                            </div>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className='pgModalContainer'>
                            {/* IF NOT A CUSTOM ITEM, GET PRICE GUIDE INFO */}
                            {!isCustom &&
                                <Row>
                                    <Col xs={12} md={7}>
                                        <div>
                                            {this.renderArtist(this.props.artist) && (<div className={styles.ArtistWrapper}><span>{this.renderArtist(this.props.artist)}</span></div>)}
                                            <span className={styles.Prices}>
                                                <BootstrapTooltip title={tooltipsText.general.boxPrice} arrow placement="top"><b>Box Price: </b></BootstrapTooltip>{this.renderPrice(this.props.boxPrice)}<br />
                                                <BootstrapTooltip title={tooltipsText.general.mibPrice} arrow placement="top"><b>MIB Price: </b></BootstrapTooltip>{this.renderPrice(this.props.mibPrice)}<br />
                                                <PriceChange
                                                    boxPrice={this.props.boxPrice}
                                                    mibPrice={this.props.mibPrice} />
                                            </span>
                                            <br /><br />
                                            {this.props.pgComments ? (<div dangerouslySetInnerHTML={{ __html: this.props.pgComments }} className={styles.ModalText}></div>) : null}
                                        </div>
                                    </Col>
                                    <Col xs={6} md={5}>
                                        <Image clicked={this.handleCustomImageModalOpen} img={this.state.image} />
                                        {this.props.videoURL && this.renderEmbedId(this.props.videoURL)}
                                    </Col>
                                </Row>
                            }
                            {/* CUSTOM ITEM PRICE + PHOTO */}
                            {/* {isCustom &&
                                <Row>
                                    <Col xs={12} md={7}>
                                        <div>
                                            <span className={styles.Prices}>
                                            </span>
                                            <br /><br />
                                        </div>
                                    </Col>
                                    <Col xs={6} md={5}>

                                    </Col>
                                </Row>
                            } */}
                            <hr />
                            <div className={styles.SectionLabel}>
                                Collection Information
                            </div>
                            <Row>
                                <Col sm={4}>
                                    <CollectionInput
                                        changed={this.handleCollectionInputChange}
                                        label="Collector"
                                        dataType="collectors"
                                        id="collector"
                                        text={this.props.collector}
                                    />
                                    <CollectionInput
                                        changed={this.handleCollectionInputChange}
                                        label="Collection"
                                        dataType="collections"
                                        id="collection"
                                        text={this.props.collection}
                                    />
                                </Col>
                                <Col sm={8}>
                                    <MyCommentsInput
                                        label="My Comments"
                                        value={this.props.myComments}
                                        changed={this.handleMyCommentsChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    {this.props.status ? <StatusSelect changed={this.handleChange} statusValue={this.props.status} /> : null}
                                </Col>
                                {this.state.status !== 'Wanted' &&
                                    <Col sm={4}>
                                        <Quantity
                                            quantity={this.props.quantity ? this.props.quantity : 1}
                                            changed={this.handleChange} />
                                    </Col>
                                }
                                {this.state.status !== 'Wanted' &&
                                    <Col sm={4}>
                                        <Signed
                                            signed={this.props.signed ? this.props.signed : false}
                                            changed={this.handleChange}
                                        />
                                    </Col>
                                }
                            </Row>
                            {this.state.status !== 'Wanted' && <div><Row>
                                <Col xs={6}>
                                    <DateSelect
                                        name="dateBought"
                                        label="Date Bought"
                                        date={this.props.dateBought}
                                        changed={this.handleChange} />
                                </Col>
                                <Col xs={6}>
                                    <DateSelect
                                        name="dateSold"
                                        label="Date Sold"
                                        date={this.props.dateSold}
                                        changed={this.handleChange} />
                                </Col>

                            </Row>

                                <hr></hr>
                                <div className={styles.SectionLabel}>
                                    Price Details
                                </div>
                                <Row>
                                    <Col sm={4}>
                                        <div className={styles.PriceField}>
                                            <PriceInput
                                                id="priceBought"
                                                changed={this.handlePriceChange}
                                                label="Price Bought"
                                                minVal={0}
                                                value={parseFloat(this.props.priceBought).toFixed(2)} />
                                        </div>
                                        <div className={styles.PriceField}>
                                            <PriceInput
                                                id="priceSold"
                                                changed={this.handlePriceChange}
                                                label="Price Sold"
                                                minVal={0}
                                                value={parseFloat(this.props.priceSold).toFixed(2)} />
                                        </div>
                                        <div className={styles.ShortSeperator} />
                                        <div className={styles.PriceField}>
                                            <Profit
                                                id="profit"
                                                minVal={-100000}
                                                isReadOnly={true}
                                                label="Profit if Sold"
                                                value={calculateProfit(this.state.priceBought, this.state.priceSold)} />
                                        </div>
                                    </Col>
                                    <Col sm={1}>
                                        <span className={styles.Gap} />
                                    </Col>
                                    <Col sm={5}>
                                        {this.props.condition ? <ConditionSelect changed={this.handleChange} conditionValue={this.props.condition} /> : null}
                                        <CalculatedValue
                                            signed={this.state.signed}
                                            condition={this.state.condition}
                                            mibPrice={this.props.mibPrice} />
                                    </Col>
                                </Row>
                                {!isCustom && <div className={styles.ChartWrapper}>
                                    {this.renderChart(this.state.brand)}
                                </div>
                                }
                                <hr />
                                <div className={styles.SectionLabel}>
                                    Location Info
                                </div>
                                <CollectionInput
                                    changed={this.handleCollectionInputChange}
                                    label="Purchase Location"
                                    dataType="purchaseLocations"
                                    id="purchaseLocation"
                                    text={this.props.purchaseLocation}
                                />
                                <CollectionInput
                                    changed={this.handleCollectionInputChange}
                                    label="Display Location"
                                    dataType="displayLocations"
                                    id="displayLocation"
                                    text={this.props.displayLocation}
                                />
                                <CollectionInput
                                    changed={this.handleCollectionInputChange}
                                    label="Storage Location"
                                    dataType="storageLocations"
                                    id="storageLocation"
                                    text={this.props.storageLocation}
                                />
                            </div>}
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={styles.Footer}>
                            <div className={styles.KeywordsWrapper}>
                                {this.renderKeywords(this.props.keywords)}
                            </div>
                            <div className={styles.FooterButtonWrapper}>
                                {/* <BootstrapTooltip title={tooltipsText.collection.deleteItem} placement="top">
                                    <Button className={styles.FooterButton} startIcon={<DeleteIcon />} onClick={(e) => this.props.deleteItem(this.props.objectID, this.props.yulelogID, this.props.user)(e)} variant="contained" color="warning">Delete Item</Button>
                                </BootstrapTooltip> */}
                                <BootstrapTooltip title={tooltipsText.collection.saveItem} placement="top">
                                    <Button onClick={(e) => this.props.saveItem(this.props.objectID, this.props.user, this.state)(e)} className={styles.FooterButton} variant="outlined" color="primary" startIcon={<SaveIcon />}>Save</Button>
                                </BootstrapTooltip>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal >

                {
                    /* 
                    SAVE WARNING MODAL 
                    */
                }
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.showSaveAlert}
                >
                    <Modal
                        style={{ zIndex: '9999' }}
                        onHide={this.handleClose}
                        show={this.state.showSaveAlert}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Save Changes?
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                Do you want to save the changes you made to this item?
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className={styles.ButtonDiv}>
                                <Button onClick={this.handleDoNotSave}>Do Not Save</Button>
                            </div>
                            <div className={styles.ButtonDiv}>
                                <Button onClick={(e) => this.saveItem(e)} className={styles.FooterButton} variant="outlined" color="primary" startIcon={<SaveIcon />}>Save</Button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </Backdrop>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}
                    open={this.state.showCustomImageModal}
                >
                    <CustomImageModal
                        open={this.state.showCustomImageModal}
                        close={this.handleCustomImageModalClose}
                        brand={this.props.brand}
                        pgImg={this.props.pgImg}
                        isCustom={isCustom}
                        showCustomImg={this.props.showCustomImage}
                        customImg={this.props.customImg}
                        yulelogID={this.props.yulelogID}
                        objectId={this.props.objectID}
                        userId={this.props.user}
                        setImage={this.renderImageURL}
                    />
                </Backdrop>
            </div >
        );
    }
}

export default CollectionModal;