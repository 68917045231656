import React, { useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch';
import localforage from 'localforage';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import DrawerList from '../../components/Drawers/DrawerList';
import ReportDrawerList from '../../components/Reports/ReportDrawerList/ReportDrawerList';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';


// Icons
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import WishlistIcon from '@mui/icons-material/Favorite';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

import ReportSummaryBox from '../../components/Reports/Dashboard/ReportSummaryBox/ReportSummaryBox';
import ReportPieChart from '../../components/Reports/Dashboard/ReportPieChart/ReportPieChart';
import ReportValuables from '../../components/Reports/Dashboard/ReportValuables/ReportValuables';
import ReportFavorites from '../../components/Reports/Dashboard/ReportFavorites/ReportFavorites';

import SmallLogo from '../../components/Logos/SmallLogo/SmallLogo';

import styles from './Reports.module.css';
import { useStyles } from '../../constants/useStyles';
import { getMostValuable, getBrands, getSeries, getArtists, getOwned, getValue, getUnique } from '../../lib/reportSummary';
import { getRandomLoaderText } from '../../lib/getRandomLoaderText';

import { AuthUserContext, withAuthorization } from '../../components/Session';
import Firebase, { withFirebase } from '../../components/Firebase';


const Reports = ({ firebase }) => {

    document.title = 'YuleLog Collection Summary';

    var userID = localStorage.getItem("user");
    var searchKey = localStorage.getItem("search");

    let reportClient = algoliasearch('G89C4NZVH8', searchKey);
    let reportIndex = reportClient.initIndex("userItems");
    let items = [];

    const [hits, setHits] = useState([]);
    const [loading, isLoading] = useState(true);


    useEffect(() => {
        let mounted = true;
        reportIndex.browseObjects({
            query: '', // Empty query will match all records
            filters: 'user:' + userID,
            batch: batch => {
                items = items.concat(batch);
            }
        }).then(() => {
            try {
                localforage.setItem('hits', JSON.stringify(items));
            }
            catch (e) {
                return alert("There was an issue generating a report.")
            }
            setHits(items)
            isLoading(false);
        })
        return () => mounted = false;
    }, [])

    const classes = useStyles();
    const brands = getBrands(hits);
    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            <SmallLogo className={styles.Logo} />
                            <div>
                                <Typography variant="h6" noWrap >
                                    Reports
                                </Typography>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <Toolbar />
                        <DrawerList pageSelected={2} />
                        <div className={classes.drawerContainer}>
                            <List />
                            <ReportDrawerList />
                        </div>
                    </Drawer>
                    <main className={classes.content}>
                        <Toolbar />
                        {loading &&
                            <div>
                                <center>
                                    <h6 className={styles.LoaderText} id="changeText">
                                        <Typography
                                            color="primary"
                                            gutterBottom
                                            variant="body1">
                                            <b>{getRandomLoaderText()}</b>
                                        </Typography>
                                    </h6>
                                </center>
                                <div className={styles.LoaderWrapper}>
                                    <CircularProgress color="primary" />
                                </div>
                            </div>}
                        {!loading && <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportValuables items={getMostValuable(hits)} />
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportPieChart brands={brands} />
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportFavorites type='Series' items={getSeries(hits)} />
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportFavorites type='Artists' items={getArtists(hits)} />
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportSummaryBox
                                    label="Estimated Collection Worth"
                                    number={getValue(hits, "worth")}
                                    type="currency"
                                    colorScheme="neutral">
                                    <MonetizationOnIcon fontSize="large" />
                                </ReportSummaryBox>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportSummaryBox
                                    label="Total Items in Collection"
                                    number={getValue(hits, "total")}>
                                    <TrendingUpIcon fontSize="large" />
                                </ReportSummaryBox>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportSummaryBox
                                    label="Total Purchase Price"
                                    number={getValue(hits, "purchased")}
                                    type="currency"
                                    colorScheme="neutral">
                                    <PaymentIcon fontSize="large" />
                                </ReportSummaryBox>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportSummaryBox
                                    label="Unique Items"
                                    number={getUnique(hits)}>
                                    <FingerprintIcon fontSize="large" />
                                </ReportSummaryBox>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportSummaryBox
                                    label="Total Sold Price"
                                    number={getValue(hits, "sold")}
                                    type="currency"
                                    colorScheme="neutral">
                                    <ReceiptIcon fontSize="large" />
                                </ReportSummaryBox>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportSummaryBox
                                    label="Wishlist Items"
                                    number={getValue(hits, "wanted")}>
                                    <WishlistIcon fontSize="large" />
                                </ReportSummaryBox>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                            >
                                <ReportSummaryBox
                                    label="Net Profit"
                                    type="currency"
                                    number={getValue(hits, "netTotal")}>
                                    <LocalAtmIcon fontSize="large" />
                                </ReportSummaryBox>
                            </Grid>
                        </Grid>}
                    </main>
                </div>
            )
            }
        </AuthUserContext.Consumer >
    )
}


const condition = authUser => !!authUser;

export default withFirebase(withAuthorization(condition)(Reports));