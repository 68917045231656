import React from 'react';
import TextField from '@mui/material/TextField';
import styles from './BoxnumInput.module.css';

const BoxnumInput = (props) => {

    return (
        <TextField
            variant="outlined"
            size="small"
            label="Box #"
            placeholder="Box #"
            defaultValue={props.value}
            InputProps={{
                disableUnderline: true,
            }}
        />
    )
};

export default BoxnumInput;