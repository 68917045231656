import React from 'react'

import './LandingImage.css';

const LandingImage = (props) => (
    <div className="landing-image-wrapper">
        <img className="landing-image" src={props.img} />
    </div>
)

export default LandingImage;