import React from 'react'

import CountUp from 'react-countup';

import './Overview.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import hallmark from '../../../img/icons/hallmark.png';
import d56 from '../../../img/icons/d56.png';
import scan from '../../../img/icons/scan.png';
import BarcodeIcon from '@mui/icons-material/DocumentScanner';

const OverviewGrid = () => (
    <div className="feature-wrapper">
        <div className="container">
            <div className="row">
                <div className="col-sm">
                    <img height="50px" width="60px" src={hallmark} /><br /><br />
                    <p className="overview-paragraph">Price guide includes information for <b><CountUp start={10000} end={15219} separator="," /></b> Hallmark Ornaments, Merry Miniatures, and Kiddie Car Classics.</p>
                </div>
                <div className="col-sm">
                    <img height="50px" width="50px" src={d56} /><br /><br />
                    <p className="overview-paragraph">Price guide includes information for <b><CountUp start={3000} end={8104} separator="," /></b> lighted items and accessories from 23 different villages, including Snowbabies.</p>
                </div>
                <div className="col-sm">
                    <BarcodeIcon sx={{ fontSize: 50, marginBottom: '25px', transform: 'rotate(90deg)' }} />
                    <p className="overview-paragraph">Barcode scanning is available for 10,000+ Hallmark ornaments, making it easy for you to inventory your collection.</p>
                </div>
            </div>
        </div>
    </div>
)

export default OverviewGrid;