import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';

import YoutubeEmbed from '../CollectionModal/YoutubeEmbed/YoutubeEmbed';

import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import Chart from 'react-google-charts';
import Skeleton from '@mui/lab/Skeleton';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircle';
import CartIcon from '@mui/icons-material/ShoppingCartRounded';
import LinkIcon from '@mui/icons-material/LinkRounded';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBag from '@mui/icons-material/ShoppingBagTwoTone';
import Store from '@mui/icons-material/Store';

import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { getArtist } from '../../../../lib/getArtist';
import defaultImage from '../../../../img/missing.png';
import styles from './PriceGuideModal.module.css';
import { useStyles } from '../../../../constants/useStyles';
import { tooltipsText } from '../../../../constants/properties';
import './PriceGuideModal.css';
import { Hits } from 'react-instantsearch-dom';
import { Divider } from '@mui/material';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const PriceGuideModal = (props) => {

    const isHallmark = (props.brand === "Hallmark");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };
    const open = Boolean(anchorEl);

    const handleClickAddMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };



    const handleLinkClick = () => {

        console.log("Call props function link")
        props.itemShared(props.yulelogID);

        setOpenSnackbar(true);

    };

    const handleCloseAddMenu = () => {
        setAnchorEl(null);
    };

    const goToEbay = () => {
        handleCloseAddMenu();
        props.shopVisited(props.yulelogID, 'ebay');
        return window.open('https://www.ebay.com/sch/i.html?_from=R40&_trksid=p2380057.m570.l1313&_nkw=' + props.boxnum, '_blank');
    };

    const goToOrnamentShop = () => {
        handleCloseAddMenu();
        props.shopVisited(props.yulelogID, 'ornament-shop');
        return window.open('https://www.ornament-shop.com/search.php?search_query=' + props.boxnum, '_blank');
    };

    const goToOrnamentMall = () => {
        handleCloseAddMenu();
        props.shopVisited(props.yulelogID, 'ornament-mall');
        return window.open(`https://www.ornamentmall.com/search.asp?keyword=${props.boxnum}&utm_source=YuleLog`, '_blank');
    };

    const goToHookedOnOrnaments = () => {
        handleCloseAddMenu();
        props.shopVisited(props.yulelogID, 'hooked-on-ornaments');
        return window.open('https://www.hookedonhallmark.com/search.asp?keyword=' + props.boxnum, '_blank');
    };

    const renderArtist = (artist) => {
        if (isHallmark) {
            if (!artist) {
                return null
            } else if (artist[0].length === 0) {
                return null
            } else {
                let artistArr = artist.map((i) =>
                    getArtist(i)
                )
                return artistArr.map((i) =>
                    <BootstrapTooltip title={i.bio} arrow placement="top">
                        {/* TODO: marke artist clickable clickable onClick={() => window.open(i.url, '_blank')} */}
                        <Chip avatar={<Avatar src={i.src}></Avatar>} className={styles.Artist} label={i.name} />
                    </BootstrapTooltip>
                );
            }
        }
    }


    const renderKeywords = (keywords) => {

        if (!keywords) {
            return null
        }
        else if (keywords[0].length === 0) {
            return null
        } else {
            return keywords.map((i) =>
                <BootstrapTooltip title={tooltipsText.general.keyword} arrow placement="top">
                    <Chip className={styles.Keywords} label={i} />
                </BootstrapTooltip>
            );
        }
    }

    const renderSeries = (series) => {
        return series == "" ? null : series;
    };

    const renderSeriesNum = (seriesnum) => {
        return seriesnum == 0 ? null : '#' + seriesnum;
    };

    const renderVillage = (village) => {
        return village == "" ? null : village;
    };

    const renderImageURL = (imageURL) => {
        return imageURL == "" ? imageURL = null : imageURL = props.imageurl;
    };

    const renderEmbedId = (url) => {
        if (url = "") {
            return null
        }
        console.log("video url:", props.videoURL)
        let embedId = props.videoURL.replace("https://youtu.be/", "")
        console.log("embed", embedId)
        return <YoutubeEmbed embedId={embedId} />
    }

    const renderPrice = (price) => {
        if (price === 0) {
            return <NumberFormat fixedDecimalScale={true} decimalScale={2} value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        }
        return price ? (<NumberFormat fixedDecimalScale={true} decimalScale={2} value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} />) : (<Skeleton variant="text" width={150} />)
    }

    const mibPrice = props.prices
        ? props.prices.price2024
        : 0.00;
    
    const price2023 = props.prices
    ? props.prices.price2023
    : 0.00;

    const hallmarkChart = [
        ['x', 'MIB Average Price'],
        ["2019", props.price2019],
        ["2020", props.price2020],
        ["2021", props.price2021],
        ["2022", props.price2022],
        ["2023", price2023],
        ["2024", mibPrice]

    ]
    const d56Chart = [
        ['x', 'MIB Average Price'],
        ["2016", props.price2016],
        ["2017", props.price2017],
        ["2018", props.price2018],
        ["2023", price2023],
        ["2024", mibPrice]
    ]
    const chartData = isHallmark ? hallmarkChart : d56Chart;


    const renderChart = () => {
        if (isHallmark) {
            return (
                mibPrice ? (
                    <Chart
                        width={'400px'}
                        height={'200px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={chartData}
                        options={{
                            hAxis: {
                                title: 'Year',
                            },
                            vAxis: {
                                title: 'MIB Average Price',
                                gridlines: {
                                    color: 'transparent'
                                }
                            },
                            legend: 'none',
                            series: {
                                0: { color: '#7D54B4' }
                            },
                            pointsVisible: true,
                        }
                        }
                    />)
                    : (
                        <Skeleton variant="rect" width={300} height={200} />
                    ))
        }
        return null;
    }

    return (
        <Modal
            {...props}
            dialogClassName={styles.Modal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animated={false}
        >
            <Modal.Header
                closeButton
            >
                <Modal.Title>
                    <BootstrapTooltip title={tooltipsText.priceGuide.addItem} placement="top">
                        <IconButton onClick={(e) => props.addItem(props.yulelogID)(e)} style={{ marginRight: '10px' }} aria-label="add">
                            <AddIcon />
                        </IconButton>
                    </BootstrapTooltip>
                    <BootstrapTooltip title={tooltipsText.priceGuide.shop} placement="top">
                        <IconButton onClick={handleClickAddMenu} style={{ marginRight: '10px' }} aria-label="shop">
                            <CartIcon />
                        </IconButton>
                    </BootstrapTooltip>

                    {isHallmark && <BootstrapTooltip title={tooltipsText.priceGuide.copyLink} placement="top">
                        <IconButton onClick={handleLinkClick} aria-label="copy-link">
                            <LinkIcon />
                        </IconButton>
                    </BootstrapTooltip>
                    }
                    <Snackbar
                        style={{ marginTop: 100 }}
                        open={openSnackbar}
                        autoHideDuration={3000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Link copied to clipboard.
                        </Alert>
                    </Snackbar>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseAddMenu}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={goToEbay}>
                            <ListItemText>Search for item on eBay</ListItemText>
                        </MenuItem>
                        {isHallmark && <MenuItem onClick={goToHookedOnOrnaments}>
                            Hooked on Ornaments
                        </MenuItem>
                        }
                        {isHallmark && <MenuItem onClick={goToOrnamentShop}>
                            The Ornament Shop
                        </MenuItem>
                        }
                        {isHallmark && <MenuItem onClick={goToOrnamentMall}>
                            Ornament Mall
                        </MenuItem>
                        }
                    </Menu>
                    <br />
                    {props.category ? (<BootstrapTooltip title={tooltipsText.general.category} placement="right"><span className={styles.Category}>{props.category}</span></BootstrapTooltip>) : ""}
                    <br />
                    <BootstrapTooltip title={tooltipsText.general.year} placement="top"><span className={styles.Year}>{props.year}</span></BootstrapTooltip>
                    <BootstrapTooltip title={tooltipsText.general.boxnum} arrow placement="right"><span className={styles.Year}>{props.boxnum}</span></BootstrapTooltip>
                    <br />
                    {props.isItemWanted
                        ? <BootstrapTooltip title={tooltipsText.priceGuide.itemWanted} arrow placement="right">
                            <div className={styles.ItemWanted}><FavoriteIcon style={{ fontSize: 12 }} /> Item on Wishlist</div>
                        </BootstrapTooltip>
                        : props.isItemCollected
                            ? <BootstrapTooltip title={tooltipsText.priceGuide.itemAdded} arrow placement="right">
                                <div className={styles.ItemAdded}><CheckCircleIcon style={{ fontSize: 12 }} /> Item in collection</div>
                            </BootstrapTooltip>
                            : null
                    }
                    <BootstrapTooltip title={tooltipsText.general.nameModal} arrow placement="right"><span className={styles.Name}>{props.name}</span></BootstrapTooltip>
                    {renderVillage(props.village) && (<div><BootstrapTooltip title={tooltipsText.general.village} arrow placement="right"><span className={styles.Series}>{props.village}</span></BootstrapTooltip></div>)}
                    {renderSeries(props.series) && (<div><BootstrapTooltip title={tooltipsText.general.series} arrow placement="right"><span className={styles.Series}>{props.series} {renderSeriesNum(props.seriesnum)}</span></BootstrapTooltip></div>)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className='pgModalContainer' disableGutters maxWidth={false}>
                    <Row>
                        <Col xs={12} md={7}>
                            <div>
                                {renderArtist(props.artist) && (<div className={styles.ArtistWrapper}><span>{renderArtist(props.artist)}</span></div>)}
                                <span className={styles.Prices}>
                                    <BootstrapTooltip title={tooltipsText.general.boxPrice} arrow placement="top"><b>Box Price: </b></BootstrapTooltip>{renderPrice(props.boxPrice)}<br />
                                    <BootstrapTooltip title={tooltipsText.general.mibPrice} arrow placement="top"><b>MIB Price: </b></BootstrapTooltip>{renderPrice(mibPrice)}
                                </span>
                                <br /><br />
                                {props.pgComments ? (<div dangerouslySetInnerHTML={{ __html: props.pgComments }} className={styles.ModalText}></div>) : null}
                            </div>
                            {isHallmark && renderChart()}
                        </Col>
                        <Col xs={6} md={5}>
                            {renderImageURL(props.imageurl)
                                ? <img className={styles.Image} src={props.imageurl} />
                                : <Tooltip title={tooltipsText.image.unavailable}><img className={styles.DefaultImage} src={defaultImage} /></Tooltip>
                            }
                            {props.videoURL && renderEmbedId(props.videoURL)}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div className={styles.Footer}>
                    <div className={styles.KeywordsWrapper}>
                        {renderKeywords(props.keywords)}
                    </div>
                    <div className={styles.FooterButtonWrapper}>
                        <BootstrapTooltip title={tooltipsText.priceGuide.addItem} placement="top">
                            <Button onClick={(e) => props.addItem(props.yulelogID)(e)} variant="contained" color="primary">Add to Collection</Button>
                        </BootstrapTooltip>
                    </div>
                </div>
            </Modal.Footer>
        </Modal >
    );
}

export default PriceGuideModal;