
export const getMostValuable = (hits) => {
    let objs = hits.map((obj) => {
        return { name: obj.name, year: obj.year, mibPrice: obj.mibPrice, boxnum: obj.boxnum };
    });
    let sortedMibPrices = objs.sort(function (a, b) { return a.mibPrice - b.mibPrice; });
    let slicedMIBArray = sortedMibPrices.slice(-5).reverse();
    return slicedMIBArray;
}

export const getBrands = (hits) => {
    let hallmark = hits.filter(value => value.brand === "Hallmark").length;
    let d56 = hits.filter(value => value.brand === "Department 56").length;
    let custom = hits.filter(value => value.brand === "Custom").length;
    const brands = {
        hallmark: hallmark,
        d56: d56,
        custom: custom
    }
    return brands
}

export const getSeries = (hits) => {
    let seriesArrayRaw = hits.map(a => a.series);
    let filteredSeriesArray = seriesArrayRaw.filter(Boolean)
    let cnts = filteredSeriesArray.reduce(function (obj, val) {
        obj[val] = (obj[val] || 0) + 1;
        return obj;
    }, {});
    //Use the keys of the object to get all the values of the array
    //and sort those keys by their counts
    let sorted = Object.keys(cnts).sort(function (a, b) {
        return cnts[b] - cnts[a];
    });

    let slicedArray = sorted.slice(0, 5);
    return slicedArray;
}

export const getArtists = (hits) => {
    let artistArrayRaw = hits.map(a => a.artist);
    let filteredArtistArray = artistArrayRaw.filter(e => String(e).trim());
    let artcnts = filteredArtistArray.reduce(function (obj, val) {
        obj[val] = (obj[val] || 0) + 1;
        return obj;
    }, {});
    //Use the keys of the object to get all the values of the array
    //and sort those keys by their counts
    let sortedArtists = Object.keys(artcnts).sort(function (a, b) {
        return artcnts[b] - artcnts[a];
    });
    var slicedArtistArray = sortedArtists.slice(0, 5);
    return slicedArtistArray;
}

export const getOwned = (hits) => {
    // Count owned items
    return hits.length;
}

export const getValue = (hits, type) => {
    var worth = {
        hits
    },

        total = 0,  //set a variable that holds our total
        totalqty = 0,
        totalpurchase = 0,
        totalsold = 0,
        wantednum = 0,
        prices = worth.hits,  //reference the element in the "JSON" aka object literal we want
        i;
    for (i = 0; i < prices.length; i++) {  //loop through the array
        var qty = prices[i].quantity;
        var qtynum = Number(qty);
        var condition = prices[i].condition
        var value = prices[i].mibPrice;
        var wanted = prices[i].status;
        switch (condition) {
            case "Never Removed From Box":
                value = (value * 1.05) * qtynum;
                break;
            case "Mint In Box":
                value = value * qtynum;
                break;
            case "No Price Tag":
                value = (value * .95) * qtynum;
                break;
            case "Slightly Damaged Box":
                value = (value * .90) * qtynum;
                break;
            case "Damaged Box":
                value = (value * .85) * qtynum;
                break;
            case "No Box":
                value = (value * .80) * qtynum;
                break;
        }
        if (wanted === "Wanted") {
            wantednum += 1
        }
        if (wanted !== "Wanted") {
            total += value
        }
        totalqty += qtynum;
        totalpurchase += prices[i].priceBought;
        totalsold += prices[i].priceSold;
    }
    var netProfit = totalsold - totalpurchase
    var totalcollectionitems = totalqty - wantednum;


    switch (type) {
        case "netTotal":
        default:
            return netProfit
        case "worth":
            return total
        case "purchased":
            return totalpurchase
        case "sold":
            return totalsold
        case "wanted":
            return wantednum
        case "total":
            return totalcollectionitems
    }
}

export const getUnique = (hits) => {
    const filteredHits = hits.filter((hit) => hit.status !== "Wanted");
    let ids = filteredHits.map(a => {
        return a.yulelogID
    });
    let unique = ids.filter(onlyUnique);
    return unique.length;
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export const getPriceChange = (mibPrice, boxPrice) => {
    let price = mibPrice - boxPrice;
    return price.toFixed(2)
}




