import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import styles from './ButtonRow.module.css';
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import TableViewIcon from '@mui/icons-material/TableView';
import localforage from 'localforage';
import { ExportToCsv } from 'export-to-csv';
import { fabClasses } from '@mui/material';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';


export default function ButtonRow(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [csvReady, setCsvReady] = React.useState(false); // Track when CSV is ready to generate

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    useEffect(() => {
        if (csvReady) {
            generateCSV();
            setCsvReady(false); // Reset after generating the CSV
        }
    }, [csvReady]); // Only run when `csvReady` is set to true



    const generateCSV = async () => {
        function transformData(columns, rows) {
            const resultArray = [];
        
            // Iterate over each row in the rows array
            rows.forEach(row => {
                const rowObject = {};
        
                // Map each column header to its corresponding row value
                columns.forEach((column, index) => {
                    rowObject[column] = row[index];
                });
        
                resultArray.push(rowObject);
            });
        
            return resultArray;
        }

        // Get the visible columns
        const visibleColumns = props.allColumns.filter(column => column.isVisible).map(column => column.id);

        var result = await localforage.getItem('printData'); //wait for the localforage item

        const finalResult = transformData(visibleColumns, result.rows);

        // Filter the data to include only the visible columns
        const filteredData = finalResult.map(row => {
            let filteredRow = {};
            visibleColumns.forEach(columnId => {
                filteredRow[columnId] = row[columnId];
            });
            return filteredRow;
        });
    
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            filename: props.reportTitle,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true, // Automatically uses object keys as headers
        };
    
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(filteredData); // Export the filtered data
    };

    const handleExportClick = () => {
        props.clicked(); // Trigger any state updates or data changes

        setTimeout(() => {
            setCsvReady(true); // Wait and then signal to generate the CSV
        }, 0); // Small timeout to let state updates complete
    };


    return (
        <div className={styles.ButtonRow}>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {props.children}
            </Popover>
            <div className={styles.Row}>
                <div className={styles.Right}>

                </div>
                <div className={styles.Left}>
                    {/* <Button className={styles.LeftButton} variant="outlined" onClick={props.clicked} startIcon={<PictureAsPdfIcon />}>
                        Export as PDF
                    </Button> */}
                    &nbsp;
                    <Button variant="outlined" 
                        onClick={handleExportClick}
                        startIcon={<TableViewIcon />}>
                        Export as CSV
                    </Button>
                    &nbsp;
                    <Button aria-describedby={id} variant="contained" onClick={handleClick} variant="outlined" startIcon={<VisibilityIcon />}>
                        Show/Hide Columns
                    </Button>
                    &nbsp;
                    <Link
                        style={{ textDecoration: 'none' }}
                        target="_blank"
                        to={ROUTES.PRINT_REPORT}>
                        <Button aria-describedby={id} onClick={props.clicked} variant="contained" variant="outlined" startIcon={<PrintIcon />}>
                            Print
                        </Button>
                    </Link>
                </div>
            </div>
        </div >
    )
}
