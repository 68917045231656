import React from 'react';
import { makeStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import SmallLogo from '../../Logos/SmallLogo/SmallLogo';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import CustomSearchBox from '../../CustomSearchBox/CustomSearchBox';

import styles from './CustomToolbar.module.css';
import { withAuthentication } from '../../Session';

const useStyles = makeStyles({
    // This group of buttons will be aligned to the right
    rightToolbar: {
        marginLeft: "auto",
        marginRight: -12
    },
    menuButton: {
        marginRight: 16,
        marginLeft: -12
    }
})

const CustomToolbar = (props) => {
    const classes = useStyles();
    return (
        <Toolbar>
            <SmallLogo className={styles.Logo} />
            <div className={styles.Title}>
                <Typography variant="h6" noWrap >
                    {props.title}
                </Typography>
            </div>
            <CustomSearchBox searchAsYouType={false} />
            <section className={classes.rightToolbar}>
                <div className={styles.BarButtonWrapper}>
                    {props.children}
                </div>
            </section>
        </Toolbar>
    )
}
export default CustomToolbar
