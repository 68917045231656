import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route
}
  from 'react-router-dom';
import * as ROUTES from './constants/routes';
import { withAuthentication } from './components/Session';
import Collection from './containers/Collection/Collection';
import PriceGuide from './containers/PriceGuide/PriceGuide';
import Loading from './containers/Loading/Loading';
import Login from './components/Login/Login';
import LandingPage from './components/Landing/LandingPage';
import Subscribe from './components/Subscribe/Subscribe';
import Settings from './containers/Settings/Settings';
import Reports from './containers/Reports/Reports';
import Success from './components/Success/Success';
import Admin from './components/Admin/Admin';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CustomReport from './containers/Reports/CustomReport/CustomReport';
import PrintReport from './containers/Reports/PrintReport/PrintReport';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#7d54b4',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
    success: {
      main: '#4caf50'
    }
  }
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.SIGN_IN} component={Login} />
          <Route path={ROUTES.LOADING} component={Loading} />
          <Route path={ROUTES.SUBSCRIBE} component={Subscribe} />
          <Route path={ROUTES.SUCCESS} component={Success} />
          <Route path={ROUTES.COLLECTION} component={Collection} />
          <Route path={ROUTES.PRICEGUIDE} component={PriceGuide} />
          <Route path={ROUTES.SETTINGS} component={Settings} />
          <Route path={ROUTES.REPORTS} component={Reports} />
          <Route path={ROUTES.CUSTOM_REPORT} component={CustomReport} />
          <Route path={ROUTES.PRINT_REPORT} component={PrintReport} />
          <Route path={ROUTES.ADMIN} component={Admin} />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default withAuthentication(App);
