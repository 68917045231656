import React from 'react';
import styles from './DateSelect.module.css';
import moment from 'moment';
import TextField from '@mui/material/TextField';

const DateSelect = (props) => {

    const [date, setDate] = React.useState(moment.unix(props.date));
    const [helperText, setHelperText] = React.useState("Click calendar icon to select date.");
    const [errorStatus, setErrorStatus] = React.useState(false);

    const startingColor = (props.date === 0) ? 'lightgray' : 'black';

    const [textColor, setTextColor] = React.useState(startingColor)

    const handleDateChange = (event) => {
        /* If no value, set to 0. Otherwise app will crash */

        let iso = moment(event.target.value).unix()
        /* If date selected is before 1973 or after 2073, do not change date */
        if (iso < 94712400 || iso > 3251207408) {
            setErrorStatus(true)
            setHelperText("The date you selected is invalid.")
            return setDate(null)
        }
        if (!event.target.value) {
            props.changed(props.name, 0)
            setTextColor('lightgray')
            return setDate(null)
        }
        setDate(event.target.value);
        setTextColor('black')
        setErrorStatus(false);
        setHelperText("Click calendar icon to select date.");
        props.changed(props.name, iso)
    }

    let maxYear = (moment().year() + 2) + "-01-01";
    let dateValue = new Date(date || 0).toISOString().slice(0, 10)
    if (props.date === 0 || date === null) {
        dateValue = null;
    }

    return (

        <TextField
            className={styles.DateSelect}
            id="date"
            error={errorStatus}
            label={props.label}
            helperText={helperText}
            type="date"
            color="warning"
            value={dateValue}
            sx={{ width: 220, input: { color: textColor } }}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                inputProps: {
                    min: "1973-01-01",
                    max: maxYear
                }
            }}
            onChange={handleDateChange}
        />
    )
};

export default DateSelect;