import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styles from './ItemAttribute.module.css';



const ItemAttribute = (props) => {


    return (

        <div className={styles.ItemAttribute}>
            <Stack direction="row" alignItems="center" gap={1}>
                {/* {renderIcon(props.text)} */}
                {props.icon}
                <Typography style={{ fontWeight: 600 }} variant="body2" noWrap >
                    {props.text}
                </Typography>
            </Stack>
        </div>
    )
}

export default ItemAttribute;