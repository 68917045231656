export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const COLLECTION = '/collection';
export const ADMIN = '/yl-admin';
export const PRICEGUIDE = '/priceguide';
export const REPORTS = '/reports';
export const PRINT_REPORT = '/printreport';
export const CUSTOM_REPORT = '/customreport';
export const SETTINGS = '/settings';
export const LOADING = '/loading';
export const SUBSCRIBE = '/subscribe';
export const SUCCESS = '/success';
export const FORUMS = 'https://forums.yulelog.com/';