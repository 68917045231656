const categories =
    [
        "Keepsake Christmas",
        "Keepsake Miniature",
        "Keepsake Easter - Spring",
        "Merry Miniature-Christmas",
        "Kiddie Car Classics",
        "Merry Miniature-General",
        "Merry Miniature-Easter",
        "Halloween",
        "Merry Miniature-Valentine’s Day",
        "Merry Miniature-Halloween",
        "Showcase Christmas",
        "Gold Crown Christmas",
        "Merry Miniature-Thanksgiving",
        "Stocking Hangers",
        "Merry Miniature",
        "Heritage",
        "Keepsake Celebrations",
        "School Days Lunch Boxes",
        "American Girls Collection",
        "Premium Collection",
        "American Spirit Coin Collection",
        "Merry Miniature-Summer",
        "Legends in Flight",
        "Mini Kiddie Car Classics",
        "Merry Miniature-St. Patrick’s Day",
        "Merry Miniature-4th of July",
        "Milestones & Memories",
        "Hanukka",
        "Illuminations",
        "Merry Miniature-Graduation",
        "Keepsake Halloween",
        "Keepsake Ornaments",
        "Pin"];


export default categories;