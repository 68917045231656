import React from 'react';
import styles from './Signed.module.css'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Signed = (props) => {

    const [itemIsSigned, setIsSigned] = React.useState(props.signed);

    const handleChange = (event) => {
        setIsSigned(event.target.checked);
        props.changed("signed", event.target.checked,);
    };

    return (
        <div className={styles.Signed}>
            <FormControlLabel
                control={
                    <Checkbox checked={itemIsSigned} onChange={handleChange} />
                }
                label="Signed?" />
        </div>
    )
};

export default Signed;