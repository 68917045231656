import React from 'react';

import logo from '../../../img/yulelogicon.png';
import styles from './Logo.module.css';

const Logo = () => {
    return (
        <img className={styles.Logo} src={logo} alt="yulelog-ornament-logo" />
    )
}

export default Logo;